import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import img1 from "../images/logoFooter.PNG";

export default function Terms(props) {
  return (
    <div className="loginPage py-5">
      <Container className="loginContainer p-5">
        <Row>
          <Col className="text-left" sm="12">
            <h3 style={{ color: "#124E68" }}>
              <i
                onClick={() => props.history.goBack()}
                className="fas fa-chevron-left pr-4"
              ></i>
              About Us
            </h3>
          </Col>
        </Row>
        <Row className='paddingLeft'>
          <Col className="text-left" sm="12" md="8">
            AVEC was founded in 2017 by Dr Mohamed Elshafey, an Egyptian
            veterinarian living and working in Canada, for the purpose of
            supporting worldwide foreign veterinarians who seek to be
            internationally recognized and able to work abroad. As we are
            growing along the years as a globally recognized organization we
            continue to serve our purpose and we expand our impact on the
            veterinary field by enhancing international communication and
            experience sharing between veterinarians on a global scale through
            technology and innovation.
          </Col>
          <Col sm="12" md="4">
          <img style={{width:'100%'}} src={img1} alt="" />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
