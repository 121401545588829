import React, {useState} from "react";
import styles from "./PasswordInput.module.css";
import {Form} from "react-bootstrap";

export default function PasswordInput({className, type, ...rest}) {
    const [showPassword, setShowPassword] = useState(false)

    return (
        <div className={styles.container}>
            <Form.Control className={styles.container}
                          type={showPassword ? "text" : "password"}
                          {...rest}
            />
            <span className={showPassword ? "fa fa-eye-slash" : "fa fa-eye"}
                  aria-hidden="true"
                  onClick={() => setShowPassword(!showPassword)}
            />
        </div>
    )
}
