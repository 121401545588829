import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import axios from "axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { APILINK } from "../../EndPoint";
import { useForm } from "react-hook-form";
import { Input } from "antd";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const axiosApiInstance = axios.create();
const CreateSubject = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [selectedOption, setOption] = useState("1");
  const onSubmit = async (data) => {
    data.course_id = props.match.params.id;

    console.log(data);
    let result = await axiosApiInstance.post(
      APILINK + "/create_subjects",
      data
    );
    console.log(result);
    if (result) {
      if (result.data) {
        //  this.setState({ showSubjectModel: false });
        setTimeout(() => {
          toast.info("Subject has been created successfully");
        }, 500);
        setTimeout(() => {
          props.history.push(`/subjects/${props.match.params.id}`);
        }, 3500);
      }
    }
  };

  return (
    <div className="admin-home pb-5">
      <Container className="content-margin">
        <Row>
          <Col className=" pt-2" sm={12}>
            {" "}
            <h4>Create New Subject</h4>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <React.Fragment>
                <Row>
                  <Col lg={6}>
                    <label className="label">Title</label>
                    <Input
                      type="text"
                      placeholder="title"
                      {...register("title", { required: true })}
                    />
                    <p className="error">
                      {errors.title?.type === "required" &&
                        "This field is required"}
                    </p>
                  </Col>
                  <Col lg={6}>
                    <label className="label">Description</label>
                    <Input
                      type="text"
                      placeholder="subject description"
                      {...register("description", { required: true })}
                    />
                    <p className="error">
                      {errors.description?.type === "required" &&
                        "This field is required"}
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <label className="label">Cost</label>
                    <Input
                      type="number"
                      placeholder="Cost"
                      {...register("cost", { required: true, min: 1 })}
                    />
                    <p className="error">
                      {errors.cost?.type === "required" &&
                        "This field is required"}
                    </p>
                    <p className="error">
                      {errors.cost?.type === "min" &&
                        "Cost can not be less than one"}
                    </p>
                  </Col>
                  <Col lg={6}>
                    <label className="label">Display Order</label>
                    <Input
                      placeholder="Display Order"
                      type="number"
                      {...register("display_order", { required: true, min: 1 })}
                    />
                    <p className="error">
                      {errors.display_order?.type === "required" &&
                        "This field is required"}
                    </p>
                    <p className="error">
                      {errors.display_order?.type === "min" &&
                        "Display order can not be less than one"}
                    </p>
                  </Col>
                </Row>
                
                <Row>
                  <Col>
                    <input className="forumBtn1" value="Create" type="submit" />
                  </Col>
                </Row>
              </React.Fragment>
            </form>
          </Col>
        </Row>
        {/* <Form id="infoForm">
            <Row>
              <Col sm={6}>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>
                    <strong style={{ color: "red", paddingRight: "5px" }}>
                      *
                    </strong>
                    Subject Title
                  </Form.Label>
                  <Form.Control
                    name="title"
                    value={this.state.title}
                    onChange={this.handleChange}
                    type="text"
                    placeholder="Subject Title"
                    maxlength="60"

                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Subject Description</Form.Label>
                  <Form.Control
                    name="desc"
                    value={this.state.desc}
                    onChange={this.handleChange}
                    type="text"
                    placeholder="Subject Description"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>
                    <strong style={{ color: "red", paddingRight: "5px" }}>
                      *
                    </strong>
                    Subject Cost
                  </Form.Label>
                  <Form.Control
                    name="cost"
                    value={this.state.cost}
                    onChange={this.handleChange}
                    type="number"
                    placeholder="Subject Cost"
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>
                    <strong style={{ color: "red", paddingRight: "5px" }}>
                      *
                    </strong>
                    Display Order
                  </Form.Label>
                  <Form.Control
                    name="order"
                    value={this.state.order}
                    onChange={this.handleChange}
                    type="number"
                    placeholder="Display Order"
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>

          <Row className="mt-3">
            <Col md={12}>
              <Button
                disabled={this.state.disabled}
                className="forumBtn1"
                onClick={this.createSubject}
              >
                create
              </Button>
            </Col>
          </Row> */}
      </Container>
      <ToastContainer position="bottom-right" />
    </div>
  );
};

export default ErrorHandler(CreateSubject, axiosApiInstance);
