import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import img1 from "../images/avecImg.jpg";
import "../style-sheets/loginStyle.css";
import { Form, Input, Button } from "antd";
import "antd/dist/antd.css";
import axios from "axios";
//redux
import { connect } from "react-redux";
import { LogOut } from "../global-state/actions/authAction";

import { bindActionCreators } from "redux";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { APILINK } from "../EndPoint";
// import bcrypt from "bcrypt"
const saltRounds = 10;
const axiosApiInstance = axios.create();

class PasswordReset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      BeErr: ""
    };
  }
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onSend = async (e) => {
    let request = {};

    request.password = this.state.password;
    console.log(window.location.pathname);
    const result = await axiosApiInstance
      .post(APILINK + window.location.pathname, request)
      .catch((err) => console.log(err));
    if (result) {
      setTimeout(() => {
        toast.info("Password Reset Successfully");
        this.props.history.push("/login");
      }, 3000);
    }
  };
  logout = (e) => {
    this.props.LogOut();
  };
  componentDidMount() {
  this.logout()
  }

  render() {
    return (
      <div className="loginPage py-5">
        {/* <Container className="p-4"></Container> */}
        <Container className="loginContainer">
          <Row>
            <Col className="imgCol1" sm={6}>
              <div className="img-box">
                {" "}
                <img
                  alt="img-login"
                  src={img1}
                  className="img-fluid loginImg"
                />
              </div>
            </Col>
            <Col className="pl-5 text-left" sm={6}>
              <Form
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
                style={{
                  margin: "30px auto 0px auto"
                }}
                layout="vertical"
              >
                <h1 className="title1">Password reset</h1>

                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    { required: true, message: "Please input your password!" }
                  ]}
                  layout="vertical"
                >
                  <Input
                    className="loginInput"
                    placeholder="Password"
                    onChange={this.handleChange}
                    type="password"
                    name="password"
                    value={this.state.password}
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    className="loginBtn1"
                    onClick={this.onSend}
                    type="primary"
                    htmlType="submit"
                  >
                    Reset Password{" "}
                  </Button>
                </Form.Item>
                <Row>
                  <Col style={{ margin: "auto" }} sm={12}>
                    {this.state.BeErr !== "" ? (
                      <p style={{ color: "red", fontWeight: "bold" }}>
                        {this.state.BeErr}
                      </p>
                    ) : null}
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <Row></Row>
        </Container>
        <ToastContainer position="bottom-right" />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  url: state.url.url
});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ LogOut }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);
