import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import img1 from "../images/avecImg.jpg";
import "../style-sheets/loginStyle.css";
import { Form, Input, Button } from "antd";
import "antd/dist/antd.css";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

//redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { userLogin, LogOut } from "../global-state/actions/authAction";
import { showNav } from "../global-state/actions/showNavAction";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import bcrypt from "bcrypt"
const saltRounds = 10;

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      BeErr: ""
    };
  }
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onLogin = async (e) => {
    let loginrequest = {};
    // const salt = bcrypt.genSaltSync(saltRounds);
    // const hash = bcrypt.hashSync(this.state.password, salt);
    loginrequest.email = this.state.email;
    loginrequest.password = this.state.password;

    const userData = await this.props.userLogin(
      loginrequest,
      this.props.history
    );
    // console.log(userData);
    if (userData) {
      // this.setState({ BeErr: userData });
      setTimeout(() => {
        toast.error(userData);
      }, 500);
    }
  };

  componentDidMount() {
    this.props.LogOut();
    this.props.showNav(false);
  }

  onFinish = (values) => {
    // console.log("Success:", values);
  };

  onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  responseGoogle = async (response) => {
    console.log(response);
    let loginrequest = {
      email: response.profileObj.email,
      is_social: true,
      social_id: response.profileObj.googleId
    };
    const userData = await this.props.userLogin(
      loginrequest,
      this.props.history
    );
    if (userData) {
      // this.setState({ BeErr: userData });
      setTimeout(() => {
        toast.error(userData);
      }, 500);
    }
  };
  responseFacebook = async (response) => {
    console.log(response);
    let name = response.name.split(" ");
    let loginrequest = {
      is_social: true,
      email: response.email,
      social_id: response.id
    };
    const userData = await this.props.userLogin(
      loginrequest,
      this.props.history
    );
    if (userData) {
      // this.setState({ BeErr: userData });
      setTimeout(() => {
        toast.error(userData);
      }, 500);
    }
  };

  render() {
    return (
      <div className="loginPage py-5">
        {/* <Container className="p-4"></Container> */}
        <Container className="loginContainer">
          <Row>
            <Col className="imgCol1" sm={6}>
              <div className="img-box">
                {" "}
                <img
                  alt="img-login"
                  src={img1}
                  className="img-fluid loginImg"
                />
              </div>
            </Col>
            <Col className="pl-5 text-left" sm={6}>
              <Form
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
                style={{
                  margin: "30px auto 0px auto"
                }}
                layout="vertical"
              >
                <h1 className="title1">Sign In</h1>

                <Form.Item
                  label="Email"
                  name="Email"
                  rules={[
                    { required: true, message: "Please input your Email!" }
                  ]}
                  layout="vertical"
                >
                  <Input
                    className="loginInput"
                    placeholder="Email"
                    onChange={this.handleChange}
                    type="email"
                    name="email"
                    value={this.state.email}
                  />
                </Form.Item>

                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    { required: true, message: "Please input your password!" }
                  ]}
                >
                  <Input.Password
                    value={this.state.password}
                    name="password"
                    onChange={this.handleChange}
                    placeholder="Password"
                    className="loginInput"
                  />
                </Form.Item>
                <a href="/forget_password" className="forget">
                  Forgot Password?
                </a>
                <Form.Item>
                  <Button
                    className="loginBtn1"
                    onClick={this.onLogin}
                    type="primary"
                    htmlType="submit"
                  >
                    LOGIN{" "}
                  </Button>
                </Form.Item>
                <Row>
                  <Col style={{ margin: "auto" }} sm={12}>
                    {this.state.BeErr !== "" ? (
                      <p style={{ color: "red", fontWeight: "bold" }}>
                        {this.state.BeErr}
                      </p>
                    ) : null}
                  </Col>
                </Row>
                {/* <Form.Item>
                  <FacebookLogin
                    appId="620355319059926"
                    autoLoad={false}
                    fields="name,email,picture"
                    callback={this.responseFacebook}
                    render={(renderProps) => (
                      <Button
                        onClick={renderProps.onClick}
                        className="loginBtn2"
                        type="primary"
                      >
                        <i className="fab fa-facebook-f mr-5"></i> Login With
                        Facebook{" "}
                      </Button>
                    )}
                    state="test"
                  />
                </Form.Item>

                <Form.Item>
                  <GoogleLogin
                    clientId="834798242328-9b6rc4gin4p64v2sqqr8pulnlhuh52i6.apps.googleusercontent.com"
                    onSuccess={this.responseGoogle}
                    onFailure={this.responseGoogle}
                    cookiePolicy={"single_host_origin"}
                    className="loginBtn3"
                    icon={false}
                    buttonText="Login With Google"
                    isSignedIn={false}
                    autoLoad={false}
                  />{" "}
                </Form.Item> */}

                <p className="p1">
                  Don't Have An Account ?{" "}
                  <a href="/register" className="span1">
                    Sign Up
                  </a>
                </p>
              </Form>
            </Col>
          </Row>
          <Row></Row>
        </Container>
        <ToastContainer position="bottom-right" />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ userLogin, LogOut, showNav }, dispatch);
};
export default connect(null, mapDispatchToProps)(Login);
