import React, { Component } from "react";
import { Row, Col, Container } from "react-bootstrap";
import "../../style-sheets/studentCourses.css";
import Subjects from "./subjects";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { APILINK } from "../../EndPoint";
import { connect } from "react-redux";
import axios from "axios";
import { Pagination, Spin, Empty } from "antd";
import { bindActionCreators } from "redux";
import { showCalender } from "../../global-state/actions/showCalenderAction";
const axiosApiInstance = axios.create();

class CourseDetails extends Component {
  state = {
    details: [],
    checked: false,
    loading: false,
    count: 1,
    current: 1,
  };
  openCollapse = (e) => {
    console.log(e.target.value);
    this.setState((prevState) => {
      return { open: !prevState.open };
    });
  };

  getCourseDetails = async () => {
    let result;
    if (this.props.user.user_type === "3") {
      result = await axiosApiInstance.get(
        APILINK +
          `/view_subject_round/${this.props.match.params.id}?student=${this.props.match.params.student}&page=1`
      );
    } else {
      result = await axiosApiInstance.get(
        APILINK + `/view_subject_round/${this.props.match.params.id}?page=1`
      );
    }

    if (result.data.data) {
      console.log(result.data.data);
      this.setState({
        details: result.data.data,
        loading: false,
        count: result.data.count,
      });
    }
  };

  componentDidMount() {
    this.setState({ loading: true });
    this.getCourseDetails();
    this.props.showCalender(true);
    console.log(this.props.location)
  }

  componentDidUpdate(prevProps, prevState) {
    // if (!isEqual(prevState.checked, this.state.checked)) {
    if (this.state.checked !== prevState.checked) {
      console.log("changeeeeee");
      this.getCourseDetails();
    }
  }

  toggle = (e) => {
    console.log(e)
    this.setState({ checked: e });
    if(e=="change")
    {
      this.getCourseDetails();

    }
  };

  onChange = (page) => {
    // console.log(page);
    this.setState(
      {
        current: page,
      },
      async () => {
        let result;
        if (this.props.user.user_type === "3") {
          result = await axiosApiInstance.get(
            APILINK +
              `/view_subject_round/${this.props.match.params.id}?student=${this.props.match.params.student}&page=${this.state.current}`
          );
        } else {
          result = await axiosApiInstance.get(
            APILINK +
              `/view_subject_round/${this.props.match.params.id}?page=${this.state.current}`
          );
        }

        if (result.data.data) {
          // console.log(result.data.data);
          this.setState({
            details: result.data.data,
            loading: false,
            count: result.data.count,
          });
        }
      }
    );
  };
  render() {
    return (
      <Container className="content-margin">
        {this.state.details.length > 0 && this.state.loading === false ? (
          <React.Fragment>
            <Row>
              <Col lg={12} md={12}>
                <h1 className="course-title">
                  {this.state.details[0].subject_round.subject.course.title}
                </h1>
                <p className="course-description">
                  {
                    this.state.details[0].subject_round.subject.course
                      .description
                  }
                </p>
              </Col>
            </Row>
            <Row>
              <Col className="course-details-keys" md={2} xs={6}>
                {/* <p>Created by</p> */}
                <p>Start Date</p>
                <p>End Date</p>
                <p>Fees</p>
              </Col>
              <Col md={10} xs={6}>
                <p className="course-info">
                  {this.props.location.data?.start_date.date}
                </p>

                <p className="course-info">
                  {this.props.location.data?.end_date.date}
                </p>
                <p className="course-info">
                  {this.state.details[0].subject_round.subject.course.cost}
                </p>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <h2 className="sub-title">Available Course Subjects</h2>
              </Col>
              {this.state.details.map((subject) => (
                <Col key={subject.id} sm={4}>
                  <Subjects
                    key={subject.id}
                    startDate={subject.subject_round.start_date.date}
                    endDate={subject.subject_round.end_date.date}
                    cost={subject.subject_round.subject.cost}
                    title={subject.subject_round.subject.title}
                    description={subject.subject_round.subject.description}
                    canActive={subject.can_active}
                    isActive={subject.is_active}
                    subjectRoundID={subject.subject_round.id}
                    studentID={this.props.match.params.student}
                    toggle={this.toggle}
                    tutor={subject.subject_round.tutor}
                  />
                </Col>
              ))}
            </Row>
          </React.Fragment>
        ) : this.state.details.length == 0 && this.state.loading === false ? (
          <Empty />
        ) : (
          <Spin tip="....loading" />
        )}
        <Row className="p-4">
          {this.state.details.length !== 0 ? (
            <Pagination
              onChange={this.onChange}
              defaultCurrent={this.state.current}
              total={this.state.count}
              current={this.state.current}
            />
          ) : null}
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuth: state.auth.isAuth,
  showNav: state.showNav.showNav,
  user: state.auth.user,
});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ showCalender }, dispatch);
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ErrorHandler(CourseDetails, axiosApiInstance));
