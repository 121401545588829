import React, { useState, useEffect, useRef } from "react";
import { Row } from "react-bootstrap";
import { Input, Button, Form } from "antd";
import axios from "axios";
import { connect } from "react-redux";
import ErrorHandler from "../error-handler/ErrorHandler";
import { APILINK } from "../EndPoint";

const axiosApiInstance = axios.create();
const ChatMessages = (props) => {
  const scrollBottom = useRef();

  const [message, setMsg] = useState("");

  const onMessage = (e) => {
    setMsg(e.target.value);
  };
  const onSend = async () => {
    let data = {
      receiver_id: props.selectedUser,
      message: message,
    };
    let result = await axiosApiInstance.post(
      APILINK + `/send_chat_message`,
      data
    );
    console.log(result);
    setMsg("");
  };

  useEffect(() => {
    if (scrollBottom.current) {
      scrollBottom.current.scrollIntoView({ smooth: true });
      window.scrollTo(0, 100);
    }
  }, []);

  const detect = (e) => {
    var target = e.target;
    if (target.scrollTop + target.offsetHeight === target.offsetHeight) {
      props.detectLoading(true);
    } else {
    }
  };

  return (
    <div onScroll={detect} className="scroll rooms">
      {props.msgs.length > 0 ? (
        <div>
          {props.msgs.map((message) => {
            return (
              <Row key={message.id} className="chatRow">
                <Row className="chatRow">
                  <div></div>

                  <div
                    className={
                      props.user.id == message.sender.id ? "sender" : "reciever"
                    }
                  >
                    <p className="">{message.message}</p>
                  </div>
                </Row>
              </Row>
            );
          })}
          <div ref={scrollBottom}></div>
        </div>
      ) : null}
      <Form>
        <Form.Item>
          <Input
            className="message-input"
            type="text"
            onChange={onMessage}
            value={message}
          />
          <Button
            type="primary"
            htmlType="submit"
            className="forumBtn1 ml-2"
            onClick={onSend}
          >
            Send
          </Button>
        </Form.Item>
        {/* <Form.Item>
          
        </Form.Item> */}
      </Form>
    </div>
  );
};
const mapStateToProps = (state) => ({
  user: state.auth.user,
  isAuth: state.auth.isAuth,
});

export default connect(mapStateToProps)(
  ErrorHandler(ChatMessages, axiosApiInstance)
);
