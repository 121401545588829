import React, { Component } from "react";
import axios from "axios";
import { Switch } from "antd";
import DataTable from "react-data-table-component";
import {
  Container,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Modal,
  Button,
  Form,
} from "react-bootstrap";
// import { Link } from "react-router-dom";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { APILINK } from "../../EndPoint";
import { Link } from "react-router-dom";
import { Pagination, Spin } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { passwordReset } from "../../global-state/actions/passwordAction";
const axiosApiInstance = axios.create();
class AllTutors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: [],
      tutors: [],
      studentID: "",
      count: 1,
      current: 1,
      showSubjectModel: false,
      columns: [
        {
          name: "Name",
          selector: "full_name",
          sortable: true,
          right: true,
        },
        {
          name: "Last Name",
          selector: "last_name",
          sortable: true,
          right: true,
        },
        {
          name: "Email",
          selector: "email",
          sortable: true,
          right: true,
        },
        {
          name: "Actions",
          button: true,
          width: "160px",
          cell: (row) => (
            <div className="table-data-feature">
              <OverlayTrigger
                key={"2"}
                placement={"top"}
                overlay={<Tooltip id={`tooltip-${"top"}`}>Edit Tutor</Tooltip>}
              >
                <Link to={`/edit_tutor/${row.id}`}>
                  <i className="students-icons fas fa fa-edit pr-2"></i>
                </Link>
              </OverlayTrigger>
              <OverlayTrigger
                key={"1"}
                placement={"top"}
                overlay={
                  <Tooltip id={`tooltip-${"top"}`}>Delete Tutor</Tooltip>
                }
              >
                <i
                  onClick={this.showWarning}
                  id={row.id}
                  className="students-icons fas fa fa-trash pr-2"
                ></i>
              </OverlayTrigger>
              <OverlayTrigger
                key={"1"}
                placement={"top"}
                overlay={
                  <Tooltip id={`tooltip-${"top"}`}>Reset Password</Tooltip>
                }
              >
                <i
                  onClick={this.Reset}
                  id={row.id}
                  className="students-icons fas fa fa-tools pr-2"
                ></i>
              </OverlayTrigger>
            </div>
          ),
        },
      ],
      tutorID: "",
      show: false,
      showPassword: false,
    };
  }

  getTutors = async () => {
    this.setState({ loading: true });
    let result;
    result = await axiosApiInstance.get(APILINK + `/tutors_list?page=1`);
    if (result) {
      this.setState({
        data: result.data.data,
        count: result.data.count,
        loading: false,
      });
    }
  };
  componentDidMount() {
    this.getTutors();
  }
  showWarning = (e) => {
    this.setState({ show: true, tutorID: e.target.id });
  };
  close = (e) => {
    this.setState({ show: false });
  };
  deleteTutor = async () => {
    let result = await axiosApiInstance.delete(
      APILINK + `/get_del_user/${this.state.tutorID}`
    );
    console.log(result);
    if (result.data == "") {
      this.setState({ show: false });
      this.getTutors();
    }
  };

  Reset = async (e) => {
    let result = await axiosApiInstance.get(
      APILINK + `/admin-reset-password?user_id=${e.target.id}`
    );
    console.log(result);
    this.props.passwordReset(result.data.url);
    setTimeout(() => {
      toast.info("You will be redirected shortly");
    }, 3000);
    setTimeout(() => {
      this.props.history.push("/admin_password_reset");
    }, 3000);
  };
  onChange = (page) => {
    console.log(page);
    this.setState(
      {
        current: page,
      },
      async () => {
        let result;

        result = await axiosApiInstance
          .get(APILINK + `/tutors_list?page=${page}`)
          .then((res) => {
            console.log(res);
            this.setState({ data: res.data.data, count: res.data.count });
          });
      }
    );
  };
  render() {
    console.log(this.state.data);
    return (
      <div>
        {this.state.loading ? (
          <Spin className="spinElement" tip="..loading" />
        ) : (
          <Container className="viewSuper">
            <Row>
              <Col className="p-4 mb-4" style={{ textAlign: "left" }} sm={6}>
                <h2> All Tutors</h2>
              </Col>
            </Row>
            <Row>
              <Col style={{ textAlign: "center" }} sm={12}>
                <DataTable
                  columns={this.state.columns}
                  data={this.state.data}
                />
              </Col>
            </Row>
            <Row className="p-4">
              {this.state.data.length !== 0 ? (
                <Pagination
                  onChange={this.onChange}
                  defaultCurrent={this.state.current}
                  total={this.state.count}
                  current={this.state.current}
                />
              ) : null}
            </Row>
          </Container>
        )}
        <ToastContainer position="bottom-right" />
        <Modal show={this.state.show} onHide={this.close}>
          <Container className="p-4">
            <Row>
              <Col className="text-center" sm={12}>
                {" "}
                <h6>Are u sure u want to delete this Tutor?</h6>
              </Col>
              <Row style={{ margin: "auto" }}>
                <Col md={12}>
                  <Button onClick={this.deleteTutor} className="headBtn mx-3">
                    Delete
                  </Button>
                  <Button className="headBtn" onClick={this.close}>
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Row>
          </Container>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  isAuth: state.auth.isAuth,
});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ passwordReset }, dispatch);
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ErrorHandler(AllTutors, axiosApiInstance));
