import React, { Component } from "react";
import "../../style-sheets/AuthHeader.css";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { connect } from "react-redux";
import logo from "../../images/Logo-alpha-layer-cropped.png";
import axios from "axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { Spin, Empty } from "antd";
//redux
import { bindActionCreators } from "redux";
import { LogOut } from "../../global-state/actions/authAction";
import { GoogleLogout } from "react-google-login";
import { APILINK } from "../../EndPoint";
import { Tabs } from "antd";

const { TabPane } = Tabs;
const axiosApiInstance = axios.create();

class AuthHeader extends Component {
  state = {
    showNotifications: false,
    loading: false,
    notifications: [],
    quizNotifications: [],
  };
  logout = (e) => {
    this.props.LogOut();
  };
  callback = (key) => {
    console.log(key);
  };
  showNotifications = () => {
    this.setState(
      (prevState) => ({
        showNotifications: !prevState.showNotifications,
        loading: true,
      }),
      async () => {
        console.log(this.state.showNotifications);
        if (this.state.showNotifications) {
          let result = await axiosApiInstance.get(
            APILINK + `/forum_notifications`
          );
          if (result) {
            this.setState({ loading: false, notifications: result.data.data });
          }
        }
      }
    );
  };

  getNotifications = (key) => {
    if (key === "1") {
      this.setState(
        (prevState) => ({ loading: true }),
        async () => {
          console.log(this.state.showNotifications);
          if (this.state.showNotifications) {
            let result = await axiosApiInstance.get(
              APILINK + `/forum_notifications`
            );
            if (result) {
              this.setState({
                loading: false,
                notifications: result.data.data,
              });
            }
          }
        }
      );
    } else {
      this.setState({ loading: true }, async () => {
        let result = await axiosApiInstance.get(
          APILINK + `/quiz_notifications`
        );
        if (result) {
          this.setState({
            loading: false,
            quizNotifications: result.data.data,
          });
        }
      });
    }
  };

  render() {
    return (
      <div>
        {this.props.user.user_type === "3" ? (
          <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
            <Navbar.Brand href="/">
              <img alt="registerImg" className="logo" src={logo}></img>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="tabs">
                {window.location.pathname.includes("course-details") ? (
                  <Nav.Link href="/course_calender">My Calendar</Nav.Link>
                ) : null}
              </Nav>
              <Nav className="Nav ml-auto">
                <NavDropdown
                  title={
                    <React.Fragment>
                      <i class="far fa-user-circle"></i>
                      <span>
                        {this.props.user.full_name !== ""
                          ? this.props.user.full_name
                          : "Admin"}
                      </span>
                    </React.Fragment>
                  }
                  id="collasible-nav-dropdown"
                >
                  <NavDropdown.Item href="/create_tutor">
                    Create Tutor Account
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/create_student">
                    Create Student Account
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={this.logout} href="/">
                    Logout
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                </NavDropdown>
                <ul className="navList">
                  <li>
                    <Nav.Link href="/admin_courses">Courses</Nav.Link>
                    <Nav.Link href="/create-course">Add Courses</Nav.Link>
                    <Nav.Link href="/view_students">Students</Nav.Link>
                    <Nav.Link href="/view_tutors">Tutors</Nav.Link>
                    <Nav.Link href="/view_fourms">All Forums</Nav.Link>
                    <Nav.Link href="/verify_payments">Verify Payments</Nav.Link>
                    <Nav.Link href="/speciality_forums">Community</Nav.Link>
                  </li>
                </ul>
                <Nav.Link href="/chat">
                  {<i className="far fa-comment-alt"></i>}
                </Nav.Link>
                <Nav.Link eventKey={2} href="">
                  {
                    <i
                      onClick={this.showNotifications}
                      className="far fa-bell"
                    ></i>
                  }
                </Nav.Link>
                {this.state.showNotifications ? (
                  <div className="notification-bar">
                    <Tabs
                      defaultActiveKey="1"
                      onTabClick={this.getNotifications}
                      onChange={this.callback}
                    >
                      <TabPane tab="Forums" key="1">
                        {this.state.loading === false &&
                        this.state.notifications.length !== 0 ? (
                          this.state.notifications.map((notification) => {
                            return (
                              <div className="single-notification">
                                <p>
                                  <strong>{notification.title}</strong>
                                </p>
                                <p>
                                  Created At{" "}
                                  {notification.created_at.split("T")[0] +
                                    " " +
                                    notification.created_at
                                      .split("T")[0]
                                      .split(".")[0]}
                                </p>
                              </div>
                            );
                          })
                        ) : this.state.loading === false &&
                          this.state.notifications.length === 0 ? (
                          <Empty />
                        ) : (
                          <Spin className="spinner" />
                        )}
                      </TabPane>
                      <TabPane tab="Quiz" key="2">
                        {this.state.loading === false &&
                        this.state.quizNotifications.length !== 0 ? (
                          this.state.quizNotifications.map((notification) => {
                            return (
                              <div className="single-notification">
                                {notification.created === false ? (
                                  <p>
                                    <strong>
                                      Student{" "}
                                      {notification.student.full_name +
                                        " " +
                                        notification.student.last_name}{" "}
                                      have missed the quiz on{" "}
                                      {notification.subject_round.subject.title}{" "}
                                      subject
                                    </strong>
                                  </p>
                                ) : (
                                  <p>
                                    <strong>
                                      New quiz has been created at subject{" "}
                                      {notification.subject_round.subject.title}{" "}
                                      with round starts at{" "}
                                      {
                                        notification.subject_round.start_date
                                          .date
                                      }{" "}
                                      inside course{" "}
                                      {
                                        notification.subject_round.subject
                                          .course.title
                                      }
                                    </strong>
                                  </p>
                                )}

                                {/* <p>Created At {notification.created_at.split("T")[0] + " " + notification.created_at.split("T")[0].split(".")[0]}</p> */}
                              </div>
                            );
                          })
                        ) : this.state.loading === false &&
                          this.state.quizNotifications.length === 0 ? (
                          <Empty />
                        ) : (
                          <div className="mt-5">
                            <Spin className="spinner" />
                          </div>
                        )}
                      </TabPane>
                    </Tabs>
                  </div>
                ) : null}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        ) : this.props.user.user_type === "2" ? (
          <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
            <Navbar.Brand href="/">
              <img alt="registerImg" className="logo" src={logo}></img>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="tabs">
                {this.props.showCalender ? (
                  <Nav.Link href="/course_calender">My Calendar</Nav.Link>
                ) : null}
              </Nav>
              <Nav className="Nav ml-auto">
                <NavDropdown
                  title={
                    <React.Fragment>
                      <i className="far fa-user-circle"></i>
                      <span>{this.props.user.full_name}</span>
                    </React.Fragment>
                  }
                  id="collasible-nav-dropdown"
                >
                  <NavDropdown.Item href="/calender">
                    My Calendar
                  </NavDropdown.Item>

                  {this.props.user.social_provider == "google" ? (
                    <NavDropdown.Item href="/">
                      <GoogleLogout
                        clientId="834798242328-9b6rc4gin4p64v2sqqr8pulnlhuh52i6.apps.googleusercontent.com"
                        buttonText="Logout"
                        onLogoutSuccess={this.logout}
                        icon={false}
                        className="logoutGoogle"
                      ></GoogleLogout>
                    </NavDropdown.Item>
                  ) : (
                    <NavDropdown.Item onClick={this.logout} href="/">
                      Logout
                    </NavDropdown.Item>
                  )}
                </NavDropdown>
                <ul className="navList">
                  <li>
                    <Nav.Link href="/courses">Courses</Nav.Link>

                    <Nav.Link href="/speciality_forums">Community</Nav.Link>
                  </li>
                </ul>
                <Nav.Link href="/chat">
                  {<i className="far fa-comment-alt"></i>}
                </Nav.Link>
                <Nav.Link eventKey={2} href="">
                  {
                    <i
                      onClick={this.showNotifications}
                      className="far fa-bell"
                    ></i>
                  }
                </Nav.Link>

                {this.state.showNotifications ? (
                  <div className="notification-bar">
                    <Tabs
                      defaultActiveKey="1"
                      onTabClick={this.getNotifications}
                      onChange={this.callback}
                    >
                      <TabPane tab="Forums" key="1">
                        {this.state.loading === false &&
                        this.state.notifications.length !== 0 ? (
                          this.state.notifications.map((notification) => {
                            return (
                              <div className="single-notification">
                                <p>
                                  <strong>{notification.title}</strong>
                                </p>
                                <p>
                                  Created At{" "}
                                  {notification.created_at.split("T")[0] +
                                    " " +
                                    notification.created_at
                                      .split("T")[0]
                                      .split(".")[0]}
                                </p>
                              </div>
                            );
                          })
                        ) : this.state.loading === false &&
                          this.state.notifications.length === 0 ? (
                          <Empty />
                        ) : (
                          <Spin className="spinner" />
                        )}
                      </TabPane>
                      <TabPane tab="Quiz" key="2">
                        {this.state.loading === false &&
                        this.state.quizNotifications.length !== 0 ? (
                          this.state.quizNotifications.map((notification) => {
                            return (
                              <div className="single-notification">
                                {notification.created === false ? null : (
                                  <p>
                                    <strong>
                                      New quiz has been created at subject{" "}
                                      {notification.subject_round.subject.title}{" "}
                                      with round starts at{" "}
                                      {
                                        notification.subject_round.start_date
                                          .date
                                      }{" "}
                                      inside course{" "}
                                      {
                                        notification.subject_round.subject
                                          .course.title
                                      }
                                    </strong>
                                  </p>
                                )}

                                {/* <p>Created At {notification.created_at.split("T")[0] + " " + notification.created_at.split("T")[0].split(".")[0]}</p> */}
                              </div>
                            );
                          })
                        ) : this.state.loading === false &&
                          this.state.quizNotifications.length === 0 ? (
                          <Empty />
                        ) : (
                          <div className="mt-5">
                            <Spin className="spinner" />
                          </div>
                        )}
                      </TabPane>
                    </Tabs>
                  </div>
                ) : null}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        ) : (
          <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
            <Navbar.Brand href="/">
              <img alt="registerImg" className="logo" src={logo}></img>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="tabs">
                <Nav.Link href="/calender">My Calendar</Nav.Link>
              </Nav>
              <Nav className="Nav ml-auto">
                <NavDropdown
                  title={
                    <React.Fragment>
                      <i class="far fa-user-circle"></i>
                      <span>{this.props.user.full_name}</span>
                    </React.Fragment>
                  }
                  id="collasible-nav-dropdown"
                >
                  <NavDropdown.Item onClick={this.logout} href="/">
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
                <ul className="navList">
                  <li>
                    <Nav.Link href="/courses">Courses</Nav.Link>

                    <Nav.Link href="/speciality_forums">Community</Nav.Link>
                  </li>
                </ul>
                <Nav.Link href="/chat">
                  {<i class="far fa-comment-alt"></i>}
                </Nav.Link>
                <Nav.Link eventKey={2} href="">
                  {<i onClick={this.showNotifications} class="far fa-bell"></i>}
                </Nav.Link>

                {this.state.showNotifications ? (
                  <div className="notification-bar">
                    <Tabs
                      defaultActiveKey="1"
                      onTabClick={this.getNotifications}
                      onChange={this.callback}
                    >
                      <TabPane tab="Forums" key="1">
                        {this.state.loading === false &&
                        this.state.notifications.length !== 0 ? (
                          this.state.notifications.map((notification) => {
                            return (
                              <div className="single-notification">
                                <p>
                                  <strong>{notification.title}</strong>
                                </p>
                                <p>
                                  Created At{" "}
                                  {notification.created_at.split("T")[0] +
                                    " " +
                                    notification.created_at
                                      .split("T")[0]
                                      .split(".")[0]}
                                </p>
                              </div>
                            );
                          })
                        ) : this.state.loading === false &&
                          this.state.notifications.length === 0 ? (
                          <Empty />
                        ) : (
                          <Spin className="spinner" />
                        )}
                      </TabPane>
                      <TabPane tab="Quiz" key="2">
                        {this.state.loading === false &&
                        this.state.quizNotifications.length !== 0 ? (
                          this.state.quizNotifications.map((notification) => {
                            return (
                              <div className="single-notification">
                                {notification.created === false ? (
                                  <p>
                                    <strong>
                                      Student{" "}
                                      {notification.student.full_name +
                                        " " +
                                        notification.student.last_name}{" "}
                                      have missed the quiz on{" "}
                                      {notification.subject_round.subject.title}{" "}
                                      subject
                                    </strong>
                                  </p>
                                ) : (
                                  <p>
                                    <strong>
                                      New quiz has been created at subject{" "}
                                      {notification.subject_round.subject.title}{" "}
                                      with round starts at{" "}
                                      {
                                        notification.subject_round.start_date
                                          .date
                                      }{" "}
                                      inside course{" "}
                                      {
                                        notification.subject_round.subject
                                          .course.title
                                      }
                                    </strong>
                                  </p>
                                )}

                                {/* <p>Created At {notification.created_at.split("T")[0] + " " + notification.created_at.split("T")[0].split(".")[0]}</p> */}
                              </div>
                            );
                          })
                        ) : this.state.loading === false &&
                          this.state.quizNotifications.length === 0 ? (
                          <Empty />
                        ) : (
                          <div className="mt-5">
                            <Spin className="spinner" />
                          </div>
                        )}
                      </TabPane>
                    </Tabs>
                  </div>
                ) : null}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  showCalender: state.showCalender.showCalender,
});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ LogOut }, dispatch);
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ErrorHandler(AuthHeader, axiosApiInstance));
