import React, { Component } from "react";
import { Container, Row, Col, Card, Modal, Button } from "react-bootstrap";
import "../../style-sheets/home.css";
import img1 from "../../images/courseImg1.png";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { APILINK } from "../../EndPoint";
import { bindActionCreators } from "redux";
import { showNav } from "../../global-state/actions/showNavAction";
import axios from "axios";

const axiosApiInstance = axios.create();

class HomeCourses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      courseID: "",
    };
  }

  hideNav = () => {
    this.props.showNav(false);
  };
  showWarning = (e) => {
    this.setState({ show: true, courseID: e.target.id });
  };
  close = (e) => {
    this.setState({ show: false });
  };
  deleteCourse = async () => {
    let result = await axiosApiInstance.delete(
      APILINK + `/update_courses/${this.state.courseID}`
    );
    console.log(result);
    if (result.data == "") {
      this.setState({ show: false });
      this.props.getCourses();
    }
  };

  render() {
    return (
      <div className="home-courses">
        <Card>
          <Card.Img
            variant="top"
            src={
              this.props.image == undefined ? img1 : APILINK + this.props.image
            }
          />
          <div className="overlay">
            <p className="">{this.props.cost} CAD</p>
          </div>
          {this.props.user.user_type === "3" ? (
            <React.Fragment>
              {" "}
              <div className="overlay2">
                <Link to={`/edit_course/${this.props.id}`}>
                  <i className="fas fa-edit"></i>Edit
                </Link>
              </div>
              <div
                onClick={this.showWarning}
                id={this.props.id}
                className="overlay3"
              >
                <i id={this.props.id} className="fas fa-trash" />
                Delete
              </div>
            </React.Fragment>
          ) : null}

          <Card.Body style={{ textAlign: "left" }}>
            <Container fluid>
              <Row>
                <Col
                  style={{ padding: "unset", marginBottom: "15px" }}
                  sm="12"
                  md="12"
                >
                  <strong> {this.props.title} </strong>
                </Col>
              </Row>

              <Row>
                <Col style={{ padding: "unset" }} sm="12" md="12">
                  <strong> Description</strong>
                </Col>
                <Col style={{ padding: "unset" }} sm="12" md="12">
                  <div className="courseDesc"> {this.props.description}</div>
                </Col>
              </Row>

              {this.props.user.user_type === "2" && this.props.isAuth ? (
                //    <Button className="enrollBtn">
                //    <a href={`/view_all_rounds/${this.props.id}`}>
                //      <i className="fas fa-eye pr-2"></i>Enroll
                //    </a>
                //  </Button>

                //  )

                !this.props.enroll.is_enroll ? (
                  <Button className="enrollBtn">
                    <a href={`/view_all_rounds/${this.props.id}`}>
                      <i className="fas fa-eye pr-2"></i>Enroll
                    </a>
                  </Button>
                ) : (
                  <>
                    <Button className="viewBtn" style={{marginTop: "6px"}}>
                      <a href={`/course-details/${this.props.enroll.id}`} >
                        {/* <a href={`/courses`}> */}
                        <i className="fas fa-eye    pr-2"></i>View
                      </a>
                    </Button>
                    <br />
                    <Button className="enrollBtn" style={{marginTop: "6px"}}>
                      <a href={`/view_all_rounds/${this.props.id}`} >
                        <i className="fas fa-eye pr-2"></i>Enroll
                      </a>
                    </Button>
                  </>
                )
              ) : !this.props.isAuth ? (
                <Button className="enrollBtn" style={{marginTop: "6px"}}>
                  <a href="/register">
                    {" "}
                    <i className="fas fa-cart-plus pr-2"></i>Enroll
                  </a>
                </Button>
              ) : null}
            </Container>
          </Card.Body>
        </Card>
        <Modal show={this.state.show} onHide={this.close}>
          <Container className="p-4">
            <Row>
              <Col className="text-center" sm={12}>
                {" "}
                <h6>Are u sure u want to delete this Course?</h6>
              </Col>
              <Row style={{ margin: "auto" }}>
                <Col md={12}>
                  <Button
                    onClick={this.deleteCourse}
                    className="forumBtn1 mx-3"
                  >
                    Delete
                  </Button>
                  <Button className="forumBtn1" onClick={this.close}>
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Row>
          </Container>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  isAuth: state.auth.isAuth,
});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ showNav }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(HomeCourses);
