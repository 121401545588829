import React, { Component } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import {
  Container,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Modal,
  Button,
} from "react-bootstrap";
// import { Link } from "react-router-dom";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { APILINK } from "../../EndPoint";
import { Link } from "react-router-dom";
import { Pagination, Spin } from "antd";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { passwordReset } from "../../global-state/actions/passwordAction";
const axiosApiInstance = axios.create();
class ViewLectures extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: [],
      lectureID: "",
      count: 1,
      current: 1,
      show: false,
      columns: [
        {
          name: "Lecture Link",
          selector: "link",
          sortable: true,
          right: true,
        },
        {
          name: "Date",
          selector: "date.date",
          sortable: true,
          right: true,
        },
        {
          name: "Start Time",
          selector: "start_time",
          sortable: true,
          right: true,
        },
        {
          name: "Actions",
          button: true,
          cell: (row) => (
            <div className="table-data-feature">
              <React.Fragment>
                <OverlayTrigger
                  key={"2"}
                  placement={"top"}
                  overlay={
                    <Tooltip id={`tooltip-${"top"}`}>Edit Details</Tooltip>
                  }
                >
                  <Link
                    to={`/edit_lecture/${row.id}/${this.props.match.params.id}`}
                  >
                  
                    <i className="students-icons fas fa fa-edit pr-2"></i>
                  </Link>
                </OverlayTrigger>
                <OverlayTrigger
                  key={"2"}
                  placement={"top"}
                  overlay={
                    <Tooltip id={`tooltip-${"top"}`}>Delete Lecture</Tooltip>
                  }
                >
                 
                  <i
                    onClick={this.showWarning}
                    id={row.id}
                    className="students-icons fas fa fa-trash pr-2"
                  ></i>
                </OverlayTrigger>
              </React.Fragment>
            </div>
          ),
        },
      ],
    };
  }
  showWarning = (e) => {
    this.setState({ show: true, lectureID: e.target.id });
  };
  close = (e) => {
    this.setState({ show: false });
  };
  deleteLecture = async (e) => {
    let result = await axiosApiInstance.delete(
      APILINK + `/meeting_update/${this.state.lectureID}`
    );
    this.setState({ show: false });
    this.getLectures();
  };

  getLectures = async () => {
    this.setState({ loading: true });
    let result;
    result = await axiosApiInstance.get(
      APILINK +
        `/create_meeting?subject_round_id=${this.props.match.params.id}&page=1`
    );
    console.log(result);
    if (result) {
      this.setState({
        data: result.data.data,
        count: result.data.count,
        loading: false,
      });
    }
  };
  componentDidMount() {
    this.getLectures();
  }

  onChange = (page) => {
    console.log(page);
    this.setState(
      {
        current: page,
      },
      async () => {
        await axiosApiInstance
          .get(
            APILINK +
              `/create_meeting?subject_round_id=${this.props.match.params.id}&page=${this.state.current}`
          )
          .then((res) => {
            console.log(res);
            this.setState({ data: res.data.data, count: res.data.count });
          });
      }
    );
  };

  render() {
    console.log(this.state.data);
    return (
      <div>
        {this.state.loading ? (
          <Spin className="spinElement" tip="..loading" />
        ) : (
          <Container className="viewSuper">
            <Row>
              <Col className="p-4 mb-4" style={{ textAlign: "left" }} sm={6}>
                <h4>Lectures link for this round</h4>
              </Col>
            </Row>
            <Row>
              <Col style={{ textAlign: "center" }} sm={12}>
                <DataTable
                  columns={this.state.columns}
                  data={this.state.data}
                />
              </Col>
            </Row>
            <Row className="p-4">
              {this.state.data.length !== 0 ? (
                <Pagination
                  onChange={this.onChange}
                  defaultCurrent={this.state.current}
                  total={this.state.count}
                  current={this.state.current}
                />
              ) : null}
            </Row>
          </Container>
        )}
        <ToastContainer position="bottom-right" />
        <Modal show={this.state.show} onHide={this.close}>
          <Container className="p-4">
            <Row>
              <Col className="text-center" sm={12}>
                {" "}
                <h6>Are u sure u want to delete this lecture?</h6>
              </Col>
              <Row style={{ margin: "auto" }}>
                <Col md={12}>
                  <Button onClick={this.deleteLecture} className="forumBtn1 mx-3">
                    Delete
                  </Button>
                  <Button className="forumBtn1" onClick={this.close}>
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Row>
          </Container>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  isAuth: state.auth.isAuth,
});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ passwordReset }, dispatch);
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ErrorHandler(ViewLectures, axiosApiInstance));
