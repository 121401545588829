import React, { Component } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import axios from "axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { APILINK } from "../../EndPoint";
import { ToastContainer, toast } from "react-toastify";
import { DatePicker } from "antd";
import "../../style-sheets/createCourse.css";

const axiosApiInstance = axios.create();
class AddRound extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: "",
      endDate: "",
      disabled: false,
    };
  }

  add = async () => {
    this.setState({ disabled: true });
    let data = {
      course_id: this.props.match.params.id,
      start_date_: this.state.startDate,
      end_date_: this.state.endDate,
    };
    if (data.start_date_ === "" || data.end_date_ === "") {
      toast.error("Empty Fields Are Not Allowed");
      this.setState({ disabled: false });
    } else if (data.end_date_ <= data.start_date_) {
      toast.error("Please Enter Valid Date!");
      this.setState({ disabled: false });
    } else {
      console.log(data);
      let result = await axiosApiInstance
        .post(APILINK + "/create_course_round", data)
        .catch((err) => {
          console.log(err);
          this.setState({ disabled: false });
        });
      if (result.data) {
        console.log(result.data);
        setTimeout(() => {
          toast.info(`Course Round Added Successfully`);
        }, 500);
        setTimeout(() => {
          this.props.history.push(
            `/view_all_rounds/${this.props.match.params.id}`
          );
        }, 3000);
        console.log(result);
        this.setState({ disabled: false });
      }
    }
  };

  handleStartDate = (date, dateString) => {
    // console.log(date, dateString);
    this.setState({ startDate: dateString });
  };
  handleEndDate = (date, dateString) => {
    // console.log(date, dateString);
    this.setState({ endDate: dateString });
  };
  render() {
    return (
      <div className="admin-home pb-5">
        <Container className="content-margin">
          <Row>
            <h4 style={{ color: "#124E68" }}>Add New Course Round</h4>
          </Row>
          <Form id="infoForm">
            <Row className="mt-2">
              <Col sm={6}>
                <Form.Label>Course start date</Form.Label>

                <DatePicker onChange={this.handleStartDate} />
              </Col>
              <Col sm={6}>
                <Form.Label>Course end date</Form.Label>

                <DatePicker onChange={this.handleEndDate} />
              </Col>
            </Row>
          </Form>
          <Row className="mt-3 px-5">
            <Col sm="12">
              <Button
                className="forumBtn1"
                style={{ float: "right" }}
                onClick={this.add}
                disabled={this.state.disabled}
              >
                Add Round
              </Button>
            </Col>
          </Row>
        </Container>
        <ToastContainer position="bottom-right" />
      </div>
    );
  }
}

export default ErrorHandler(AddRound, axiosApiInstance);
