import React, { Component } from "react";
import { Row, Col, Container, Form, Modal, Accordion } from "react-bootstrap";
import "../../style-sheets/training.css";
import ErrorHandler from "../../error-handler/ErrorHandler";
import axios from "axios";
import { APILINK } from "../../EndPoint";
import { Empty, Spin, Pagination, Input, Button } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import QuestionReplies from "./QuestionReplies";

const axiosApiInstance = axios.create();

class ViewForumQuesAnswers extends Component {
  state = {
    loading: false,
    answers: [],
    count: 1,
    current: 1,
    showAdd: false,
    quesID: "",
    title: "",
    details: "",
    showWarningMsg: false,
    question: "",
    answer:""
  };
  getQuestion = async () => {
    this.setState({ loading: true });
    let result;

    result = await axiosApiInstance.get(
      APILINK + `/get_forum_question/${this.props.match.params.id}`
    );

    this.setState({
      question: result.data,
      loading: false,
    });
  };

  getAnswers = async () => {
    this.setState({ loading: true });
    let result;

    result = await axiosApiInstance.get(
      APILINK +
        `/get_forum_answers?question_id=${this.props.match.params.id}&page=${this.state.current}`
    );

    this.setState({
      answers: result.data.data,
      count: result.data.count,
    });
  };

  componentDidMount() {
    this.getQuestion();
    this.getAnswers();
  }
  onChange = (page) => {
    console.log(page);
    this.setState(
      {
        current: page,
      },
      async () => {
        let result = await axiosApiInstance
          .get(
            APILINK +
              `/get_forum_answers?question_id=${this.props.match.params.id}&page=${this.state.current}`
          )
          .then((res) => {
            console.log(res);
            this.setState({ answers: res.data.data, count: res.data.count });
          });
      }
    );
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  addAnswer = async () => {
    if (this.state.answer == "") {
      setTimeout(() => {
        toast.error(`Please enter your answer first!`);
      }, 500);
    } else {
      let data = {
        question_id: this.props.match.params.id,
        answer_details: this.state.answer,
      };
      let result = await axiosApiInstance
        .post(APILINK + `/create_forum_answer`, data)
        .then((res) => {
          setTimeout(() => {
            toast.info(`A new Answer added successfully`);
          }, 500);
          this.setState({ showAdd: false, answer: "" });
          this.getAnswers();
        });
    }
  };
  //   openWarning = (e) => {
  //     this.setState({ showWarningMsg: true, quesID: e.target.id });
  //   };
  //   hideAdd = (e) => {
  //     this.setState({ showWarningMsg: false });
  //   };

  //   deleteQuestion = async () => {
  //     let result = await axiosApiInstance.delete(
  //       APILINK + `/edit_forum_question/${this.state.quesID}`
  //     );
  //     console.log(result);
  //     if (result.data == "") {
  //       this.setState({ showWarningMsg: false });
  //       this.getQuestions();
  //     }
  //   };
  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };
  updateList = () => {
    this.getAnswers();
  };
  render() {
    return (
      <div>
        <Container className="content-margin">
          <Row>
            <Col sm="12">
              <h4 style={{ color: "#124E68" }}>
                <i
                  onClick={() => this.props.history.goBack()}
                  className="fas fa-chevron-left pr-4"
                ></i>
                All Answers
              </h4>
            </Col>
          </Row>
          <div className="answersDiv p-4">
            <Row className="my-1">
              {this.state.question !== "" ? (
                <React.Fragment>
                  <Col md="6" xs="12">
                    <div className="questCard mt-2 mb-4">
                      <h4>{this.state.question.title}</h4>
                      <p className="pl-4">
                        {this.state.question.question_details}
                      </p>{" "}
                    </div>
                  </Col>
                </React.Fragment>
              ) : this.state.loading ? (
                <Spin className="text-center" tip="...loading" />
              ) : (
                <Empty className="text-center" />
              )}
            </Row>
            <Row className="mb-4">
              <Col sm="9">
                <Input.TextArea
                  rows={6}
                  type="text"
                  name="answer"
                  value={this.state.answer}
                  placeholder="Add your answer..."
                  onChange={this.handleChange}
                />
              </Col>
              <Col sm="3">
                {" "}
                <Button
                  className="forumBtn1"
                  onClick={this.addAnswer}
                  type="primary"
                  htmlType="submit"
                >
                  Add{" "}
                </Button>
              </Col>
              <Col sm="3"></Col>
            </Row>
            <Row>
              <Col className="my-2" md={8} sm="12">
                {this.state.answers.length !== 0
                  ? this.state.answers.map((data, index) => {
                      return (
                        <React.Fragment>
                          <QuestionReplies
                            replies={data.children}
                            mainAnswers={data.answer_details}
                            id={data.id}
                            level={data.level}
                            questionID={this.props.match.params.id}
                            updateList={this.updateList}
                            author={data.author}
                          />
                        </React.Fragment>
                      );
                    })
                  : null}

                {/* <div class="card1">
                  <p>Similar post</p>
                  <h2>
                    How I recreated a Polaroid camera with CSS gradients only
                  </h2>
                </div> */}
              </Col>
            </Row>
          </div>
          <Row className="m-4">
            {this.state.data !== "" ? (
              <Pagination
                onChange={this.onChange}
                defaultCurrent={this.state.current}
                total={this.state.count}
                current={this.state.current}
              />
            ) : null}
          </Row>
        </Container>

        <Modal show={this.state.showWarningMsg} onHide={this.closeWarning}>
          <Container className="p-4">
            <Row>
              <Col className="text-center" sm={12}>
                {" "}
                <h6>Are u sure u want to delete this Question?</h6>
              </Col>
              <Row style={{ margin: "auto" }}>
                <Col md={12}>
                  <Button
                    onClick={this.deleteQuestion}
                    className="headBtn mx-3"
                  >
                    Delete
                  </Button>
                  <Button className="headBtn" onClick={this.close}>
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Row>
          </Container>
        </Modal>
        <ToastContainer position="bottom-right" />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isSubject: state.isSubject.isSubject,
});

export default connect(
  mapStateToProps,
  null
)(ErrorHandler(ViewForumQuesAnswers, axiosApiInstance));
