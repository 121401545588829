// import React, { Component } from "react";
// import { Button } from "antd";
// export default class CalenderDetails extends Component {
//   render() {
//     console.log(this.props);
//     return (
//       <div>
//         <h3 style={{ color: "#124E68" }}>Subjects</h3>
//         <hr />
//         {this.props.details
//           ? this.props.details.map((data) => {
//               return (
//                 <React.Fragment>
//                   <div className="calenderBox my-3">
//                     <p>
//                       <strong>Subject </strong>
//                       {data.subject.title}
//                     </p>
//                     <p>
//                       <strong>Tutor </strong>
//                       {data.tutor.full_name}
//                     </p>
//                     <p>
//                       <strong>Start Time </strong>
//                       {data.start_time}
//                     </p>
//                     <p>
//                       <strong>Start Date </strong>
//                       {data.start_date.date}
//                     </p>
//                     <p>
//                       <strong>End Date </strong>
//                       {data.end_date.date}
//                     </p>

//                     {/* 
//                     {this.props.quizes
//                       ? this.props.quizes.map((x) =>
//                           data.id == x.subject_round ? (
//                             <>
//                               <hr style={{ backgroundColor: "white" }} />
//                               <p>
//                                 {" "}
//                                 <strong>Quiz Title </strong>
//                                 {x.title}
//                               </p>
//                               <p>
//                                 {" "}
//                                 <strong>Quiz Date </strong>
//                                 {x.start_date.date}
//                               </p>
//                             </>
//                           ) : null
//                         )
//                       : null} */}
//                   </div>
//                 </React.Fragment>
//               );
//             })
//           : null}

//         {this.props.meetings && this.props.selectedDay !== ""
//           ? this.props.meetings.map((x) =>
//               x.date.date == this.props.selectedDay ? (
//                 <div className="calenderBox my-3">
//                   <p className="lectureLink">
//                     <strong>Lecture Title </strong>
//                     {x.title}
//                   </p>
//                   <p className="lectureLink">
//                     <strong>Lecture Zoom Link </strong>
//                     <a href={`${x.link}`} target="_blank">
//                       Click Here
//                     </a>
//                   </p>
//                   <p>
//                     {" "}
//                     <strong>Lecture Date </strong>
//                     {x.date.date}
//                   </p>
//                   <p>
//                     {" "}
//                     <strong>Lecture Start Time </strong>
//                     {x.start_time}
//                   </p>
//                   <p>
//                     {" "}
//                     <strong>Lecture Duration </strong>
//                     {x.duration}
//                   </p>
//                 </div>
//               ) : null
//             )
//           : null}

//         {this.props.quizes && this.props.selectedDay !== ""
//           ? this.props.quizes.map((x) =>
//               x.start_date.date == this.props.selectedDay ? (
//                 <div className="calenderBox my-3">
//                   <p>
//                     {" "}
//                     <strong>Quiz Title </strong>
//                     {x.title}
//                   </p>
//                   <p>
//                     {" "}
//                     <strong>Quiz Date </strong>
//                     {x.start_date.date}
//                   </p>
//                 </div>
//               ) : null
//             )
//           : null}
//       </div>
//     );
//   }
// }

// import React, { Component } from "react";
// import { Button } from "antd";

// export default class CalenderDetails extends Component {
//   renderSubjectDetails = () => {
//     const { details } = this.props;

//     if (!details) return null;

//     return details.map((data) => (
//       <div className="calenderBox my-3" key={data.id}>
//         <p>
//           <strong>Subject: </strong>
//           {data.subject.title}
//         </p>
//         <p>
//           <strong>Tutor: </strong>
//           {data.tutor.full_name}
//         </p>
//         <p>
//           <strong>Start Time: </strong>
//           {data.start_time}
//         </p>
//         <p>
//           <strong>Start Date: </strong>
//           {data.start_date.date}
//         </p>
//         <p>
//           <strong>End Date: </strong>
//           {data.end_date.date}
//         </p>
//       </div>
//     ));
//   };

//   renderMeetings = () => {
//     const { meetings, selectedDay } = this.props;

//     if (!meetings || !selectedDay) return null;

//     return meetings
//       .filter((meeting) => meeting.date.date === selectedDay)
//       .map((meeting) => (
//         <div className="calenderBox my-3" key={meeting.id}>
//           <p className="lectureLink">
//             <strong>Lecture Title: </strong>
//             {meeting.title}
//           </p>
//           <p className="lectureLink">
//             <strong>Lecture Zoom Link: </strong>
//             <a href={meeting.link} target="_blank" rel="noopener noreferrer">
//               Click Here
//             </a>
//           </p>
//           <p>
//             <strong>Lecture Date: </strong>
//             {meeting.date.date}
//           </p>
//           <p>
//             <strong>Lecture Start Time: </strong>
//             {meeting.start_time}
//           </p>
//           <p>
//             <strong>Lecture Duration: </strong>
//             {meeting.duration}
//           </p>
//         </div>
//       ));
//   };

//   renderQuizzes = () => {
//     const { quizes, selectedDay } = this.props;

//     if (!quizes || !selectedDay) return null;

//     return quizes
//       .filter((quiz) => quiz.start_date.date === selectedDay)
//       .map((quiz) => (
//         <div className="calenderBox my-3" key={quiz.id}>
//           <p>
//             <strong>Quiz Title: </strong>
//             {quiz.title}
//           </p>
//           <p>
//             <strong>Quiz Date: </strong>
//             {quiz.start_date.date}
//           </p>
//         </div>
//       ));
//   };

//   render() {
//     return (
//       <div>
//         <h3 style={{ color: "#124E68" }}>Subjects</h3>
//         <hr />
//         {this.renderSubjectDetails()}
//         {this.renderMeetings()}
//         {this.renderQuizzes()}
//       </div>
//     );
//   }
// }



// import React from 'react';

// class CalenderDetails extends React.Component {
//   renderSubjectDetails = () => {
//     const { details } = this.props;

//     if (!details) return null;

//     return details
//       .filter((data) => data.subject) // Ensures only subject-related events are rendered
//       .map((data) => (
//         <div className="calenderBox my-3" key={data.id}>
//           <p>
//             <strong>Subject: </strong>
//             {data.subject.title}
//           </p>
//           <p>
//             <strong>Tutor: </strong>
//             {data.tutor.full_name}
//           </p>
//           <p>
//             <strong>Start Time: </strong>
//             {data.start_time}
//           </p>
//           <p>
//             <strong>Start Date: </strong>
//             {data.start_date?.date || 'No start date available'}
//           </p>
//           <p>
//             <strong>End Date: </strong>
//             {data.end_date?.date || 'No end date available'}
//           </p>
//         </div>
//       ));
//   };

//   renderMeetingDetails = () => {
//     const { details } = this.props;

//     if (!details) return null;

//     return details
//       .filter((data) => data.title && data.link) // Ensures only meeting-related events are rendered
//       .map((meeting) => (
//         <div className="calenderBox my-3" key={meeting.id}>
//           <p>
//             <strong>Lecture Title: </strong>
//             {meeting.title}
//           </p>
//           <p>
//             <strong>Lecture Zoom Link: </strong>
//             <a href={meeting.link} target="_blank" rel="noopener noreferrer">
//               Click Here
//             </a>
//           </p>
//           <p>
//             <strong>Lecture Date: </strong>
//             {meeting.date?.date || 'No date available'}
//           </p>
//           <p>
//             <strong>Lecture Start Time: </strong>
//             {meeting.start_time || 'No start time available'}
//           </p>
//           <p>
//             <strong>Lecture Duration: </strong>
//             {meeting.duration || 'No duration available'}
//           </p>
//         </div>
//       ));
//   };

//   renderQuizDetails = () => {
//     const { details } = this.props;

//     if (!details) return null;

//     return details
//       .filter((data) => !data.subject && data.title) // Ensures only quiz-related events are rendered
//       .map((quiz) => (
//         <div className="calenderBox my-3" key={quiz.id}>
//           <p>
//             <strong>Quiz Title: </strong>
//             {quiz.title}
//           </p>
//           <p>
//             <strong>Quiz Date: </strong>
//             {quiz.start_date?.date || 'No date available'}
//           </p>
//         </div>
//       ));
//   };

//   render() {
//     return (
//       <div>
//         <h3 style={{ color: '#124E68' }}>Subjects</h3>
//         <hr />
//         {this.renderSubjectDetails()}

//         <h3 style={{ color: '#124E68' }}>Meetings</h3>
//         <hr />
//         {this.renderMeetingDetails()}

//         <h3 style={{ color: '#124E68' }}>Quizzes</h3>
//         <hr />
//         {this.renderQuizDetails()}
//       </div>
//     );
//   }
// }

// export default CalenderDetails;

import React from "react";

class CalenderDetails extends React.Component {
  renderSubjectDetails = () => {
    const { details } = this.props;

    if (!details) return null;

    return details
      .filter((data) => data.subject) // Ensures only subject-related events are rendered
      .map((data) => (
        <div className="calenderBox my-3" style={styles.box} key={data.id}>
          <p style={styles.text}>
            <strong>Subject: </strong>
            {data.subject.title}
          </p>
          <p style={styles.text}>
            <strong>Tutor: </strong>
            {data.tutor.full_name}
          </p>
          <p style={styles.text}>
            <strong>Start Time: </strong>
            {data.start_time}
          </p>
          <p style={styles.text}>
            <strong>Start Date: </strong>
            {data.start_date?.date || "No start date available"}
          </p>
          <p style={styles.text}>
            <strong>End Date: </strong>
            {data.end_date?.date || "No end date available"}
          </p>
        </div>
      ));
  };

  renderMeetingDetails = () => {
    const { details } = this.props;

    if (!details) return null;

    return details
      .filter((data) => data.title && data.link) // Ensures only meeting-related events are rendered
      .map((meeting) => (
        <div className="calenderBox" style={styles.box} key={meeting.id}>
          <p style={styles.text}>
            <strong>Lecture Title: </strong>
            {meeting.title}
          </p>
          <p style={styles.text}>
            <strong>Lecture Zoom Link: </strong>
            <a href={meeting.link} target="_blank" rel="noopener noreferrer" style={styles.link}>
              Click Here
            </a>
          </p>
          <p style={styles.text}>
            <strong>Lecture Date: </strong>
            {meeting.date?.date || "No date available"}
          </p>
          <p style={styles.text}>
            <strong>Lecture Start Time: </strong>
            {meeting.start_time || "No start time available"}
          </p>
          <p style={styles.text}>
            <strong>Lecture Duration: </strong>
            {meeting.duration || "No duration available"}
          </p>
        </div>
      ));
  };

  renderQuizDetails = () => {
    const { details } = this.props;

    if (!details) return null;

    return details
      .filter((data) => !data.subject && data.title) // Ensures only quiz-related events are rendered
      .map((quiz) => (
        <div className="calenderBox" style={styles.box} key={quiz.id}>
          <p style={styles.text}>
            <strong>Quiz Title: </strong>
            {quiz.title}
          </p>
          <p style={styles.text}>
            <strong>Quiz Date: </strong>
            {quiz.start_date?.date || "No date available"}
          </p>
        </div>
      ));
  };

  render() {
    return (
      <div>
        <h3 style={styles.header}>Subjects</h3>
        <hr style={styles.hr} />
        {this.renderSubjectDetails()}

        <h3 style={styles.header}>Meetings</h3>
        <hr style={styles.hr} />
        {this.renderMeetingDetails()}

        <h3 style={styles.header}>Quizzes</h3>
        <hr style={styles.hr} />
        {this.renderQuizDetails()}
      </div>
    );
  }
}

const styles = {
  box: {
    backgroundColor: "#f4f4f4",
    padding: "10px",
    borderRadius: "5px",
    marginBottom: "15px",
  },
  text: {
    margin: "5px 0",
    color: "#333",
  },
  link: {
    color: "#1a73e8",
    textDecoration: "underline",
  },
  header: {
    color: "#124E68",
  },
  hr: {
    borderTop: "2px solid #124E68",
  },
};

export default CalenderDetails;

