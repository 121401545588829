import React, { Component } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import axios from "axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { APILINK } from "../../EndPoint";
import { ToastContainer, toast } from "react-toastify";
import { DatePicker } from "antd";
import "../../style-sheets/createCourse.css";

const axiosApiInstance = axios.create();
class CreateCourse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      desc: "",
      order: "",
      policy: "1",
      startDate: "",
      endDate: "",
      selectedFile: null,
      disabled: false,
      cost: ""
    };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  createCourse = async () => {
    if (this.state.title === "" || this.state.order ==="") {
      setTimeout(() => {
        toast.error(`Please Fill out the required fields !`);
      }, 500);
    } else {
      this.setState({ disabled: true });

      let data;
      let eventdata;
      let result;
      if (this.state.selectedFile == null) {
        data = {
          title: this.state.title,
          display_order: this.state.order,
          description: this.state.desc,
          installment_policy: this.state.policy,
          cost: this.state.cost,
          installments_cost: this.state.installmentcost
        };

        result = await axiosApiInstance.post(APILINK + "/create_course", data);
      } else {
        eventdata = new FormData();
        eventdata.append("title", this.state.title);
        eventdata.append("description", this.state.desc);
        eventdata.append("installment_policy", this.state.policy);
        eventdata.append("image", this.state.selectedFile);
        eventdata.append("display_order", this.state.order);
        eventdata.append("cost", this.state.cost);
        eventdata.append("installments_cost", this.state.installmentcost);

        result = await axiosApiInstance.post(
          APILINK + "/create_course",
          eventdata
        );
      }

      if (result) {
        console.log(result);
        setTimeout(() => {
          toast.info(`Course Added Successfully`);
        }, 500);
        setTimeout(() => {
          this.props.history.push("/admin_courses");
        }, 3000);
      }
    }
  };

  onFileUpload = (e) => {
    e.preventDefault();

    this.setState({
      selectedFile: e.target.files[0],
      fileName: e.target.files[0].name
    });
  };
  handleStartDate = (date, dateString) => {
    // console.log(date, dateString);
    this.setState({ startDate: dateString });
  };
  handleEndDate = (date, dateString) => {
    // console.log(date, dateString);
    this.setState({ endDate: dateString });
  };
  render() {
    return (
      <div className="admin-home pb-5">
        <Container className="content-margin">
          <Form id="infoForm">
            <Row>
              <Col md="6" xs="12">
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>
                    <strong style={{ color: "red", paddingRight: "5px" }}>
                      *
                    </strong>
                    Course Title
                  </Form.Label>
                  <Form.Control
                    name="title"
                    value={this.state.title}
                    onChange={this.handleChange}
                    type="text"
                    placeholder="Course Title"
                    maxlength="60"
                  />
                </Form.Group>
              </Col>
              <Col md="6" xs="12">
                <Form.Group controlId="exampleForm.ControlInput2">
                  <Form.Label>Course Description</Form.Label>
                  <Form.Control
                    name="desc"
                    value={this.state.desc}
                    onChange={this.handleChange}
                    type="text"
                    placeholder="Course Description"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md="6" xs="12">
                <Form.Group controlId="exampleForm.ControlInput">
                  <Form.Label>
                    {" "}
                    <strong style={{ color: "red", paddingRight: "5px" }}>
                      *
                    </strong>
                    Installment Policy
                  </Form.Label>
                  <Form.Control
                    name="policy"
                    value={this.state.policy}
                    onChange={this.handleChange}
                    as="select"
                  >
                    <option selected disabled style={{ display: "none" }}>
                      Choose installment policy
                    </option>
                    <option value="1">Half/Full </option>
                    <option value="2">Full</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col md="6" xs="12">
                <Form.Group controlId="exampleForm.ControlInput2">
                  <Form.Label>
                    <strong style={{ color: "red", paddingRight: "5px" }}>
                      *
                    </strong>
                    Display Order
                  </Form.Label>
                  <Form.Control
                    name="order"
                    value={this.state.order}
                    onChange={this.handleChange}
                    type="number"
                    placeholder="Display Order"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md="6" xs="12">
                <Form.Label>Course installment Cost</Form.Label>
                <Form.Control
                  name="installmentcost"
                  value={this.state.installmentcost}
                  onChange={this.handleChange}
                  type="number"
                  placeholder="Course installment Cost"
                />
              </Col>
              <Col md="6" xs="12">
                <Form.Label>Course Cost</Form.Label>
                <Form.Control
                  name="cost"
                  value={this.state.cost}
                  onChange={this.handleChange}
                  type="number"
                  placeholder="Course Cost"
                />
              </Col>
            </Row>
            <Row>
              <Col md="6" xs="12">
                <Form.Group controlId="exampleForm.ControlInput3">
                  <Form.Label>Course Image</Form.Label>
                  <div class="wrapper">
                    <div class="file-upload">
                      <input onChange={this.onFileUpload} type="file" />
                      <i class="fa fa-plus"></i>
                    </div>
                  </div>{" "}
                  <p>{this.state.fileName}</p>
                </Form.Group>
              </Col>
            </Row>
          </Form>
          <Row className="mt-3">
            <Col sm="12">
              <Button
                disabled={this.state.disabled}
                className="forumBtn1"
                onClick={this.createCourse}
              >
                Create Course
              </Button>
            </Col>
          </Row>
        </Container>
        <ToastContainer position="bottom-right" />
      </div>
    );
  }
}

export default ErrorHandler(CreateCourse, axiosApiInstance);
