import React, { Component } from "react";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import axios from "axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { APILINK } from "../../EndPoint";
import "../../style-sheets/createMaterial.css";
import { connect } from "react-redux";
import { Empty, Spin, Pagination } from "antd";
import { ToastContainer, toast } from "react-toastify";

const axiosApiInstance = axios.create();
class ViewMaterial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      materials: [],
      count: 1,
      current: 1,
      loading: false,
      materialID: "",
      show: false
    };
  }

  showWarning = (e) => {
    this.setState({ show: true, materialID: e.target.id });
  };

  close = (e) => {
    this.setState({ show: false });
  };

  deleteMaterial = async (e) => {
    let result = await axiosApiInstance.delete(
      APILINK + `/material_details/${this.state.materialID}`
    );
    this.setState({ show: false });
    this.getMaterial();
  };

  getMaterial = async () => {
    this.setState({ loading: true });
    let result = await axiosApiInstance.get(
      APILINK + `/view_material?subject_round_id=${this.props.match.params.id}`
    );
    console.log(result);
    this.setState({ materials: result.data.data, loading: false });
  };
  componentDidMount() {
    this.getMaterial();
  }
  getWatermark = async (e) => {
    // console.log(e.target.id);
    let result = await axiosApiInstance.get(
      APILINK + `/watermark?url=${e.target.id}`
    );
    console.log(result);

    if (result) {
    setTimeout(() => {
      toast.info("Please Check your Email");
    }, 50);

    }
  };
  render() {
    return (
      <div className="admin-home pb-5">
        <Container className="content-margin">
          <Row>
            <Col className="p-4 mb-4" style={{ textAlign: "left" }} sm={6}>
              <h2> View Material Details</h2>
            </Col>
          </Row>

          {this.state.materials.length !== 0 && this.state.loading === false ? (
            <Row>
              {this.state.materials.map((material) => {
                return (
                  <Col sm="4">
                    {material.material_type === "1" ? (
                      <>
                        {" "}
                        <p>{material.title}</p>
                        <audio controlsList="nodownload" controls>
                          <source src={ material.data} />
                        </audio>{" "}
                      </>
                    ) : (
                      <p
                        onClick={this.getWatermark}
                        id={material.id}
                        className="materialName"
                      >
                        {material.title}
                      </p>
                    )}
                    {this.props.user.user_type === "3" ?<i
                      onClick={this.showWarning}
                      id={material.id}
                      className="students-icons fas fa fa-trash pr-2"
                    ></i> : null }
                    
                  </Col>
                );
              })}
            </Row>
          ) : this.state.loading ? (
            <Spin tip="...loading" />
          ) : (
            <Empty />
          )}

          {/* {this.state.materials.length !== 0 ? (
            <Row>
              {this.state.materials.map((material) => {
                return (
                  <Col sm="2">
                    {material.material_type === "1" ? <>
                      <audio controls>
                        <source src={material.data} />

                      </audio>
                    </> : <p>
                      {material.data.includes("docx") ? (
                        <img src={doc} className="img-fluid" />
                      ) : material.data.includes("pdf") ? (
                        <img src={pdf} className="img-fluid" />
                      ) : null}
                    </p>}

                  </Col>
                );
              })}
            </Row>
          ) : null} */}
        </Container>
        <Modal show={this.state.show} onHide={this.close}>
          <Container className="p-4">
            <Row>
              <Col className="text-center" sm={12}>
                {" "}
                <h6>Are u sure u want to delete this Material?</h6>
              </Col>
              <Row style={{ margin: "auto" }}>
                <Col md={12}>
                  <Button
                    onClick={this.deleteMaterial}
                    className="forumBtn1 mx-3"
                  >
                    Delete
                  </Button>
                  <Button className="forumBtn1" onClick={this.close}>
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Row>
          </Container>
        </Modal>
        <ToastContainer position="bottom-right" />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(ErrorHandler(ViewMaterial, axiosApiInstance));
