import React, { Component } from "react";
import { Container, Row, Col, Button, Dropdown } from "react-bootstrap";
import "../../style-sheets/header.css";
import img1 from "../../images/logo.png";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { LogOut } from "../../global-state/actions/authAction";
import { GoogleLogout } from "react-google-login";

class HomeHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  logout = (e) => {
    this.props.LogOut();
  };
  render() {
    return (
      <div className="header">
        <Container fluid className="">
          <Row>
            <Col className="text-left" xs="4">
              <img src={img1} alt="img-fluid" className="homeLogo" />
            </Col>
            <Col className="text-right btnsDiv" xs="8">
              {this.props.user.user_type === "3" ? (
                <div>
                  <Dropdown>
                    <Dropdown.Toggle className="navBtn" id="dropdown-basic">
                      <i className="fas fa-user-circle navIcon pr-2"></i>
                      {this.props.user.full_name}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="navDropDown">
                      <Dropdown.Item onClick={this.logout} href="/">
                        Log out
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              ) : this.props.user.user_type === "2" ? (
                <Dropdown>
                  <Dropdown.Toggle className="navBtn" id="dropdown-basic">
                    <i className="fas fa-user-circle navIcon pr-2"></i>
                    {this.props.user.full_name}
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="navDropDown">
                    <Dropdown.Item href="/courses">My Courses</Dropdown.Item>
                    <Dropdown.Item href="/payments">My Payments</Dropdown.Item>
                    <Dropdown.Item href="/chat">Chat</Dropdown.Item>

                    {this.props.user.social_provider == "google" ? (
                      <Dropdown.Item href="/">
                        <GoogleLogout
                          clientId="834798242328-9b6rc4gin4p64v2sqqr8pulnlhuh52i6.apps.googleusercontent.com"
                          buttonText="Logout"
                          onLogoutSuccess={this.logout}
                          className="logoutGoogle"
                        ></GoogleLogout>
                      </Dropdown.Item>
                    ) : (
                      <Dropdown.Item onClick={this.logout} href="/">
                        Logout
                      </Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <>
                  <Button className="btn1 p-2 mr-3">
                    <Link to="/login">Log in </Link>
                  </Button>
                  <Button className="btn2 p-2">
                    <Link to="/register">Sign up </Link>
                  </Button>
                </>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.auth.user,
  isAuth: state.auth.isAuth,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ LogOut }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(HomeHeader);
