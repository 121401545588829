import React, { Component } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import axios from "axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { APILINK } from "../../EndPoint";
import { ToastContainer, toast } from "react-toastify";
import { Checkbox } from "antd";
import "../../style-sheets/createCourse.css";

const axiosApiInstance = axios.create();
class CreateForum extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      desc: "",
      public: false,
      disabled: false,
    };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  CreateForum = async () => {
    if (this.state.title == "") {
      setTimeout(() => {
        toast.error(`Please Fill out the required fields !`);
      }, 500);
    } else {
      this.setState({ disabled: true });
      let data = {
        title: this.state.title,
        description: this.state.desc,
        course_round_id: this.props.match.params.id,
        // is_public: this.state.public,
      };

      let result = await axiosApiInstance.post(APILINK + "/create_forum", data);

      if (result) {
        console.log(result);
        setTimeout(() => {
          toast.info(`Forum Added Successfully`);
        }, 500);
        setTimeout(() => {
          this.props.history.push(
            `/view_round_fourm/${this.props.match.params.id}`
          );
        }, 3000);
      }
    }
  };
  handlePublic = (e) => {
    console.log(e.target.checked);
    this.setState({ public: e.target.checked });
  };
  render() {
    return (
      <div className="admin-home pb-5">
        <Container className="content-margin">
          <h3>Create Forum</h3>
          <Form id="infoForm">
            <Row>
              <Col sm={6}>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>
                    <strong style={{ color: "red", paddingRight: "5px" }}>
                      *
                    </strong>
                    Forum Title
                  </Form.Label>
                  <Form.Control
                    name="title"
                    value={this.state.title}
                    onChange={this.handleChange}
                    type="text"
                    placeholder="Forum Title"
                    maxlength="60"
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group controlId="exampleForm.ControlInput2">
                  <Form.Label>Forum Description</Form.Label>
                  <Form.Control
                    name="desc"
                    value={this.state.desc}
                    onChange={this.handleChange}
                    type="text"
                    placeholder="Forum Description"
                  />
                </Form.Group>
              </Col>
            </Row>
            {/* <Row>
              <Col sm={6}>
                <Form.Group controlId="exampleForm.ControlInput3">
                  <Checkbox onChange={this.handlePublic}>Is Public?</Checkbox>
                </Form.Group>
              </Col>
            </Row> */}
          </Form>
          <Row className="mt-3">
            <Col sm="12">
              <Button
                disabled={this.state.disabled}
                className="forumBtn1"
                onClick={this.CreateForum}
              >
                Create Forum
              </Button>
            </Col>
          </Row>
        </Container>
        <ToastContainer position="bottom-right" />
      </div>
    );
  }
}

export default ErrorHandler(CreateForum, axiosApiInstance);
