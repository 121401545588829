import React, { Component } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Modal,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
// import { Link } from "react-router-dom";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { APILINK } from "../../EndPoint";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DatePicker, Select, TimePicker, Spin, Pagination } from "antd";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isSubject } from "../../global-state/actions/isSubjectAction";
const axiosApiInstance = axios.create();
class SubjectRounds extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: [],
      count: 1,
      current: 1,
      tutors: [],
      time: "",
      showEditModel: false,
      columns: [
        {
          name: "Subject title",
          selector: "subject.title",
          sortable: true,
          right: true
        },
        {
          name: "Course title",
          selector: "subject.course.title",
          sortable: true,
          right: true
        },
        {
          name: "Start Date",
          selector: "start_date.date",
          sortable: true,
          right: true
        },
        {
          name: "End Date",
          selector: "end_date.date",
          sortable: true,
          right: true
        },
        {
          name: "Tutor",
          selector: "tutor.full_name",
          sortable: true,
          right: true
        },

        {
          name: "Actions",
          width: "290px",
          button: true,
          cell: (row) => (
            <div className="table-data-feature">
              {console.log(row)}
              {this.props.user.user_type === "3" ? (
                <React.Fragment>
                  <OverlayTrigger
                    key={"3"}
                    placement={"top"}
                    overlay={
                      <Tooltip id={`tooltip-${"top"}`}>Edit Round</Tooltip>
                    }
                  >
                    <i
                      onClick={() => this.showEdit(row)}
                      value={row}
                      className="fas fa-edit pr-2"
                    ></i>
                  </OverlayTrigger>
                  <OverlayTrigger
                    key={"3"}
                    placement={"top"}
                    overlay={
                      <Tooltip id={`tooltip-${"top"}`}>Delete Round</Tooltip>
                    }
                  >
                    <i
                      onClick={this.showWarning}
                      id={row.id}
                      className="fas fa-trash pr-2"
                    ></i>
                  </OverlayTrigger>
                  <OverlayTrigger
                    key={"3"}
                    placement={"top"}
                    overlay={
                      <Tooltip id={`tooltip-${"top"}`}>
                        Create Materials
                      </Tooltip>
                    }
                  >
                    <Link to={`/add_materials/${row.id}`}>
                      {" "}
                      <i className="fas fa-plus-circle pr-2"></i>
                    </Link>
                  </OverlayTrigger>
                  <OverlayTrigger
                    key={"2"}
                    placement={"top"}
                    overlay={
                      <Tooltip id={`tooltip-${"top"}`}>Create Lecture</Tooltip>
                    }
                  >
                    <Link to={`/create_meeting/${row.id}`}>
                      {" "}
                      <i className="fas fa-plus-circle pr-2"></i>
                    </Link>
                  </OverlayTrigger>
                  <OverlayTrigger
                    key={"2"}
                    placement={"top"}
                    overlay={
                      <Tooltip id={`tooltip-${"top"}`}>
                        View Round Lectures
                      </Tooltip>
                    }
                  >
                    <Link to={`/view_lectures/${row.id}`}>
                      {" "}
                      <i className="fas fa-eye pr-2"></i>
                    </Link>
                  </OverlayTrigger>
                  <OverlayTrigger
                    key={"4"}
                    placement={"top"}
                    overlay={
                      <Tooltip id={`tooltip-${"top"}`}>View Materials</Tooltip>
                    }
                  >
                    <Link to={`/view_materials/${row.id}`}>
                      {" "}
                      <i className="fas fa-eye pr-2"></i>
                    </Link>
                  </OverlayTrigger>
                  <OverlayTrigger
                    key={"2"}
                    placement={"top"}
                    overlay={
                      <Tooltip id={`tooltip-${"top"}`}>Create Quiz</Tooltip>
                    }
                  >
                    <Link to={`/create_quiz/${row.id}`}>
                      {" "}
                      <i className="fas fa-plus-circle pr-2"></i>
                    </Link>
                  </OverlayTrigger>
                  <OverlayTrigger
                    key={"5"}
                    placement={"top"}
                    overlay={
                      <Tooltip id={`tooltip-${"top"}`}>Create Training</Tooltip>
                    }
                  >
                    <Link to={`/create_training/${row.id}`}>
                      {" "}
                      <i
                        onClick={this.props.isSubject(true)}
                        className="fas fa-plus-circle pr-2"
                      ></i>
                    </Link>
                  </OverlayTrigger>
                  <OverlayTrigger
                    key={"7"}
                    placement={"top"}
                    overlay={
                      <Tooltip id={`tooltip-${"top"}`}>View Trainings</Tooltip>
                    }
                  >
                    <Link to={`/view_training/${row.id}`}>
                      {" "}
                      <i
                        onClick={this.props.isSubject(true)}
                        className="fas fa-eye pr-2"
                      ></i>
                    </Link>
                  </OverlayTrigger>
                  <OverlayTrigger
                    key={"7"}
                    placement={"top"}
                    overlay={
                      <Tooltip id={`tooltip-${"top"}`}>View Quizzes</Tooltip>
                    }
                  >
                    <Link to={`/view_quiz/${row.id}`}>
                      {" "}
                      <i className="fas fa-eye pr-2"></i>
                    </Link>
                  </OverlayTrigger>
                </React.Fragment>
              ) : this.props.user.user_type === "1" ? (
                <React.Fragment>
                  <OverlayTrigger
                    key={"3"}
                    placement={"top"}
                    overlay={
                      <Tooltip id={`tooltip-${"top"}`}>
                        Create Materials
                      </Tooltip>
                    }
                  >
                    <Link to={`/add_materials/${row.id}`}>
                      {" "}
                      <i className="fas fa-plus pr-2"></i>
                    </Link>
                  </OverlayTrigger>
                  <OverlayTrigger
                    key={"2"}
                    placement={"top"}
                    overlay={
                      <Tooltip id={`tooltip-${"top"}`}>Create Lecture</Tooltip>
                    }
                  >
                    <Link to={`/create_meeting/${row.id}`}>
                      {" "}
                      <i className="fas fa-plus-circle pr-2"></i>
                    </Link>
                  </OverlayTrigger>
                  <OverlayTrigger
                    key={"2"}
                    placement={"top"}
                    overlay={
                      <Tooltip id={`tooltip-${"top"}`}>
                        View Round Lectures
                      </Tooltip>
                    }
                  >
                    <Link to={`/view_lectures/${row.id}`}>
                      {" "}
                      <i className="fas fa-eye pr-2"></i>
                    </Link>
                  </OverlayTrigger>
                  <OverlayTrigger
                    key={"4"}
                    placement={"top"}
                    overlay={
                      <Tooltip id={`tooltip-${"top"}`}>View Materials</Tooltip>
                    }
                  >
                    <Link to={`/view_materials/${row.id}`}>
                      {" "}
                      <i className="fas fa-eye pr-2"></i>
                    </Link>
                  </OverlayTrigger>
                  <OverlayTrigger
                    key={"2"}
                    placement={"top"}
                    overlay={
                      <Tooltip id={`tooltip-${"top"}`}>Create Quiz</Tooltip>
                    }
                  >
                    <Link to={`/create_quiz/${row.id}`}>
                      {" "}
                      <i className="fas fa-plus-circle pr-2"></i>
                    </Link>
                  </OverlayTrigger>
                  <OverlayTrigger
                    key={"7"}
                    placement={"top"}
                    overlay={
                      <Tooltip id={`tooltip-${"top"}`}>View Quizzes</Tooltip>
                    }
                  >
                    <Link to={`/view_quiz/${row.id}`}>
                      {" "}
                      <i className="fas fa-eye pr-2"></i>
                    </Link>
                  </OverlayTrigger>
                </React.Fragment>
              ) : null}
              {this.props.user.user_type === "3" ||
              this.props.user.user_type === "1" ? (
                <OverlayTrigger
                  key={"3"}
                  placement={"top"}
                  overlay={
                    <Tooltip id={`tooltip-${"top"}`}>View Quiz Grades</Tooltip>
                  }
                >
                  <Link to={`/quiz_grades/${row.id}`}>
                    {" "}
                    <i className="fas fa-eye pr-2"></i>
                  </Link>
                </OverlayTrigger>
              ) : null}
            </div>
          )
        }
      ]
    };
  }
  // editModal = async (e) => {
  //   let result = await axiosApiInstance.get(
  //     APILINK + `/subject_deatils/${this.props.match.params.id}`
  //   );
  //   if (result) {
  //     this.setState({
  //       courseID: result.data.course_round.id,
  //       roundID: e.target.id,
  //     });
  //   }
  //   console.log(e.target.value);
  //   console.log(e.target.id);
  //   this.setState({ showEditModel: true, roundID: e.target.id });
  // };
  showWarning = (e) => {
    this.setState({ showDelete: true, roundID: e.target.id });
  };
  close = (e) => {
    this.setState({ showDelete: false });
  };
  showEdit = (e) => {
    console.log(e);
    this.setState(
      {
        startDate: e.start_date.date,
        endDate: e.end_date.date,
        startTime: e.start_time,
        selectedTutor: e.tutor.full_name,
        selectedTutorID: e.tutor.id,
        roundID: e.id
      },
      () => {
        this.setState({ showEditModel: true });
      }
    );
  };
  hideEdit = () => {
    this.setState({ showEditModel: false });
  };
  getRounds = async () => {
    this.setState({ loading: true });
    let result;
    if (this.props.user.user_type == "1") {
      result = await axiosApiInstance.get(
        APILINK +
          `/create_subject_round?course_round_id=${this.props.match.params.id}&tutor=true`
      );
    } else {
      result = await axiosApiInstance.get(
        APILINK +
          `/create_subject_round?course_round_id=${this.props.match.params.id}`
      );
    }

    console.log(result);
    if (result.data.data) {
      this.setState({
        data: result.data.data,
        count: result.data.count,
        loading: false
      });
    }
  };
  componentDidMount() {
    this.getRounds();
  }
  handleTime = (time, timeString) => {
    this.setState({ time: timeString }, () => {
      console.log(this.state.startTime);
    });
  };
  handleStartDate = (date, dateString) => {
    // console.log(date, dateString);
    this.setState({ startDate: dateString });
  };
  handleEndDate = (date, dateString) => {
    // console.log(date, dateString);
    this.setState({ endDate: dateString });
  };
  handleTutor = (e) => {
    this.setState({ selectedTutor: e, selectedTutorID: e });
  };
  getAllTutors = async () => {
    let result = await axiosApiInstance.get(
      APILINK + "/tutor_signup?user_type=1"
    );
    this.setState({ tutors: result.data }, () => {
      console.log(this.state.tutors);
    });
  };
  editSubjectRound = async () => {
    let data = {
      start_time: this.state.startTime,
      start_date_: this.state.startDate,
      end_date_: this.state.endDate,
      tutor_id: this.state.selectedTutorID,
      course_round_id: this.props.match.params.id,
      subject_round_id: this.state.roundID
    };
    console.log(data);
    let result = await axiosApiInstance.put(
      APILINK + `/update_subject_round/${this.state.roundID}`,
      data
    );
    if (result) {
      if (result.data) {
        this.setState({ showEditModel: false });
        setTimeout(() => {
          toast.info("Subject has been edited successfully");
        }, 500);
        this.getRounds();
      }
    }
  };

  onChange = (page) => {
    console.log(page);
    this.setState(
      {
        current: page
      },
      async () => {
        let result;
        if (this.props.user.user_type == "1") {
          result = await axiosApiInstance.get(
            APILINK +
              `/create_subject_round?course_round_id=${this.props.match.params.id}&tutor=true&page=${page}`
          );
        } else {
          result = await axiosApiInstance.get(
            APILINK +
              `/create_subject_round?course_round_id=${this.props.match.params.id}&page=${page}`
          );
        }
        console.log(result);
        if (result.data.data) {
          this.setState({ data: result.data.data, count: result.data.count });
        }
      }
    );
  };

  questionType = () => {
    this.props.isSubject(true);
    this.props.history.push("/create_questions");
  };
  getAllTutors = async () => {
    let result = await axiosApiInstance.get(
      APILINK + "/tutor_signup?user_type=1"
    );
    this.setState(
      { tutors: result.data.data, tutornextUrl: result.data.next },
      () => {
        console.log(this.state.tutors);
      }
    );
  };

  deleteRound = async () => {
    let result = await axiosApiInstance.delete(
      APILINK + `/update_subject_round/${this.state.roundID}`
    );
    console.log(result);
    if (result.data == "") {
      this.setState({ showDelete: false });
      this.getRounds();
    }
  };

  sortData = async () => {
    let result = await axiosApiInstance.get(
      APILINK +
        `/create_subject_round?course_round_id=${this.props.match.params.id}&order=-create_date`
    );
    console.log(result);
    this.setState({
      data: result.data.data,
      count: result.data.count,
      loading: false
    });
  };
  render() {
    return (
      <div>
        {this.state.loading ? (
          <Spin className="spinElement" tip="..loading" />
        ) : (
          <Container className="viewSuper">
            <Row>
              <Col className="p-4 mb-4" style={{ textAlign: "left" }} sm={6}>
                <h2>Subject Rounds</h2>
              </Col>
            </Row>
            <Row>
              {this.props.user.user_type === "3" ? (
                <Col style={{ textAlign: "left" }} className="p-4 mb-4" sm={12}>
                  {" "}
                  <Button onClick={this.sortData} className="courseBtn1 ms-1">
                    <i class="fas fa-sort"></i> Desending
                  </Button>
                  <Button onClick={this.getRounds} className="courseBtn1 mx-1">
                    <i class="fas fa-sort"></i> Ascending
                  </Button>
                </Col>
              ) : null}
            </Row>
            <Row>
              <Col style={{ textAlign: "center" }} sm={12}>
                <DataTable
                  columns={this.state.columns}
                  data={this.state.data}
                />
              </Col>
            </Row>
            <Row className="p-4">
              {this.state.data.length !== 0 ? (
                <Pagination
                  onChange={this.onChange}
                  defaultCurrent={this.state.current}
                  total={this.state.count}
                  current={this.state.current}
                />
              ) : null}
            </Row>
          </Container>
        )}

        <Modal show={this.state.showEditModel} onHide={this.hideEdit}>
          <Container fluid className="py-4">
            <Form id="infoForm">
              <Row>
                <Col className="text-center pt-2" sm={12}>
                  {" "}
                  <h6>Edit Subject Round</h6>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col sm={6}>
                  <Form.Label>Subject start date</Form.Label>

                  <DatePicker
                    value={moment(this.state.startDate, "YYYY/MM/DD")}
                    onChange={this.handleStartDate}
                  />
                </Col>
                <Col sm={6}>
                  <Form.Label>Subject end date</Form.Label>

                  <DatePicker
                    value={moment(this.state.endDate, "YYYY/MM/DD")}
                    onChange={this.handleEndDate}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <Form.Label>Select Tutor </Form.Label>
                  <Select
                    allowClear
                    placeholder="Select Tutor"
                    value={this.state.selectedTutor}
                    onChange={this.handleTutor}
                    onClick={this.getAllTutors}
                    showSearch
                    className="selectTutor"
                    onPopupScroll={this.onScroll}
                  >
                    {this.state.tutors.map((item) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.full_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Col>
                <Col>
                  <Form.Label>Start Time</Form.Label>
                  <TimePicker
                    onChange={this.handleTime}
                    value={moment(this.state.startTime, "HH:mm:ss")}
                    // value={this.state.startTime}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12} className="mt-4 text-center">
                  {" "}
                  <Button
                    className=" forumBtn1"
                    onClick={this.editSubjectRound}
                  >
                    Edit
                  </Button>{" "}
                </Col>
              </Row>
            </Form>
          </Container>
        </Modal>
        <Modal show={this.state.showDelete} onHide={this.close}>
          <Container className="p-4">
            <Row>
              <Col className="text-center" sm={12}>
                {" "}
                <h6>Are u sure u want to delete this Round?</h6>
              </Col>
              <Row style={{ margin: "auto" }}>
                <Col md={12}>
                  <Button onClick={this.deleteRound} className="forumBtn1 mx-3">
                    Delete
                  </Button>
                  <Button className="forumBtn1" onClick={this.close}>
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Row>
          </Container>
        </Modal>

        <ToastContainer position="bottom-right" />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  isAuth: state.auth.isAuth
});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ isSubject }, dispatch);
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ErrorHandler(SubjectRounds, axiosApiInstance));
