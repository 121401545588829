import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./App.css";
import Home from "./components/Home";
import Login from "./components/Login";
import Register from "./components/Register";
import SideMenu from "./components/layout/sidemenu";
import AuthHeader from "./components/layout/AuthHeader";
// import HomeFooter from "./components/layout/HomeFooter";
// import HomeHeader from "./components/layout/HomeHeader";
import { connect } from "react-redux";
import Courses from "./components/admin/courses";
import CreateCourse from "./components/admin/CreateCourse";
import AddRound from "./components/admin/addRound";
import CreateSubject from "./components/admin/CreateSubject";
import ViewAllRounds from "./components/admin/ViewAllRounds";
import CreateTutor from "./components/admin/createTutor";
import ViewAllSubjects from "./components/admin/ViewAllSubjects";
import SubjectRounds from "./components/admin/subjectRounds";
import EditCourse from "./components/admin/EditCourse";
import ViewStudents from "./components/admin/ViewStudents";
import CreateStudent from "./components/admin/createStudent";
import CourseDetails from "./components/student/courseDetails";
import StudentCourses from "./components/student/studentCourses";
import CreateMaterials from "./components/admin/CreateMaterials";
import ViewMaterial from "./components/admin/ViewMaterial";
import ForgetPassword from "./components/forgetPassword";
import PasswordReset from "./components/passwordReset";
import Calender from "./components/student/Calender";
import CourseCalender from "./components/student/CourseCalender";
import ViewTrainingQues from "./components/student/ViewTrainingQues";
import CreateQuiz from "./components/admin/addQuizzes";
import CreateTraining from "./components/admin/addTraining";
import CreateQuestions from "./components/admin/createQuestions";
import ViewTraining from "./components/admin/viewTraining";
import ViewAllTrainings from "./components/student/ViewAllTrainings";
import ViewQuizzes from "./components/admin/viewQuizzes";
import ViewAllQuizes from "./components/student/ViewAllQuizes";
import ViewQuizQues from "./components/student/ViewQuizQues";
import EditQuiz from "./components/admin/EditQuiz";
import AllTutors from "./components/admin/AllTutors";
import EditTutor from "./components/admin/EditTutor";
import ResetPassword from "./components/admin/AdminResetPassword";
import CreateForum from "./components/admin/CreateForum";
import AllFourms from "./components/admin/AllFourms";
import ViewForumQues from "./components/admin/ViewForumQues";
import ViewFourmQuesAnswers from "./components/admin/ViewFourmQuesAnswers";
import RoundForum from "./components/admin/RoundForum";
import StudentPayment from "./components/admin/studentPayment";
import Payment from "./components/student/payment";
import Chat from "./components/chat";
import EditStudent from "./components/admin/editStudent";
import { getToken, onMessageListener } from "./firebase";
import { ToastContainer, toast } from "react-toastify";
import TestLogin from "./components/TestLogin";
import UnverifiedPayments from "./components/admin/unverifiedPayments";
import SpecialityForum from "./components/admin/SpecialityForum";
import CreateSpecialityForum from "./components/admin/CreateSpecialityForum";
import QuizGrades from "./components/admin/QuizGrades";
import AssignedCourses from "./components/Tutor/AssignedCourses";
import CreateMeeting from "./components/admin/CreateMeeting";
import ViewLectures from "./components/admin/ViewLectures";
import EditMeeting from "./components/admin/EditMeeting";
import Terms from "./components/Terms";
import AboutUs from "./components/AboutUs";
import policies from "./components/Policies";
import ViewQuizQuesAdmin from "./components/admin/ViewQuizQuesAdmin";
import axios from "axios";
import ErrorHandler from "./error-handler/ErrorHandler";

class App extends Component {
  componentDidMount() {
    // var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
    if (getToken()) {
      onMessageListener().then((payload) => {
        setTimeout(() => {
          toast.info(payload.notification.title);
        }, 500);
      });
    }
  }

  // //gets the type of browser
  // detectBrowser = () => {
  //   if (
  //     (navigator.userAgent.indexOf("Opera") ||
  //       navigator.userAgent.indexOf("OPR")) != -1
  //   ) {
  //     console.log("opera");
  //   } else if (navigator.userAgent.indexOf("Chrome") != -1) {
  //     console.log("chrome");
  //   } else if (navigator.userAgent.indexOf("Safari") != -1) {
  //     console.log("safari");
  //   } else if (navigator.userAgent.indexOf("Firefox") != -1) {
  //     console.log("fire");
  //   } else if (
  //     navigator.userAgent.indexOf("MSIE") != -1 ||
  //     !!document.documentMode == true
  //   ) {
  //     console.log("opera");
  //   } else {
  //     return "Unknown";
  //   }
  // };
  render() {
    return (
      <Router>
        <div className="App">
          {this.props.isAuth && this.props.showNav === false ? (
            <div className="background">
              <div className="content-card">
                <SideMenu />
                <AuthHeader />
                {this.props.user.user_type == "3" ? (
                  <React.Fragment>
                    <Route exact path="/" component={Courses} />
                    <Route
                      exact
                      path="/courses/:id"
                      component={StudentCourses}
                    />
                    <Route
                      exact
                      path="/create_meeting/:id"
                      component={CreateMeeting}
                    />
                    <Route
                      exact
                      path="/view_lectures/:id"
                      component={ViewLectures}
                    />
                    <Route
                      exact
                      path="/verify_payments"
                      component={UnverifiedPayments}
                    />
                    <Route
                      exact
                      path="/create_quiz/:id"
                      component={CreateQuiz}
                    />
                    <Route
                      exact
                      path="/create_questions/:id"
                      component={CreateQuestions}
                    />
                    <Route
                      exact
                      path="/create_training/:id"
                      component={CreateTraining}
                    />
                    <Route exact path="/chat" component={Chat} />
                    <Route
                      exact
                      path="/student_payment/:id"
                      component={StudentPayment}
                    />
                    <Route
                      exact
                      path="/view_training/:id"
                      component={ViewTraining}
                    />
                    <Route
                      exact
                      path="/view_quiz/:id"
                      component={ViewQuizzes}
                    />
                    <Route
                      exact
                      path="/course-details/:id/:student"
                      component={CourseDetails}
                    />
                    <Route
                      exact
                      path="/edit_lecture/:id/:round"
                      component={EditMeeting}
                    />

                    <Route exact path="/edit_quiz/:id" component={EditQuiz} />
                    <Route exact path="/view_tutors" component={AllTutors} />
                    <Route exact path="/edit_tutor/:id" component={EditTutor} />
                    <Route
                      exact
                      path="/edit_student/:id"
                      component={EditStudent}
                    />
                    <Route
                      exact
                      path="/admin_password_reset"
                      component={ResetPassword}
                    />
                    <Route
                      exact
                      path="/create_forum/:id"
                      component={CreateForum}
                    />
                    <Route exact path="/view_fourms" component={AllFourms} />
                    <Route
                      exact
                      path="/quiz_questions/:id"
                      component={ViewQuizQuesAdmin}
                    />
                    <Route
                      exact
                      path="/create_speciality_forum"
                      component={CreateSpecialityForum}
                    />
                    <Route
                      exact
                      path="/quiz_grades/:id"
                      component={QuizGrades}
                    />
                    <Route
                      exact
                      path="/view_all_rounds/:id"
                      component={ViewAllRounds}
                    />
                  </React.Fragment>
                ) : this.props.user.user_type == "2" ? (
                  <React.Fragment>
                    <Route exact path="/courses" component={StudentCourses} />
                    <Route exact path="/chat" component={Chat} />
                    <Route
                      exact
                      path="/view_all_training/:id"
                      component={ViewAllTrainings}
                    />
                    <Route
                      exact
                      path="/view_all_quizes/:id"
                      component={ViewAllQuizes}
                    />
                    <Route exact path="/payments" component={Payment} />
                    <Route
                      exact
                      path="/quiz_questions/:id"
                      component={ViewQuizQues}
                    />
                    <Route exact path="/calender" component={Calender} />
                    <Route
                      exact
                      path="/course_calender"
                      component={CourseCalender}
                    />

                    <Route
                      exact
                      path="/course-details/:id"
                      component={CourseDetails}
                    />
                    <Route exact path="/" component={Home} />
                    <Route
                      exact
                      path="/view_all_rounds/:id"
                      component={ViewAllRounds}
                    />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {/* <Route exact path="/" component={Courses} /> */}
                    <Route
                      exact
                      path="/create_questions/:id"
                      component={CreateQuestions}
                    />
                    <Route
                      exact
                      path="/edit_lecture/:id/:round"
                      component={EditMeeting}
                    />
                    <Route exact path="/edit_quiz/:id" component={EditQuiz} />

                    <Route
                      exact
                      path="/create_meeting/:id"
                      component={CreateMeeting}
                    />
                    <Route
                      exact
                      path="/view_lectures/:id"
                      component={ViewLectures}
                    />
                    <Route
                      exact
                      path="/create_quiz/:id"
                      component={CreateQuiz}
                    />
                    <Route exact path="/calender" component={Calender} />
                    <Route
                      exact
                      path="/view_quiz/:id"
                      component={ViewQuizzes}
                    />
                    <Route
                      exact
                      path="/create_forum/:id"
                      component={CreateForum}
                    />
                    <Route
                      exact
                      path="/quiz_questions/:id"
                      component={ViewQuizQuesAdmin}
                    />
                    <Route exact path="/chat" component={Chat} />
                    <Route
                      exact
                      path="/quiz_grades/:id"
                      component={QuizGrades}
                    />
                    <Route exact path="/" component={AssignedCourses} />
                  </React.Fragment>
                )}
                <Route exact path="/test" component={TestLogin} />

                <Route
                  exact
                  path="/view_round_fourm/:id"
                  component={RoundForum}
                />
                <Route
                  exact
                  path="/view_fourm_questions/:id"
                  component={ViewForumQues}
                />
                <Route
                  exact
                  path="/speciality_forums"
                  component={SpecialityForum}
                />
                <Route
                  exact
                  path="/view_forum_question_answers/:id"
                  component={ViewFourmQuesAnswers}
                />
                <Route exact path="/admin_courses" component={Courses} />
                <Route exact path="/create_tutor" component={CreateTutor} />
                <Route exact path="/create-course" component={CreateCourse} />
                <Route exact path="/add_round/:id" component={AddRound} />
                <Route
                  exact
                  path="/training_questions/:id"
                  component={ViewTrainingQues}
                />
                <Route
                  exact
                  path="/create-subject/:id"
                  component={CreateSubject}
                />

                <Route
                  exact
                  path="/add_materials/:id"
                  component={CreateMaterials}
                />
                <Route
                  exact
                  path="/view_materials/:id"
                  component={ViewMaterial}
                />
                <Route exact path="/subjects/:id" component={ViewAllSubjects} />
                <Route
                  exact
                  path="/subject-rounds/:id"
                  component={SubjectRounds}
                />
                <Route
                  exact
                  path="/password-reset/:token/:id/"
                  component={PasswordReset}
                />
                <Route exact path="/create_student" component={CreateStudent} />
                <Route exact path="/edit_course/:id" component={EditCourse} />
                {this.props.user.user_type == "1" ? (
                  <Route
                    exact
                    path="/view_students/:id"
                    component={ViewStudents}
                  />
                ) : (
                  <Route exact path="/view_students" component={ViewStudents} />
                )}
              </div>
            </div>
          ) : (
            <React.Fragment>
              <Route
                exact
                path="/password-reset/:token/:id/"
                component={PasswordReset}
              />
              <Route exact path="/forget_password" component={ForgetPassword} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/terms" component={Terms} />
              <Route exact path="/about" component={AboutUs} />
              <Route exact path="/policies" component={policies} />
              <Route exact path="/register" component={Register} />
            </React.Fragment>
          )}
          {this.props.isAuth == false || this.props.user.user_type == "2" ? (
            <Route exact path="/" component={Home} />
          ) : null}

          {this.props.isAuth ? (
            <>
              <ToastContainer position="bottom-right" />
            </>
          ) : null}
        </div>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
    isAuth: state.auth.isAuth,
    showNav: state.showNav.showNav,
    user: state.auth.user
});

export default connect(mapStateToProps, null)(ErrorHandler(App, axios.create()));
