import React, { Component } from "react";
import axios from "axios";
// import { Switch } from "antd";
import Switch from 'react-js-switch';

import DataTable from "react-data-table-component";
import {
  Container,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Modal,
  Button
} from "react-bootstrap";
// import { Link } from "react-router-dom";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { ADMIN_ENDPOINTS, APILINK } from "../../EndPoint";
import { Link } from "react-router-dom";
import { Pagination, Spin } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { passwordReset } from "../../global-state/actions/passwordAction";
import SwitchButton from "../common/switch/Switch";
const axiosApiInstance = axios.create();
class ViewStudents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: [],
      tutors: [],
      studentID: "",
      count: 1,
      current: 1,
      showSubjectModel: false,
      show: false,
      columns: [
        {
          name: "First Name",
          selector: "user.full_name",
          sortable: true,
          right: true
        },
        {
          name: "Last Name",
          selector: "user.last_name",
          sortable: true,
          right: true
        },
        {
          name: "Email",
          selector: "user.email",
          sortable: true,
          right: true
        },
        {
          name: "Country",
          selector: "user.country",
          sortable: true,
          right: true
        },
        {
          name: "phone",
          selector: "user.mobile",
          sortable: true,
          right: true
        },
        {
          name: "Birth Date",
          selector: "user.birth_date",
          sortable: true,
          right: true
        },
        {
          name: "Actions",
          button: true,
          width: "220px",
          cell: (row) => (
            <div className="table-data-feature">
              {this.props.user.user_type === "3" ? (
                <React.Fragment>
                  <OverlayTrigger
                    key={"1"}
                    placement={"top"}
                    overlay={
                      <Tooltip id={`tooltip-${"top"}`}>
                        Activate/Dectivate Student Account
                      </Tooltip>
                    }
                  >
                    <SwitchButton
                      className="activate-student"
                      id={row.user.id}
                      onClick={this.clicked}
                      defaultChecked={row.user.is_active}
                      onChange={() => this.onChange(this.state.current)}
                    />

                  </OverlayTrigger>
                  <OverlayTrigger
                    key={"2"}
                    placement={"top"}
                    overlay={
                      <Tooltip id={`tooltip-${"top"}`}>View Courses</Tooltip>
                    }
                  >
                    <Link to={`/courses/${row.user.id}`}>
                      {" "}
                      <i className="students-icons fas fa fa-eye pr-2"></i>
                    </Link>
                  </OverlayTrigger>
                  <OverlayTrigger
                    key={"3"}
                    placement={"top"}
                    overlay={
                      <Tooltip id={`tooltip-${"top"}`}>
                        Delete Student Account
                      </Tooltip>
                    }
                  >
                    <i
                      onClick={this.showWarning}
                      id={row.user.id}
                      className="students-icons fas fa fa-trash pr-2"
                    ></i>
                  </OverlayTrigger>
                  <OverlayTrigger
                    key={"4"}
                    placement={"top"}
                    overlay={
                      <Tooltip id={`tooltip-${"top"}`}>Reset Password</Tooltip>
                    }
                  >
                    <i
                      onClick={this.Reset}
                      id={row.user.id}
                      className="students-icons fas fa fa-tools pr-2"
                    ></i>
                  </OverlayTrigger>
                  <OverlayTrigger
                    key={"5"}
                    placement={"top"}
                    overlay={
                      <Tooltip id={`tooltip-${"top"}`}>
                        View Student Payments
                      </Tooltip>
                    }
                  >
                    <Link to={`/student_payment/${row.user.id}`}>
                      {" "}
                      <i className="students-icons fas fa fa-eye pr-2"></i>
                    </Link>
                  </OverlayTrigger>
                  <OverlayTrigger
                    key={"6"}
                    placement={"top"}
                    overlay={
                      <Tooltip id={`tooltip-${"top"}`}>Edit Student</Tooltip>
                    }
                  >
                    <Link to={`/edit_student/${row.user.id}`}>
                      {" "}
                      <i className="students-icons fas fa fa-edit pr-2"></i>
                    </Link>
                  </OverlayTrigger>
                </React.Fragment>
              ) : null}
            </div>
          )

        }
      ],
      studentID: "",
      keyWord: "",
      isOrder: false
    };
  }
  showWarning = (e) => {
    this.setState({ show: true, studentID: e.target.id });
  };
  close = (e) => {
    this.setState({ show: false });
  };
  deleteStudent = async (e) => {
    let result = await axiosApiInstance.delete(
      APILINK + `/delete_user/${this.state.studentID}`
    );
    this.setState({ show: false });
    this.getStudents();
    console.log(result);
  };
  clicked = (checked, e) => {
    this.setState({ studentID: e.target.id }, async () => {
      console.log(`id: ${e.target.id}`);
      console.log({ checked });
      let result = await axiosApiInstance.get(
        APILINK +
        `/activate_student/${this.state.studentID}/?is_active=${checked}`
      );
      if (result) {
        console.log("status changed");
      }
    });
  };
  // onChange = async (checked,e) => {
  //   console.log(`switch to ${checked}`);
  //   console.log(e.target.id)
  //   let result = await axiosApiInstance.get(APILINK + `/activate_student/${e.target.id}/?is_active=${checked}`)
  //   if(result){
  //     console.log("status changed")
  //   }
  // }
  getStudents = async () => {
    this.setState({ loading: true });
    let result;
    if (this.props.user.user_type === "3") {
      result = await axiosApiInstance.get(
        APILINK +
        `${ADMIN_ENDPOINTS.students}?page=${this.state.current}&ordering=user__first_name,user__last_name`
      );
      console.log(result);
      if (result) {
        this.setState({
          data: result.data.data,
          count: result.data.count,
          loading: false
        });
      }
    } else {
      result = await axiosApiInstance.get(
        APILINK +
        `${ADMIN_ENDPOINTS.students}?course_round_id=${this.props.match.params.id}`
      );
      console.log(result.data);
      if (result) {
        this.setState({
          data: result.data.data,
          count: result.data.count,
          loading: false
        });
      }
    }
  };
  componentDidMount() {
    this.getStudents();
  }

  onChange = (page) => {
    console.log({ page });
    this.setState(
      {
        current: page
      },
      async () => {
        if (this.state.isSearched) {
          await axiosApiInstance
            .get(
              APILINK +
              `${ADMIN_ENDPOINTS.students}?search=${this.state.keyWord}&page=${this.state.current}&ordering=user__first_name,user__last_name`
            )
            .then((res) => {
              console.log(res);
              this.setState({ data: res.data.data, count: res.data.count });
            });
        } else {
          // if(isOrder)
          // {

          // }
          await axiosApiInstance
            .get(
              APILINK +
              `${ADMIN_ENDPOINTS.students}?page=${this.state.current}&ordering=${this.state.isOrder
                ? "-user__first_name,user__last_name"
                : "user__first_name,user__last_name"
              }`
            )
            .then((res) => {
              console.log(res);
              this.setState({ data: res.data.data, count: res.data.count });
            });
        }
      }
    );
  };

  onSearch = async (e) => {
    this.setState({ keyWord: e.target.value, isSearched: true });
    let result = await axiosApiInstance
      .get(
        APILINK +
        `${this.props.user.user_type == "3"
          ? `${ADMIN_ENDPOINTS.students}?search=${e.target.value}&page=1&ordering=user__first_name,user__last_name`
          : `${ADMIN_ENDPOINTS.students}?course_round_id=${this.props.match.params.id}&search=${e.target.value}`
        }`
      )
      .catch((err) => {
        console.log(err.response);
      });
    console.log(result);
    if (result) {
      if (result.data == "name wasn't sent") {
        this.getStudents();
        this.setState({ isSearched: false });
      } else {
        this.setState({
          data: result.data.data,
          count: result.data.count,
          loading: false
        });
      }
    }
  };

  Reset = async (e) => {
    let result = await axiosApiInstance.get(
      APILINK + `/admin-reset-password?user_id=${e.target.id}`
    );
    console.log(result);
    this.props.passwordReset(result.data.url);
    setTimeout(() => {
      toast.info("You will be redirected shortly");
    }, 3000);
    setTimeout(() => {
      this.props.history.push("/admin_password_reset");
    }, 3000);
  };

  sortData = async () => {
    this.setState({ isOrder: true });
    let result = await axiosApiInstance.get(
      APILINK +
      `${this.props.user.user_type == "3"
        ? `${ADMIN_ENDPOINTS.students}?page=${this.state.current
        }&ordering=-user__first_name,user__last_name&${this.state.isSearched ? `search=${this.state.keyWord}` : ""
        }`
        : `${ADMIN_ENDPOINTS.students}?page=${this.state.current
        }&ordering=-user__first_name,user__last_name&course_round_id=${this.props.match.params.id
        }&${this.state.isSearched ? `search=${this.state.keyWord}` : ""}`
      }`
    );
    console.log(result);
    if (result) {
      this.setState({
        data: result.data.data,
        count: result.data.count,
        loading: false
      });
    }
  };

  render() {
    console.log(this.state.data);
    return (
      <div>
        {this.state.loading ? (
          <Spin className="spinElement" tip="..loading" />
        ) : (
          <Container className="viewSuper">
            <Row>
              <Col className="p-4 mb-4" style={{ textAlign: "left" }} sm={6}>
                <h2> All Students</h2>
              </Col>
            </Row>
            <Row>
              <Col style={{ textAlign: "center" }} sm={12}>
                <DataTable
                  columns={this.state.columns}
                  data={this.state.data}
                  subHeader
                  subHeaderComponent={
                    <React.Fragment>
                      <Col
                        style={{ textAlign: "initial", color: "#7c8798" }}
                        sm={12}
                        md={6}
                      >
                        order data:{" "}
                        <Button
                          onClick={this.sortData}
                          className="courseBtn1 ms-1"
                        >
                          <i class="fas fa-sort"></i> Desending
                        </Button>
                        <Button
                          onClick={this.getStudents}
                          className="courseBtn1 mx-1"
                        >
                          <i class="fas fa-sort"></i> Ascending
                        </Button>
                      </Col>
                      <Col style={{ textAlign: "right" }} sm={12} md={6}>
                        <label
                          style={{ color: "#7c8798", marginRight: "10px" }}
                        >
                          Search:{" "}
                        </label>
                        <input
                          id="search"
                          type="text"
                          placeholder=""
                          aria-label="Search Input"
                          className="seachInput"
                          value={this.state.keyWord}
                          onChange={this.onSearch}
                        />
                      </Col>
                    </React.Fragment>
                  }
                />
              </Col>
            </Row>
            <Row className="p-4">
              {this.state.data.length !== 0 ? (
                <Pagination
                  onChange={this.onChange}
                  defaultCurrent={this.state.current}
                  total={this.state.count}
                  current={this.state.current}
                // pageSize={20}
                />
              ) : null}
            </Row>
          </Container>
        )}
        <ToastContainer position="bottom-right" />
        <Modal show={this.state.show} onHide={this.close}>
          <Container className="p-4">
            <Row>
              <Col className="text-center" sm={12}>
                {" "}
                <h6>Are u sure u want to delete this Student?</h6>
              </Col>
              <Row style={{ margin: "auto" }}>
                <Col md={12}>
                  <Button
                    onClick={this.deleteStudent}
                    className="forumBtn1 mx-3"
                  >
                    Delete
                  </Button>
                  <Button className="forumBtn1" onClick={this.close}>
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Row>
          </Container>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  isAuth: state.auth.isAuth
});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ passwordReset }, dispatch);
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ErrorHandler(ViewStudents, axiosApiInstance));
