import React, { Component } from "react";
import { Row, Col, Container, Button, Modal, Form } from "react-bootstrap";
import "../../style-sheets/training.css";
import { message, Radio } from "antd";
import ErrorHandler from "../../error-handler/ErrorHandler";
import axios from "axios";
import { APILINK } from "../../EndPoint";
import { Empty, Spin, Input, Pagination } from "antd";
import { ToastContainer, toast } from "react-toastify";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import generateQuestionsExcel from "../../utils/questions-excel-genrator";
const axiosApiInstance = axios.create();
class ViewQuizQuesAdmin extends Component {
  state = {
    loading: false,
    questions: [],
    count: 0,
    checkAnswer: "",
    quizes: [{ question: "", answer: "" }],
    questionCount: 0,
    disable: true,
    results: "",
    QuizResults: "",
    question: "",
    choice: "",
    showAddModal: false,
    disable: true,
    current: 1,
    question: "",
    showEdit: false,
    showEditChoice: false,
    choiceId: "",
    showDelete: false,
    showEditImg: false,
    selectedFile: null,
    dateTaken: "",
    duration: "",
    hours: "",
    mins: "",
    sec: "",
    showEditOrder: false,
    order: "",
    showExp: false,
  };
  getQuestions = async () => {
    this.setState({ loading: true });
    let result = await axiosApiInstance
      .get(
        APILINK +
          `/quiz_questions/${this.props.match.params.id}?page=${this.state.current}`
      )
      .then((res) => {
        this.setState({
          questions: res.data.data,
          loading: false,
          questionCount: res.data.count,
          dateTaken: res.data.date_taken,
          duration: res.data.duration,
        });
        this.getRemainingTime();
      })
      .catch((err) => {
        console.log(err.response);
      });
  };
  componentDidMount() {
    this.getQuestions();
  }

  editQues = async (e) => {
    let data = {};
    data.question = this.state.question;
    let result = await axiosApiInstance.put(
      APILINK + `/edit_question/${this.state.quesID}`,
      data
    );
    if (result) {
      this.setState({ question: "", showEdit: false });
      this.getQuestions();
    }
  };
  editChoice = async (e) => {
    let data = {};
    data.choice = this.state.choice;
    let result = await axiosApiInstance.put(
      APILINK + `/edit_question_choice/${this.state.choiceId}`,
      data
    );
    if (result) {
      this.setState({ choice: "", showEditChoice: false });
      this.getQuestions();
    }
  };

  deleteChoice = async (e) => {
    let result = await axiosApiInstance.delete(
      APILINK + `/edit_question_choice/${e.target.id}`
    );
    console.log(result);
    if (result.data == "") {
      this.setState({ show: false });
      this.getQuestions();
    }
  };
  deleteQuestion = async (e) => {
    console.log("hereeeee");
    let result = await axiosApiInstance.delete(
      APILINK + `/edit_question/${this.state.quesID}`
    );
    this.setState({ showDelete: false });
    this.getQuestions();
  };
  viewAdd = (e) => {
    this.setState({ showAddModal: true, quesID: e.target.id });
  };
  hideAdd = () => {
    this.setState({ showAddModal: false });
  };
  handleChange = (e) => {
    console.log(e);
    this.setState({ [e.target.name]: e.target.value });
  };
  addChoice = async () => {
    let data = {
      question_id: this.state.quesID,
      choice: this.state.choice,
    };
    console.log(data);
    let result = await axiosApiInstance
      .post(APILINK + `/create_question_choice`, data)
      .catch((err) => {
        console.log(err.response);
      });
    console.log(result);
    if (result) {
      if (result.data) {
        this.setState({ showAddModal: false, choice: "" });
        this.getQuestions();
      }
    }
  };
  onChange = (page) => {
    console.log(page);
    this.setState(
      {
        current: page,
      },
      async () => {
        const result = await axiosApiInstance
          .get(
            APILINK +
              `/quiz_questions/${this.props.match.params.id}?page=${this.state.current}`
          )
          .then((res) => {
            console.log(res);
            this.setState({
              questions: res.data.data,
              loading: false,
              questionCount: res.data.count,
              dateTaken: res.data.date_taken,
              duration: res.data.duration,
            });
          });
      }
    );
  };
  hideGrade = (e) => {
    this.setState({ showModal: false });
  };

  showEditModel = (e, ques) => {
    console.log(e, ques);
    this.setState(
      { showEdit: true, quesID: e.target.id, selectedQuestion: ques },
      () => {
        console.log(this.state.quesID, this.state.selectedQuestion);
      }
    );
  };

  hideEdit = () => {
    this.setState({ showEdit: false });
  };
  showEditChoiceModel = (e) => {
    this.setState({ showEditChoice: true, choiceId: e.target.id });
  };
  hideEditChoice = () => {
    this.setState({ showEditChoice: false });
  };
  showWarning = (e) => {
    console.log(e.target.id);
    this.setState({ showDelete: true, quesID: e.target.id });
  };
  hideWarning = (e) => {
    this.setState({ showDelete: false });
  };

  showEditImgModel = (e) => {
    this.setState({ showEditImg: true, quesID: e.target.id });
  };

  onFileUpload = (e) => {
    e.preventDefault();

    this.setState({
      selectedFile: e.target.files[0],
      fileName: e.target.files[0].name,
    });
  };

  EditImage = async () => {
    let eventdata = new FormData();

    eventdata.append("image", this.state.selectedFile);

    let result = await axiosApiInstance.put(
      APILINK + `/edit_question/${this.state.quesID}`,
      eventdata
    );
    if (result) {
      this.setState({ question: "", showEditImg: false });
      this.getQuestions();
    }
  };

  showOrderModal = (e, order) => {
    this.setState({ showEditOrder: true, quesID: e.target.id, order: order });
  };
  editOrder = async () => {
    let data = {
      display_order: this.state.order,
    };
    let result = await axiosApiInstance.put(
      APILINK + `/edit_question/${this.state.quesID}`,
      data
    );
    if (result) {
      this.setState({ showEditOrder: false });
      this.getQuestions();
    }
  };
  hideImg = () => {
    this.setState({ showEditImg: false });
  };
  hideOrderModal = () => {
    this.setState({ showEditOrder: false });
  };
  showExplainAdmin = (e, exp) => {
    this.setState({ showExp: true, quesID: e.target.id, expTxt: exp });
  };
  hideExpAdmin = () => {
    this.setState({ showExp: false, expTxt: "" });
  };
  editExp = async () => {
    let data = {
      explanation: this.state.expTxt,
    };
    let result = await axiosApiInstance.put(
      APILINK + `/edit_question/${this.state.quesID}`,
      data
    );
    if (result) {
      this.setState({ showExp: false });
      this.getQuestions();
    }
  };
  render() {
    return (
      <div>
        <Container className="content-margin">
          <Row>
            <Col sm="6">
              <h4 style={{ color: "#124E68" }}>
                <i
                  onClick={() => this.props.history.goBack()}
                  className="fas fa-chevron-left pr-4"
                ></i>
                Quiz Questions
              </h4>
            </Col>
            <Col sm="6">
              <div
                style={{ display: "flex", flexDirection: "row", gap: "8px" }}
              >
                <Button className="forumBtn1">
                  <Link to={`/create_questions/${this.props.match.params.id}`}>
                    Add Another Question
                  </Link>
                </Button>
                <Button
                  className="forumBtn1"
                  onClick={async () =>
                    await generateQuestionsExcel(this.state.questions)
                  }
                >
                  Download As Excel File
                </Button>
              </div>
            </Col>
          </Row>

          {this.state.questions.length !== 0 ? (
            <React.Fragment>
              {" "}
              {this.state.questions.map((ques, index) => {
                // return this.state.count == index ? (
                return (
                  <div
                    className="questionDiv mb-4 p-3"
                    style={{ width: "90%" }}
                  >
                    <Row>
                      <Col md="8" xs="12">
                        <p>{ques.question}</p>
                        {ques.image == "" || ques.image == null ? (
                          <span className="p-4">
                            <i
                              id={ques.id}
                              onClick={this.showEditImgModel}
                              className="fas fa-edit px-1 editIcon"
                            />
                            Add image
                          </span>
                        ) : (
                          <>
                            <img
                              src={APILINK + "/media/" + ques.image}
                              className="img-fluid"
                            />
                            <i
                              id={ques.id}
                              onClick={this.showEditImgModel}
                              className="fas fa-edit px-1 editIcon"
                            />
                            edit this image
                          </>
                        )}
                      </Col>
                      <Col md="4" xs="12" className="p-4 mb-2">
                        <i
                          id={ques.id}
                          onClick={(e, q) =>
                            this.showEditModel(e, ques.question)
                          }
                          className="fas fa-edit px-1 editIcon"
                        />
                        <i
                          onClick={this.showWarning}
                          id={ques.id}
                          className="fas fa-trash  editIcon"
                        ></i>
                      </Col>
                    </Row>

                    <Row>
                      {ques.choices_.map((choice) => {
                        return (
                          <Col className="my-3" sm="12">
                            <p className="choiceP">{choice.choice}</p>
                            <span style={{ color: "green" }}>
                              {choice.is_answer ? "(Correct answer)" : null}
                            </span>
                            <i
                              id={choice.id}
                              onClick={this.showEditChoiceModel}
                              className="fas fa-edit px-1 editIcon"
                            />
                            <i
                              onClick={this.deleteChoice}
                              id={choice.id}
                              className="fas fa-trash editIcon"
                            ></i>
                          </Col>
                        );
                      })}
                    </Row>
                    <Row>
                      <Col md="12" xs="12" className="p-3 text-right">
                        <Button
                          onClick={this.viewAdd}
                          id={ques.id}
                          className="forumBtn1"
                        >
                          Add Choice
                        </Button>
                        <Button
                          onClick={(e, q) =>
                            this.showOrderModal(e, ques.display_order)
                          }
                          id={ques.id}
                          className="forumBtn1 mx-3"
                        >
                          Change Question Order
                        </Button>
                        <Button
                          onClick={(e, q) =>
                            this.showExplainAdmin(e, ques.explanation)
                          }
                          id={ques.id}
                          className="forumBtn1 mx-1"
                        >
                          View/Edit Explaination
                        </Button>
                      </Col>
                    </Row>
                  </div>
                );
                // ) : null;
              })}
            </React.Fragment>
          ) : null}
          {this.state.loading ? (
            <Col className="mt-5 text-center" sm="12">
              {" "}
              <Spin tip="...loading" />
            </Col>
          ) : this.state.questions.length === 0 &&
            this.state.loading == false ? (
            <Empty />
          ) : null}
          <Row className="p-4">
            {this.state.questions.length !== 0 ? (
              <Pagination
                onChange={this.onChange}
                defaultCurrent={this.state.current}
                total={this.state.questionCount}
                current={this.state.current}
              />
            ) : null}
          </Row>
          <Modal
            className="text-center"
            show={this.state.showAddModal}
            onHide={this.hideAdd}
          >
            <Container className="py-4">
              <Row>
                <Col sm="8">
                  <Input
                    className="questInput"
                    defaultValue=""
                    onChange={(e) => {
                      this.setState(
                        { choice: e.target.value, disable: false },
                        () => {
                          console.log(this.state.choice);
                        }
                      );
                    }}
                    placeholder="Add choice"
                  />
                </Col>
                <Col sm="4">
                  <Button
                    disabled={this.state.disable}
                    className="forumBtn1"
                    onClick={this.addChoice}
                  >
                    Add
                  </Button>
                </Col>
              </Row>
            </Container>
          </Modal>
        </Container>

        <Modal
          className="text-center"
          show={this.state.showEdit}
          onHide={this.hideEdit}
        >
          <Container className="py-4">
            <Row>
              <Col sm="12">
                <Input.TextArea
                  rows={4}
                  className="questInput"
                  onChange={(e) => {
                    this.setState({ question: e.target.value, disable: false });
                  }}
                  placeholder="Edit Question hereee"
                  defaultValue={this.state.selectedQuestion}
                />
              </Col>
            </Row>
            <Row>
              <Col sm="12" className="text-center mt-2">
                <Button
                  disabled={this.state.disable}
                  className="forumBtn1"
                  onClick={this.editQues}
                >
                  save changes
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal>

        <Modal
          className="text-center"
          show={this.state.showEditChoice}
          onHide={this.hideEditChoice}
        >
          <Container className="py-4">
            <Row>
              <Col sm="12">
                <Input.TextArea
                  rows={4}
                  className="questInput"
                  onChange={(e) => {
                    this.setState({ choice: e.target.value, disable: false });
                  }}
                  placeholder="Edit Choice hereee"
                />
              </Col>
            </Row>
            <Row>
              <Col sm="12" className="text-center mt-2">
                <Button
                  disabled={this.state.disable}
                  className="forumBtn1"
                  onClick={this.editChoice}
                >
                  save changes
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal>

        <Modal
          className="text-center"
          show={this.state.showDelete}
          onHide={this.hideWarning}
        >
          <Container className="py-4">
            <Row>
              <Col sm="12">
                <h5>Are you sure you want to delete this question?</h5>
              </Col>
            </Row>
            <Row>
              <Col sm="12" className="text-center mt-2">
                <Button
                  className="forumBtn1 mx-2"
                  onClick={this.deleteQuestion}
                >
                  Delete
                </Button>
                <Button className="forumBtn1" onClick={this.hideWarning}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal>

        <Modal
          className="text-center"
          show={this.state.showEditImg}
          onHide={this.hideImg}
        >
          <Container className="py-4">
            <Row>
              <Col sm="12">
                <h5>Edit question image</h5>
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <div class="wrapper">
                  <div class="file-upload2">
                    <input onChange={this.onFileUpload} type="file" />
                    <i class="fa fa-plus"></i>
                  </div>
                </div>{" "}
                <p>{this.state.fileName}</p>
              </Col>
            </Row>
            <Row>
              <Col sm="12" className="text-center mt-2">
                <Button className="forumBtn1 mx-2" onClick={this.EditImage}>
                  Save Changes
                </Button>
                <Button className="forumBtn1" onClick={() => this.hideImg()}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal>

        <Modal
          className="text-center"
          show={this.state.showEditOrder}
          onHide={this.hideOrderModal}
        >
          <Container className="py-4">
            <Row>
              <Col sm="12">
                <Input
                  className="questInput"
                  onChange={(e) => {
                    this.setState({ order: e.target.value });
                  }}
                  placeholder="Edit Order"
                  defaultValue={this.state.order}
                />
              </Col>
            </Row>
            <Row>
              <Col sm="12" className="text-center mt-2">
                <Button className="forumBtn1" onClick={this.editOrder}>
                  Save Changes
                </Button>
                <Button
                  className="forumBtn1 mx-2"
                  onClick={this.hideOrderModal}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal>
        <Modal show={this.state.showExp} onHide={this.hideExpAdmin}>
          <Container fluid className="p-4">
            <Row>
              <Col className="text-center" sm={12}></Col>

              <p></p>
            </Row>
            <Row>
              <Col className="text-center" sm="12">
                <Input.TextArea
                  rows={6}
                  className="questInput"
                  onChange={(e) => {
                    this.setState({ expTxt: e.target.value });
                  }}
                  placeholder="Add/Edit the explaination here.."
                  defaultValue={this.state.expTxt}
                />
              </Col>
            </Row>
            <Row>
              <Col sm="12" className="text-center mt-2">
                <Button className="forumBtn1" onClick={this.editExp}>
                  Save Changes
                </Button>
                <Button className="forumBtn1 mx-2" onClick={this.hideExpAdmin}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal>
        <ToastContainer position="bottom-right" />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  isAuth: state.auth.isAuth,
});
export default connect(
  mapStateToProps,
  null
)(ErrorHandler(ViewQuizQuesAdmin, axiosApiInstance));
