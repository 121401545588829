import React from "react";
import { Container, Row, Col } from "react-bootstrap";
export default function Terms(props) {
  return (
    <div className="loginPage py-5" style={{ height: "auto" }}>
      <Container className="loginContainer p-5">
        <Row>
          <Col className="text-left" sm="12">
            <h3 style={{ color: "#124E68" }}>
              <i
                onClick={() => props.history.goBack()}
                className="fas fa-chevron-left pr-4"
              ></i>
              Terms & Conditions
            </h3>
          </Col>
        </Row>
        <Row className='paddingLeft'>
          <Col className="text-left" sm="12">
            <h5>Payment and cancellation rules</h5>
          </Col>
          <Col className="text-left"  md="8" sm="12">
            <p>
              {" "}
              1- In the case of choosing to pay in two instalments, the date of
              your next payment will be made clear to you before the
              subscription begins.
            </p>
            <p>
              {" "}
              2- The date of your next payment is final and non-negotiable after
              the start of subscription at any time period.
            </p>
            <p>
              {" "}
              3- The subscriber will be notified of the approaching date of
              payment of any due payment before the due date sufficiently long
              enough before the due date, the warning will be for one time only.
            </p>{" "}
            <p>
              4- In the event of non-compliance with the payment on the
              specified date, the subscription will be put onhold for one week,
              after the end of this week and in case the delayed payment is not
              yet paid the subscription will be canceled. and the subscriber is
              not entitled to claim a refund of any previous payments.
            </p>{" "}
            <p>
              {" "}
              5- The participant in the program has the right to cancel the
              subscription at any time and without giving reasons, provided that
              the cost of the materials already acquired by the participant is
              deducted. In the event that you wish to re-subscribe to the
              program after cancellation at any time, the subscriber must pay
              the cost of the program again in full without deduction. .
            </p>
          </Col>
        </Row>
        <Row className='paddingLeft'>
          <Col className="text-left" sm="12">
            <h5>Rules of storing scientific materials</h5>
          </Col>
          <Col className="text-left"  md="8" sm="12">
            <p>
              {" "}
              1-Subscriber will have access to the entire materials during the
              whole time of the course. once subscription period ends Subscriber
              will no longer have access to the materials.
            </p>
            <p>
              {" "}
              2- Study materials can be downloaded and stored on your own
              device, while the records for the lectures are not downloadable.
            </p>
            <p>
              {" "}
              3- Preserving the scientific material is your personal
              responsibility. The subscriber must take all necessary measures to
              preserve the scientific material sent to him, as AVEC does not
              resend the scientific material again after the end of your
              subscription.
            </p>{" "}
          </Col>
        </Row>
        <Row className='paddingLeft'>
          <Col className="text-left" sm="12">
            <h5>Payment and cancellation rules</h5>
          </Col>
          <Col className="text-left"  md="8" sm="12">
            <p>
              {" "}
              1- In the case of choosing to pay in two instalments, the date of
              your next payment will be made clear to you before the
              subscription begins.
            </p>
            <p>
              {" "}
              2- The date of your next payment is final and non-negotiable after
              the start of subscription at any time period.
            </p>
            <p>
              {" "}
              3- The subscriber will be notified of the approaching date of
              payment of any due payment before the due date sufficiently long
              enough before the due date, the warning will be for one time only.
            </p>{" "}
            <p>
              4- In the event of non-compliance with the payment on the
              specified date, the subscription will be put onhold for one week,
              after the end of this week and in case the delayed payment is not
              yet paid the subscription will be canceled. and the subscriber is
              not entitled to claim a refund of any previous payments.
            </p>{" "}
            <p>
              {" "}
              5- The participant in the program has the right to cancel the
              subscription at any time and without giving reasons, provided that
              the cost of the materials already acquired by the participant is
              deducted. In the event that you wish to re-subscribe to the
              program after cancellation at any time, the subscriber must pay
              the cost of the program again in full without deduction. .
            </p>
          </Col>
        </Row>
        <Row className='paddingLeft'>
          <Col className="text-left" sm="12">
            <h5>Appointments and attendance rules</h5>
          </Col>
          <Col className="text-left"  md="8" sm="12">
            <p>
              {" "}
              1-The lecturer is not responsible for re-explaining any part of
              the lecture in case the participant is late for the start time.
            </p>
            <p>
              {" "}
              2- Ensuring the stability of your internet connection is your
              personal responsibility. The lecturer is not responsible for
              repeating or explaining any part of the lecture in case there are
              problems with the subscriber's connection to the Internet.
            </p>
            <p>
              {" "}
              3- - The duration of the program is 9 months from the date of
              attending the first lecture. It is not allowed to request to stop
              attending the program and resume attendance at a later date. The
              participant must ensure his readiness to complete the entire
              program without stopping.
            </p>{" "}
            <p>
              It is not allowed to request to re-attend the program again after
              completion.{" "}
            </p>
          </Col>
        </Row>
        <Row className='paddingLeft'>
          <Col className="text-left" sm="12">
            <h5>Laws regarding scientific materials </h5>
          </Col>
          <Col className="text-left"  md="8" sm="12">
            <p>
              {" "}
              1-The scientific materials presented in the program are registered
              and protected by the Intellectual Property Protection Law. All
              materials sent to subscribers will be watermarked by their names.
            </p>
            <p>
              {" "}
              2- Scientific materials are not allowed to be circulated in any
              form outside the program.
            </p>
            <p>
              {" "}
              3-It is not allowed to circulate the scientific material in any
              way between the participants in the program as the materials are
              watermarked by your personal name.
            </p>{" "}
            <p>
              4- Circulation of the scientific materials exposes you to
              cancelation of your subscription immediately, and the subscriber
              will not entitled to claim any refund of any money paid and
              exposes you to legal accountability.
            </p>
          </Col>
        </Row>
        <Row className='paddingLeft'>
          <Col className="text-left" sm="12">
            <h5>Simulation Exam Rules</h5>
          </Col>
          <Col className="text-left" md="8" sm="12">
            <p>
              {" "}
              1-After completing each subject, an online exam is conducted on
              the subject for the purpose of training.
            </p>
            <p>
              {" "}
              2- You will know the exam date after the completion of each
              subject. The date is final and not subject to change.
            </p>
            <p>
              {" "}
              3-Attendance of the exam is compulsory for all participants.
            </p>{" "}
            <p>
              4- Passing the exam is from 70%. In the event of a failure, the
              participant must repeat the same exam again within a period not
              exceeding one week. Falling twice in a row in the same exam will
              cause your subscription to be put on hold.
            </p>
            <p>
              5- It is not possible to attend the exam after the agreed time has
              passed. Failure to attend the exam on the agreed time will cause
              your subscription to be put on hold.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
