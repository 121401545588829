import writeXlsxFile from 'write-excel-file'

export default async function generateQuestionsExcel(questions) {

    const formattedQuestions = questions.map((q, i) => ({ ...q, index: i + 1 }))
    console.log({ formattedQuestions })
    let maxAnswers = 0
    formattedQuestions.forEach((it, i) => {
        const choicesLength = it.choices_.length
        if (choicesLength > maxAnswers) {
            maxAnswers = choicesLength
        }
    })

    const answersSchema = []
    for (let i = 0; i < maxAnswers; i++) {
        answersSchema.push({
            column: `answer${i + 1}`,
            type: String,
            value: q => q.choices_[i]?.choice
        })
    }

    const schema = [
        {
            column: "question",
            type: String,
            value: q => q.question,
            width: 20
        },
        ...answersSchema,
        {
            column: "correct",
            type: Number,
            value: q => q.choices_.reduce((prev, curr, i) => {
                return curr.is_answer ? i : prev
            }, 0) + 1
        },
        {
            column: "weight",
            type: Number,
            value: q => +q.weight,
            width: 20
        },
        {
            column: "display_order",
            type: Number,
            value: q => q.display_order,
            width: 20
        },
        {
            column: "explanation",
            type: String,
            value: q => q.explanation,
            width: 20
        },
    ]
console.log(schema)
    await writeXlsxFile(formattedQuestions, {
        schema,
        headerStyle: {
            backgroundColor: '#eeeeee',
            fontWeight: 'bold',
        },
        fileName: 'file.xlsx'
    })

}