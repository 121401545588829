import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Carousel,
  Button,
  Card,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import "../../style-sheets/home.css";
import img1 from "../../images/logoFooter.PNG";
class HomeFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="home-footer">
        <div className='footerDiv'>
        <Container  className="py-4">
          <Row>
            <Col sm={12} md={5}>
              <img style={{width:'100%'}} src={img1} alt="" />
              {/* <h6>RECIVE EMAIL UPDATES</h6>
              <InputGroup className="mb-3">
                <FormControl
                  placeholder="Your Email Address"
                  aria-label="Your Email Address"
                  aria-describedby="basic-addon2"
                />
                <InputGroup.Append>
                  <InputGroup.Text id="basic-addon2">JOIN</InputGroup.Text>
                </InputGroup.Append>
              </InputGroup> */}
            </Col>
            
            <Col className="text-left mt-5" sm={12} md={3}>
              <ul className="footer-list">
                <li className="listTitle">HELP</li>
                <li><a href='/terms'>Terms & Conditions</a></li>
                <li><a href='/policies'>Privcey Policy</a></li>
              </ul>{" "}
            </Col>

            <Col className="text-left mt-5" sm={12} md={3}>
              <ul className="footer-list">
                <li className="listTitle">ABOUT</li>
                <li><a href='/about'>Our Story</a></li>
                <li>Contact inquires@avecian.com</li>
               
              </ul>{" "}
            </Col>
          </Row>
         
        </Container>
        </div>
        <Container>
        <Row style={{backgroundColor:'white',borderRadius:'5px'}}>
            <Col className='text-center mt-3' sm="12">
            <p className='cpy'>Copyright © 2020 . Div Systems All rights reserved  V1.0.0</p>

            </Col>
          </Row>
        </Container>
      
      </div>
    );
  }
}

export default HomeFooter;
