import firebase from "firebase/app";
import "firebase/messaging";

var firebaseConfig = {
  apiKey: "AIzaSyBHmrYP60HISgmVE5rxPiL_z8BvgGLdsr4",
  authDomain: "e-learning-316707.firebaseapp.com",
  projectId: "e-learning-316707",
  storageBucket: "e-learning-316707.appspot.com",
  messagingSenderId: "834798242328",
  appId: "1:834798242328:web:272d20c467a762d41ed16d",
  measurementId: "G-8LHQXRYRKK",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
// firebase.analytics();
let messaging = null;
if (firebase.messaging.isSupported()) {
  console.log("firebase support");
  console.log(firebase.messaging.isSupported());
  messaging = firebase.messaging();
}
export const getToken = () => {
  if (messaging == null) {
    return null;
  }
  return messaging
    .getToken({
      vapidKey:
        "BEzHKwjU1EfI-rc4LqREzdgpu39j_8dJLV_7vfSKEA5lMqobCn-IuM2H7IbpL2MgAUnVRkkiBDPyucYrIhNOzBc",
    })
    .then((currentToken) => {
      if (currentToken) {
        console.log("current token for client: ", currentToken);

        localStorage.setItem("fireToken", currentToken);
        // setTokenFound(true);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        // setTokenFound(false);
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // catch error while creating client token
    });
};

export const onMessageListener = () => 
  new Promise((resolve) => {
    if (messaging){
    messaging.onMessage((payload) => {
      console.log(payload);
      resolve(payload);
    });}
  });

