import React, { Component } from "react";
import "../../style-sheets/createTutor.css";
import { Row, Col, Button, Form, Container } from "react-bootstrap";
import axios from "axios";
import { APILINK } from "../../EndPoint";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { Checkbox, DatePicker, Select } from "antd";

//redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { userRegister, LogOut } from "../../global-state/actions/authAction";
import { showNav } from "../../global-state/actions/showNavAction";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { sortOn } from "../sortFunction";

// import bcrypt from "bcrypt"
let days = [];
let months = [];
let years = [];
const saltRounds = 10;

for (let i = 1; i <= 31; i++) {
  days.push(i);
}

for (let i = 1; i <= 12; i++) {
  months.push(i);
}
for (let i = 1940; i <= 2021; i++) {
  years.push(i);
}
const axiosApiInstance = axios.create();

class CreateStudent extends Component {
  state = {
    staffSpeciality: "",
    university: "",
    city: "",
    workPlace: "",
    selectedCountry: "",
    password: "",
    fullName: "",
    lastName: "",
    gradYear: "",
    gender: "",
    mobile: "",
    email: "",
    employment: "",
    college: "",
    day: "1",
    month: "1",
    year: "1948",
    selectedCountryRes: null,
    selectedCountryResID: "",
    employeesNumber: "",
    businessType: "",
    switchView: false,
    showStaffMember: false,
    showPostGraduate: false,
    postGrade: [{ certificate_type: "", speciality: "" }],
    passErr: "",
    emailErr: "",
    phoneErr: "",
    countries: [],
    selectedCountryID: "",
    unis: [],
    selectedUni: "",
    selectedUniID: "",
    colleges: [],
    selectedCollege: "",
    selectedCollegeID: "",
    disabled: false
  };

  generalFormHandler = () => {
    this.setState({ switchView: false });
    let dash = document.getElementById("dash");
    dash.style.transform = "translateX(0px)";
  };
  handleCountriesRes = (v, e) => {
    console.log(e);
    this.setState({
      selectedCountryRes: e.value,
      selectedCountryResID: e.id
    });
  };
  infoFormHandler = () => {
    this.setState({ switchView: true });
    let dash = document.getElementById("dash");
    dash.style.transform = "translateX(200px)";
  };
  handleDate = (e) => {
    console.log(e.target.value);
    this.setState({ [e.target.name]: e.target.value });
  };
  responseGoogle = (response) => {
    console.log(response);
  };

  componentDidMount() {
    this.props.showNav(false);
  }

  handleChange = (e) => {
    if (e.target.name == "city") {
      if (e.target.value.match(/^[a-zA-Z ]*$/)) {
        this.setState({ [e.target.name]: e.target.value });
      }
    } else {
      console.log(e.target.value);
      this.setState({ [e.target.name]: e.target.value });
    }
  };
  handleSpeciality2 = async (e) => {
    console.log(e);
  };

  handleIsWorking = (e) => {
    this.setState({ isWorking: e.target.checked });
  };
  handleStaff = (e) => {
    console.log(e.target.checked);
    this.setState({ showStaffMember: e.target.checked });
  };

  handlePostGrad = (e) => {
    console.log(e.target.checked);

    this.setState({ showPostGraduate: e.target.checked });
  };

  handleAddClick = () => {
    let postGradeCopy = [...this.state.postGrade];
    this.setState(
      {
        postGrade: [...postGradeCopy, { certificate_type: "", speciality: "" }]
      },
      () => {
        console.log(this.state.postGrade);
      }
    );
  };

  handlePostGradDropDown = (e, index) => {
    const { name, value } = e.target;

    const list = [...this.state.postGrade];
    console.log(index);
    console.log(list);
    list[index][name] = value;
    console.log(list);
    // this.setState({postGrade : list},()=>{
    //   console.log(this.state.postGrade)
    // })
  };

  handleEmail = (e) => {
    this.setState({ [e.target.name]: e.target.value });

    if (
      e.target.value.match(
        /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/
      )
    ) {
      this.setState({ email: e.target.value });

      this.setState({ emailErr: "", disable: false });
    } else {
      this.setState({
        emailErr: "Enter valid email!",
        disable: true
      });
    }
  };
  handleNumber = (e) => {
    this.setState({ [e.target.name]: e.target.value });

    if (e.target.value.match(/^[0-9]{8,15}$/)) {
      this.setState({ mobile: e.target.value });

      this.setState({ phoneErr: "", disable: false });
    } else {
      this.setState({
        phoneErr: "Phone Number must be min 8 and max 15 numbers",
        disable: true
      });
    }
  };
  handlePassword = (e) => {
    this.setState({ [e.target.name]: e.target.value });

    if (
      e.target.value.match(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/
      )
    ) {
      this.setState({ password: e.target.value });

      this.setState({ passErr: "", disable: false });
    } else {
      this.setState({
        passErr:
          "password minimum 8 characters with at least one upper case and one lower case and number and no special charcters",
        disable: true
      });
    }
  };
  handleGradYear = (date, dateString) => {
    this.setState({ gradYear: dateString });
  };
  onRegister = async (e) => {
    e.preventDefault();
    let registerrequest = {};
    this.setState({ disabled: true });

    if (this.state.showPostGraduate) {
      registerrequest.email = this.state.email;
      registerrequest.password = this.state.password;
      registerrequest.full_name = this.state.fullName;
      registerrequest.last_name = this.state.lastName;
      registerrequest.employment_type = this.state.employment;
      registerrequest.business_type = this.state.businessType;
      registerrequest.no_of_employees = this.state.employeesNumber;
      registerrequest.speciality = this.state.staffSpeciality;
      registerrequest.grad_year = this.state.gradYear;
      registerrequest.university = this.state.selectedUni;
      registerrequest.city = this.state.city;
      registerrequest.country = this.state.selectedCountry;
      registerrequest.mobile = this.state.mobile;
      registerrequest.college = this.state.college;
      registerrequest.gender = this.state.gender;
      registerrequest.institution_mail = this.state.institutionalEmail;
      registerrequest.user_type = "2";
      registerrequest.is_social = false;
      registerrequest.post_grad_study = this.state.postGrade;
      registerrequest.birth_date =
        this.state.year + "-" + this.state.month + "-" + this.state.day;
      registerrequest.country_residence = this.state.selectedCountryRes;
    } else {
      registerrequest.email = this.state.email;
      registerrequest.password = this.state.password;
      registerrequest.full_name = this.state.fullName;
      registerrequest.last_name = this.state.lastName;
      registerrequest.employment_type = this.state.employment;
      registerrequest.business_type = this.state.businessType;
      registerrequest.no_of_employees = this.state.employeesNumber;
      registerrequest.speciality = this.state.staffSpeciality;
      registerrequest.grad_year = this.state.gradYear;
      registerrequest.university = this.state.selectedUni;
      registerrequest.city = this.state.city;
      registerrequest.country = this.state.selectedCountry;
      registerrequest.mobile = this.state.mobile;
      registerrequest.college = this.state.selectedCollege;
      registerrequest.gender = this.state.gender;
      registerrequest.institution_mail = this.state.institutionalEmail;
      registerrequest.user_type = "2";
      registerrequest.is_social = false;
      registerrequest.birth_date =
        this.state.year + "-" + this.state.month + "-" + this.state.day;

      registerrequest.country_residence = this.state.selectedCountryRes;
    }

    console.log(registerrequest);
    const userData = await this.props.userRegister(
      registerrequest,
      this.props.history
    );
    // console.log(userData);
    if (userData) {
      this.setState({ BeErr: userData }, () => {
        if (this.state.BeErr.error) {
          for (const error in userData.error) {
            this.setState({ disabled: false });

            console.log(userData.error[error][0]);
            setTimeout(() => {
              toast.error(
                `${
                  error +
                  " : " +
                  userData.error[error][0].charAt(0).toUpperCase() +
                  userData.error[error][0].slice(1)
                }`
              );
            }, 500);
          }
        } else {
          setTimeout(() => {
            toast.success("User Created Successfully");
          }, 500);
          setTimeout(() => {
            this.props.history.push("/view_students");
          }, 3500);
        }
      });
    }
  };

  getCountries = async () => {
    let result = await axiosApiInstance.get(APILINK + "/get_countries");
    if (result) {
      console.log(result);
      this.setState({ countries: result.data });
    }
  };
  handleCountries = (v, e) => {
    console.log(e);
    this.setState({
      selectedCountry: e.value,
      selectedCountryID: e.id
    });
  };
  getUnis = async () => {
    let result = await axiosApiInstance.get(
      APILINK + `/get_schools/${this.state.selectedCountryID}`
    );
    if (result) {
      console.log(result);
      this.setState({ unis: result.data });
    }
  };
  handleUni = (v, e) => {
    console.log(e);
    this.setState({
      selectedUni: e.value,
      selectedUniID: e.id
    });
  };

  getColleges = async () => {
    let result = await axiosApiInstance.get(APILINK + `/get_schools`);
    if (result) {
      console.log(result);
      this.setState({ colleges: result.data.sort(sortOn("name")) });
    }
  };
  handleCollege = (v, e) => {
    console.log(e);
    this.setState({
      selectedCollege: e.value,
      selectedCollegeID: e.id
    });
  };
  render() {
    console.log(
      this.state.day + "-" + this.state.month + "-" + this.state.year
    );
    return (
      <div>
        <Container className="tutor-form">
          <Col className="col-padding2 form" lg={12}>
            <h1 onClick={this.generalFormHandler} className="sub-header">
              Student Creation
            </h1>

            <div id="dash"></div>
            <React.Fragment>
              <Form>
                <Row>
                  <Col>
                    <Form.Group controlId="exampleForm.ControlInput2">
                      <Form.Label>
                        <strong style={{ color: "red", paddingRight: "5px" }}>
                          *
                        </strong>
                        First Name
                      </Form.Label>
                      <Form.Control
                        name="fullName"
                        onChange={this.handleChange}
                        value={this.state.fullName}
                        type="text"
                        placeholder="First name"
                      />
                    </Form.Group>
                    <Form.Group controlId="exampleForm.ControlInput3">
                      <Form.Label>
                        <strong style={{ color: "red", paddingRight: "5px" }}>
                          *
                        </strong>
                        Last Name
                      </Form.Label>
                      <Form.Control
                        name="lastName"
                        onChange={this.handleChange}
                        value={this.state.lastName}
                        type="text"
                        placeholder="Last name"
                      />
                    </Form.Group>
                    <Form.Group controlId="exampleForm.ControlInput4">
                      <Form.Label>
                        {" "}
                        <strong style={{ color: "red", paddingRight: "5px" }}>
                          *
                        </strong>
                        Country of Residence
                      </Form.Label>

                      <Select
                        showSearch
                        allowClear
                        placeholder="select country"
                        value={this.state.selectedCountryRes}
                        onChange={this.handleCountriesRes}
                        style={{ width: "100%" }}
                        onClick={this.getCountries}
                      >
                        {this.state.countries.map((country) => (
                          <Select.Option id={country.id} value={country.name}>
                            {country.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Group>
                    <Form.Group controlId="exampleForm.ControlInput6">
                      <Form.Label>
                        {" "}
                        <strong style={{ color: "red", paddingRight: "5px" }}>
                          *
                        </strong>
                        City
                      </Form.Label>
                      <Form.Control
                        name="city"
                        onChange={this.handleChange}
                        value={this.state.city}
                        type="text"
                        placeholder="City"
                      />
                    </Form.Group>
                    <Form.Group controlId="exampleForm.ControlInput4">
                      <Form.Label>University Country</Form.Label>

                      <Select
                        allowClear
                        showSearch
                        placeholder="select country"
                        value={this.state.selectedCountry}
                        onChange={this.handleCountries}
                        style={{ width: "100%" }}
                        onClick={this.getCountries}
                      >
                        {this.state.countries.map((country) => (
                          <Select.Option id={country.id} value={country.name}>
                            {country.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Group>

                    <Form.Group controlId="exampleForm.ControlInput8">
                      <Form.Label>University</Form.Label>
                      <Select
                        allowClear
                        showSearch
                        placeholder="select Uni"
                        value={this.state.selectedUni}
                        onChange={this.handleUni}
                        style={{ width: "100%" }}
                        onClick={this.getUnis}
                      >
                        {this.state.selectedCountry ? (
                          this.state.unis.map((uni) => (
                            <Select.Option id={uni.id} value={uni.name}>
                              {uni.name}
                            </Select.Option>
                          ))
                        ) : (
                          <Select.Option>Select country first</Select.Option>
                        )}
                      </Select>
                    </Form.Group>
                    <Form.Group controlId="exampleForm.ControlSelect2">
                      <Form.Label>Date of Birth</Form.Label>
                      <Row>
                        <Col className="col-padding3" lg={4}>
                          <Form.Control
                            name="day"
                            value={this.state.day}
                            onChange={this.handleChange}
                            as="select"
                          >
                            {days.map((day) => {
                              return <option key={day}>{day}</option>;
                            })}
                          </Form.Control>
                        </Col>

                        <Col className="col-padding3" lg={4}>
                          <Form.Control
                            name="month"
                            value={this.state.month}
                            onChange={this.handleChange}
                            as="select"
                          >
                            {months.map((month) => {
                              return <option key={month}>{month}</option>;
                            })}
                          </Form.Control>
                        </Col>
                        <Col className="col-padding3" lg={4}>
                          <Form.Control
                            name="year"
                            value={this.state.year}
                            onChange={this.handleChange}
                            as="select"
                          >
                            {years.map((year) => {
                              return <option key={year}>{year}</option>;
                            })}
                          </Form.Control>
                        </Col>
                      </Row>
                    </Form.Group>

                    {/* </Col>
                                    <Col> */}
                    <Form.Group controlId="exampleForm.ControlInput3">
                      <Form.Label>
                        <strong style={{ color: "red", paddingRight: "5px" }}>
                          *
                        </strong>
                        Email
                      </Form.Label>
                      <Form.Control
                        onChange={this.handleEmail}
                        value={this.state.email}
                        name="email"
                        type="email"
                        placeholder="Your email"
                      />
                      <p style={{ color: "red", fontWeight: "bold" }}>
                        {this.state.emailErr}
                      </p>
                    </Form.Group>
                    <Form.Group controlId="exampleForm.ControlInput4">
                      <Form.Label>
                        <strong style={{ color: "red", paddingRight: "5px" }}>
                          *
                        </strong>
                        Phone Number
                      </Form.Label>
                      <Form.Control
                        onChange={this.handleNumber}
                        name="mobile"
                        value={this.state.mobile}
                        type="number"
                        placeholder="Your number"
                      />
                      <p style={{ color: "red", fontWeight: "bold" }}>
                        {this.state.phoneErr}
                      </p>
                    </Form.Group>
                    <Form.Group controlId="exampleForm.ControlInput3">
                      <Form.Label>
                        <strong style={{ color: "red", paddingRight: "5px" }}>
                          *
                        </strong>
                        Password
                      </Form.Label>
                      <Form.Control
                        name="password"
                        onChange={this.handlePassword}
                        value={this.state.password}
                        type="password"
                      />
                      <p style={{ color: "red", fontWeight: "bold" }}>
                        {this.state.passErr}
                      </p>
                    </Form.Group>
                    <Form.Group controlId="exampleForm.ControlSelect1">
                      <Form.Label>Gender</Form.Label>
                      <Form.Control
                        name="gender"
                        value={this.state.gender}
                        onChange={this.handleChange}
                        as="select"
                      >
                        <option selected style={{ display: "none" }}>
                          I am a
                        </option>
                        <option value="1">Male</option>
                        <option value="2">Female</option>
                      </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Form.Label>Year of Graduation</Form.Label>
                      <DatePicker
                        className="form-control"
                        onChange={this.handleGradYear}
                        picker="year"
                      />
                    </Form.Group>

                    <Checkbox onChange={this.handleStaff}>
                      Are you a staff member?
                    </Checkbox>
                    {this.state.showStaffMember ? (
                      <React.Fragment>
                        <Form.Group controlId="exampleForm.ControlInput">
                          <Form.Label>Veterinary College</Form.Label>
                          <Select
                            allowClear
                            showSearch
                            placeholder="Select College"
                            value={this.state.selectedCollege}
                            onChange={this.handleCollege}
                            style={{ width: "100%" }}
                            onClick={this.getColleges}
                          >
                            {this.state.colleges.map((college) => (
                              <Select.Option
                                id={college.id}
                                value={college.name}
                              >
                                {college.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlInput5">
                          <Form.Label>Speciality</Form.Label>
                          <Form.Control
                            name="staffSpeciality"
                            value={this.state.staffSpeciality}
                            onChange={this.handleChange}
                            as="select"
                          >
                            <option selected style={{ display: "none" }}>
                              Choose Speciality
                            </option>
                            <option>Anatomy and embryology</option>
                            <option>Physiology</option>
                            <option>Histology</option>
                            <option>Genetics</option>
                            <option>Biochemistry</option>
                            <option>Biostatistics</option>
                            <option>
                              Veterinary economics and farm management
                            </option>
                            <option>Animal breeding and production</option>
                            <option>Animal behavior and management</option>
                            <option>Pathology</option>
                            <option>Bacteriology</option>
                            <option>Virology</option>
                            <option>Parasitology</option>
                            <option>Pharmacology</option>
                            <option>Immunity</option>
                            <option>Nutrition</option>
                            <option>Surgery and anesthesia</option>
                            <option>Gynecology</option>
                            <option>Fish diseases and management </option>
                            <option>Forensic medicine and toxicology </option>
                            <option>Milk hygiene </option>
                            <option>Radiology </option>
                            <option>Infectious diseases </option>
                            <option>Avian and rabbit diseases </option>
                            <option>Meat hygiene</option>
                            <option>
                              Animal poultry and environmental hygiene
                            </option>
                            <option>Zoonoses</option>
                          </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlInput3">
                          <Form.Label>Institutional Email</Form.Label>
                          <Form.Control
                            onChange={this.handleChange}
                            value={this.state.institutionalEmail}
                            name="institutionalEmail"
                            type="email"
                            placeholder="Institutional Email"
                          />
                        </Form.Group>
                      </React.Fragment>
                    ) : null}
                    <Checkbox onChange={this.handlePostGrad}>
                      Do you have post graduate studies?
                    </Checkbox>
                    {this.state.showPostGraduate ? (
                      <React.Fragment>
                        {this.state.postGrade.map((study, i) => {
                          return (
                            <React.Fragment key={i}>
                              <Form.Group controlId="exampleForm.ControlInput">
                                <Form.Label>Degree</Form.Label>
                                <Form.Control
                                  name="certificate_type"
                                  value={this.state.certificate_type}
                                  onChange={(e) =>
                                    this.handlePostGradDropDown(e, i)
                                  }
                                  as="select"
                                >
                                  <option selected style={{ display: "none" }}>
                                    Choose your degree
                                  </option>
                                  <option value="1">Diploma</option>
                                  <option value="2">Master</option>
                                  <option value="3">PHD</option>
                                </Form.Control>
                              </Form.Group>
                              <Form.Group controlId="exampleForm.ControlInput5">
                                <Form.Label>Speciality</Form.Label>
                                <Form.Control
                                  name="speciality"
                                  value={this.state.speciality}
                                  onChange={(e) =>
                                    this.handlePostGradDropDown(e, i)
                                  }
                                  as="select"
                                >
                                  <option selected style={{ display: "none" }}>
                                    Choose Speciality
                                  </option>
                                  <option>Anatomy and embryology</option>
                                  <option>Physiology</option>
                                  <option>Histology</option>
                                  <option>Genetics</option>
                                  <option>Biochemistry</option>
                                  <option>Biostatistics</option>
                                  <option>
                                    Veterinary economics and farm management
                                  </option>
                                  <option>
                                    Animal breeding and production
                                  </option>
                                  <option>
                                    Animal behavior and management
                                  </option>
                                  <option>Pathology</option>
                                  <option>Bacteriology</option>
                                  <option>Virology</option>
                                  <option>Parasitology</option>
                                  <option>Pharmacology</option>
                                  <option>Immunity</option>
                                  <option>Nutrition</option>
                                  <option>Surgery and anesthesia</option>
                                  <option>Gynecology</option>
                                  <option>Fish diseases and management </option>
                                  <option>
                                    Forensic medicine and toxicology{" "}
                                  </option>
                                  <option>Milk hygiene </option>
                                  <option>Radiology </option>
                                  <option>Infectious diseases </option>
                                  <option>Avian and rabbit diseases </option>
                                  <option>Meat hygiene</option>
                                  <option>
                                    Animal poultry and environmental hygiene
                                  </option>
                                  <option>Zoonoses</option>
                                </Form.Control>
                              </Form.Group>
                              <div className="btn-box">
                                {this.state.postGrade.length - 1 === i && (
                                  <Button
                                    className="add-study"
                                    onClick={this.handleAddClick}
                                  >
                                    Add Study
                                  </Button>
                                )}
                              </div>
                            </React.Fragment>
                          );
                        })}
                      </React.Fragment>
                    ) : null}
                    <Checkbox onChange={this.handleIsWorking}>
                      Are you actively working in the field?
                    </Checkbox>
                    {this.state.isWorking ? (
                      <React.Fragment>
                        <Form.Group controlId="exampleForm.ControlInputf">
                          <Form.Label>Type of Employment</Form.Label>
                          <Form.Control
                            name="employment"
                            value={this.state.employment}
                            onChange={this.handleChange}
                            as="select"
                          >
                            <option selected style={{ display: "none" }}>
                              Choose your employment type
                            </option>
                            <option value="1">Business Owner</option>
                            <option value="2">Employee</option>
                            <option value="3">Both</option>
                          </Form.Control>
                        </Form.Group>
                        {this.state.employment === "1" ? (
                          <React.Fragment>
                            <Form.Group controlId="exampleForm.ControlInputr">
                              <Form.Label>Business Type</Form.Label>
                              <Form.Control
                                name="businessType"
                                value={this.state.businessType}
                                onChange={this.handleChange}
                                as="select"
                              >
                                <option selected style={{ display: "none" }}>
                                  Choose your business type
                                </option>
                                <option>Pet animal clinic owner </option>
                                <option>Drug store owner</option>
                                <option>Laboratory owner</option>
                                <option>Dairy Farm owner</option>
                                <option>Beef cattle farm owner</option>
                                <option>Poultry farm owner</option>
                                <option>Horse farm owner</option>
                                <option>Sheep farm owner</option>
                                <option>Goat farm owner</option>
                                <option>Pigs farm owner</option>
                                <option>Ration factory owner</option>
                                <option>Rabbit farm owner</option>
                                <option>other</option>
                              </Form.Control>
                            </Form.Group>
                            <Form.Group controlId="exampleForm.ControlInpute">
                              <Form.Label>Number of Employees</Form.Label>
                              <Form.Control
                                name="employeesNumber"
                                value={this.state.employeesNumber}
                                onChange={this.handleChange}
                                as="select"
                              >
                                <option selected style={{ display: "none" }}>
                                  Choose Employee Range
                                </option>
                                <option value="0-50">0-50</option>
                                <option value="50-100">50-100</option>
                                <option value="Above 100">Above 100</option>
                              </Form.Control>
                            </Form.Group>
                          </React.Fragment>
                        ) : this.state.employment == "3" ? (
                          <React.Fragment>
                            <Form.Group controlId="exampleForm.ControlInputw">
                              <Form.Label>Business Type</Form.Label>
                              <Form.Control
                                name="businessType"
                                value={this.state.businessType}
                                onChange={this.handleChange}
                                as="select"
                              >
                                <option selected style={{ display: "none" }}>
                                  Choose your business type
                                </option>
                                <option value="commercial">Commercial</option>
                                <option value="medicine">Medicine</option>
                                <option value="engineering">Engineering</option>
                              </Form.Control>
                            </Form.Group>
                            <Form.Group controlId="exampleForm.ControlInputq">
                              <Form.Label>Number of Employees</Form.Label>
                              <Form.Control
                                name="employeesNumber"
                                value={this.state.employeesNumber}
                                onChange={this.handleChange}
                                as="select"
                              >
                                <option selected style={{ display: "none" }}>
                                  Choose Employee Range
                                </option>
                                <option value="0-50">0-50</option>
                                <option value="50-100">50-100</option>
                                <option value="Above 100">Above 100</option>
                              </Form.Control>
                            </Form.Group>
                          </React.Fragment>
                        ) : null}
                      </React.Fragment>
                    ) : null}

                    <Button
                      disabled={this.state.disabled}
                      onClick={this.onRegister}
                      className="submit-btn"
                      type="submit"
                    >
                      Create
                    </Button>
                  </Col>
                </Row>
              </Form>
            </React.Fragment>
          </Col>
        </Container>
        <ToastContainer position="bottom-right" />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ userRegister, LogOut, showNav }, dispatch);
};

export default connect(
  null,
  mapDispatchToProps
)(ErrorHandler(CreateStudent, axiosApiInstance));
