import React, { Component } from "react";
import {
  Row,
  Col,
  Container,
  Button,
  Form,
  Modal,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import "../../style-sheets/training.css";
import ErrorHandler from "../../error-handler/ErrorHandler";
import axios from "axios";
import { APILINK } from "../../EndPoint";
import { Empty, Spin, Pagination, Select, Card } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const axiosApiInstance = axios.create();
const { Meta } = Card;

class SpecialityForum extends Component {
  state = {
    loading: false,
    data: [],
    count: 1,
    current: 1,
    showAdd: false,
    forumID: "",
    title: "",
    details: "",
    err: "",
    specialities: [
      { id: "1", name: "Pet animals " },
      { id: "2", name: "Cattle" },
      { id: "3", name: "Avian" },
      { id: "4", name: "Camels" },
      { id: "5", name: "Swine" },
      { id: "6", name: "Ovine" },
      { id: "7", name: "Caprine" },
      { id: "8", name: "Fish hygiene" },
      { id: "9", name: "Wild animals" },
      { id: "10", name: "Veterinary Pharmaceuticals" },
      { id: "11", name: "Food hygiene" },
      { id: "12", name: "Laboratory medicine" },
      { id: "13", name: "Other" }
    ],
    selectedSpeciality: null,
    count: 1,
    current: 1,
    show: false
  };
  componentDidMount = async () => {};

  openAddModal = (e) => {
    this.setState({ showAdd: true, forumID: e.target.id }, () =>
      console.log(this.state.forumID)
    );
  };
  hideAdd = (e) => {
    this.setState({ showAdd: false });
  };
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  addQuestion = async () => {
    if (this.state.title == "") {
      setTimeout(() => {
        toast.error(`Please Fill out the required fields !`);
      }, 500);
    } else {
      let data = {
        forum_id: this.state.forumID,
        title: this.state.title,
        question_details: this.state.details
      };
      let result = await axiosApiInstance.post(
        APILINK + `/create_forum_question`,
        data
      );
      if (result.data) {
        console.log(result);
        setTimeout(() => {
          toast.info(`A new Question added successfully`);
        }, 500);
        this.setState({ showAdd: false });
        setTimeout(() => {
          this.props.history.push(
            `/view_fourm_questions/${this.state.forumID}`
          );
        }, 3000);
      }
    }
  };
  handleSpecialities = (v, e) => {
    console.log(e);
    this.setState(
      {
        selectedSpeciality: e.value,
        selectedSpecialityID: e.id
      },
      async () => {
        let result;
        this.setState({ loading: true });

        result = await axiosApiInstance.get(
          APILINK +
            `/get_free_forums?speciality=${this.state.selectedSpecialityID}&page=${this.state.current}`
        );

        console.log(result);
        if (result) {
          this.setState(
            {
              data: result.data.data,
              loading: false,
              count: result.data.count
            },
            () => console.log(this.state.data)
          );
        }
      }
    );
  };

  onChange = (page) => {
    console.log(page);
    this.setState(
      {
        current: page
      },
      async () => {
        let result = await axiosApiInstance
          .get(
            APILINK +
              `/get_free_forums?speciality=${this.state.selectedSpecialityID}&page=${this.state.current}`
          )
          .then((res) => {
            console.log(res);
            this.setState({
              data: res.data.data,
              count: res.data.count,
              loading: false
            });
          });
      }
    );
  };
  showWarning = (e) => {
    this.setState({ show: true, forumID: e.target.id });
  };
  close = (e) => {
    this.setState({ show: false });
  };
  deleteForum = async (e) => {
    let result = await axiosApiInstance.delete(
      APILINK + `/delete_forum/${this.state.forumID}`
    );
    this.setState({ show: false }, async () => {
      let result;
      this.setState({ loading: true });

      result = await axiosApiInstance.get(
        APILINK +
          `/get_free_forums?speciality=${this.state.selectedSpecialityID}&page=${this.state.current}`
      );

      console.log(result);
      if (result) {
        this.setState(
          {
            data: result.data.data,
            loading: false,
            count: result.data.count
          },
          () => console.log(this.state.data)
        );
      }
    });
  };
  render() {
    return (
      <div>
        <Container className="content-margin">
          <Row>
            <Col sm="7">
              <h3 style={{ color: "#124E68" }}>Community</h3>
            </Col>
            {this.props.user.user_type == "3" ? (
              <Col sm="5">
                <Link to="/create_speciality_forum">
                  <Button className="forumBtn1"> Create Forum</Button>
                </Link>
              </Col>
            ) : null}
          </Row>
          <Row>
            <Col sm="6">
              <Form.Label>Select Speciality</Form.Label>

              <Select
                allowClear
                placeholder="Select Speciality"
                value={this.state.selectedSpeciality}
                onChange={this.handleSpecialities}
                style={{ width: "100%" }}
                showSearch
              >
                {this.state.specialities.map((speciality) => (
                  <Select.Option id={speciality.id} value={speciality.name}>
                    {speciality.name}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          </Row>

          <Row className="my-5">
            {this.state.data.length !== 0 && this.state.loading === false ? (
              this.state.data.map((forum) => {
                return (
                  <Col sm="12" md="4">
                    <Card
                      className="my-3"
                      actions={[
                        <>
                          <OverlayTrigger
                            key={"2"}
                            placement={"top"}
                            overlay={
                              <Tooltip id={`tooltip-${"top"}`}>
                                Add Question
                              </Tooltip>
                            }
                          >
                            <i
                              id={forum.id}
                              className="fas fa-plus-circle pr-2"
                              onClick={this.openAddModal}
                            ></i>
                          </OverlayTrigger>
                        </>,
                        <>
                          <OverlayTrigger
                            key={"2"}
                            placement={"top"}
                            overlay={
                              <Tooltip id={`tooltip-${"top"}`}>
                                View Questions
                              </Tooltip>
                            }
                          >
                            <Link to={`/view_fourm_questions/${forum.id}`}>
                              <i className="fas fa-eye pr-2 viewForum"></i>
                            </Link>
                          </OverlayTrigger>
                        </>,
                        <>
                          {this.props.user.user_type === "3" ? (
                            <OverlayTrigger
                              key={"2"}
                              placement={"top"}
                              overlay={
                                <Tooltip id={`tooltip-${"top"}`}>
                                  Delete Forum
                                </Tooltip>
                              }
                            >
                              <i
                                onClick={this.showWarning}
                                id={forum.id}
                                className="fas fa fa-trash pr-2"
                              ></i>
                            </OverlayTrigger>
                          ) : null}
                        </>
                      ]}
                    >
                      <Meta
                        title={forum.title}
                        description={forum.description}
                      />
                    </Card>
                    {/* <div className="forumCard my-4">
                      <Row>
                        <Col sm="12">
                          {" "}
                          <h5>{forum.title}</h5>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="12">
                          {" "}
                          <p>{forum.description}</p>{" "}
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="6">
                          <Button
                            onClick={this.openAddModal}
                            id={forum.id}
                            className="forumBtn"
                          >
                            <i
                              id={forum.id}
                              className="fas fa-plus-circle pr-2"
                            ></i>{" "}
                            Questions
                          </Button>
                        </Col>
                        <Col sm="6">
                          <Button className="forumBtn">
                            {" "}
                            <Link to={`/view_fourm_questions/${forum.id}`}>
                              <i className="fas fa-eye pr-2"></i> Questions
                            </Link>
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="12">
                          <Button
                            onClick={this.showWarning}
                            id={forum.id}
                            className="forumBtn"
                          >
                            <i id={forum.id} className="fas fa-trash pr-2" />
                            Forum
                          </Button>
                        </Col>
                      </Row>
                   
                    </div> */}
                  </Col>
                );
              })
            ) : this.state.loading ? (
              <Spin className="text-center" tip="...loading" />
            ) : this.state.loading === false && this.state.data.length === 0 ? (
              <Col className="text-center" sm="12">
                <Empty className="text-center" />
              </Col>
            ) : null}
          </Row>
          <Row>
            {this.state.data.length !== 0 ? (
              <Pagination
                onChange={this.onChange}
                defaultCurrent={this.state.current}
                total={this.state.count}
                current={this.state.current}
              />
            ) : null}
          </Row>
        </Container>
        <Modal show={this.state.showAdd} onHide={this.hideAdd}>
          <Container fluid className="py-4">
            <Form id="infoForm">
              <Row>
                <Col className="text-center pt-2" sm={12}>
                  {" "}
                  <h6>Add Question</h6>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col sm={12}>
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label>
                      <strong style={{ color: "red", paddingRight: "5px" }}>
                        *
                      </strong>
                      Title
                    </Form.Label>
                    <Form.Control
                      name="title"
                      value={this.state.title}
                      onChange={this.handleChange}
                      type="text"
                      placeholder="Title"
                      as="textarea"
                      rows={4}
                    />
                  </Form.Group>
                </Col>
                <Col sm={12} className="mt-2">
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label>Details</Form.Label>
                    <Form.Control
                      name="details"
                      value={this.state.details}
                      onChange={this.handleChange}
                      type="text"
                      placeholder="Details"
                      as="textarea"
                      rows={4}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="">
                <Col sm={12} className="mt-4 text-center">
                  <Button className="m-2 forumBtn1" onClick={this.hideAdd}>
                    Cancel
                  </Button>
                  <Button className="m-2 forumBtn1" onClick={this.addQuestion}>
                    Add Question
                  </Button>{" "}
                </Col>
              </Row>
            </Form>
          </Container>
        </Modal>
        <Modal show={this.state.show} onHide={this.close}>
          <Container className="p-4">
            <Row>
              <Col className="text-center" sm={12}>
                {" "}
                <h6>Are u sure u want to delete this Forum?</h6>
              </Col>
              <Row style={{ margin: "auto" }}>
                <Col md={12}>
                  <Button onClick={this.deleteForum} className="forumBtn1 mx-3">
                    Delete
                  </Button>
                  <Button className="forumBtn1" onClick={this.close}>
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Row>
          </Container>
        </Modal>
        <ToastContainer position="bottom-right" />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isSubject: state.isSubject.isSubject,
  user: state.auth.user
});

export default connect(
  mapStateToProps,
  null
)(ErrorHandler(SpecialityForum, axiosApiInstance));
