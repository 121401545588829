import React, { Component } from "react";
import {
  Row,
  Col,
  Container,
  Button,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import "../../style-sheets/training.css";
import ErrorHandler from "../../error-handler/ErrorHandler";
import axios from "axios";
import { APILINK } from "../../EndPoint";
import { Empty, Spin, Pagination, Card } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const axiosApiInstance = axios.create();
const { Meta } = Card;

class ViewForumQues extends Component {
  state = {
    loading: false,
    data: [],
    count: 1,
    current: 1,
    showAdd: false,
    quesID: "",
    title: "",
    details: "",
    showWarningMsg: false,
  };
  getQuestions = async () => {
    this.setState({ loading: true });
    let result;

    result = await axiosApiInstance.get(
      APILINK +
        `/get_forum_questions?forum_id=${this.props.match.params.id}&page=${this.state.current}`
    );

    console.log(result);

    this.setState({
      data: result.data.data,
      loading: false,
      count: result.data.count,
    });
  };
  componentDidMount() {
    this.getQuestions();
  }
  onChange = (page) => {
    console.log(page);
    this.setState(
      {
        current: page,
      },
      async () => {
        let result = await axiosApiInstance
          .get(
            APILINK +
              `/get_forum_questions?forum_id=${this.props.match.params.id}&page=${this.state.current}`
          )
          .then((res) => {
            console.log(res);
            this.setState({ data: res.data.data, count: res.data.count });
          });
      }
    );
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  openWarning = (e) => {
    this.setState({ showWarningMsg: true, quesID: e.target.id });
  };

  closeWarning = (e) => {
    this.setState({ showWarningMsg: false });
  };
  deleteQuestion = async () => {
    let result = await axiosApiInstance.delete(
      APILINK + `/edit_forum_question/${this.state.quesID}`
    );
    console.log(result);
    if (result.data == "") {
      this.setState({ showWarningMsg: false });
      this.getQuestions();
    }
  };
  render() {
    return (
      <div>
        <Container className="content-margin">
          <Row>
            <Col sm="12">
              <h4 style={{ color: "#124E68" }}>
                <i
                  onClick={() => this.props.history.goBack()}
                  className="fas fa-chevron-left pr-4"
                ></i>
                All Questions
              </h4>
            </Col>
          </Row>

          <Row className="my-5">
            {this.state.data.length !== 0 && this.state.loading === false ? (
              this.state.data.map((data) => {
                return (
                  <Col key={data.id} sm="12" md="4">
                    <Card
                      className="my-3"
                      actions={[
                        <>
                          <OverlayTrigger
                            key={"2"}
                            placement={"top"}
                            overlay={
                              <Tooltip id={`tooltip-${"top"}`}>
                                View Answers
                              </Tooltip>
                            }
                          >
                            <Link
                              to={`/view_forum_question_answers/${data.id}`}
                            >
                              <i
                                className="fas fa-eye pr-1"
                                style={{ color: "white" }}
                              ></i>
                            </Link>
                          </OverlayTrigger>
                        </>,
                        <>
                          {this.props.user.user_type == "3" ||
                          this.props.user.id == data.author.id ? (
                            <OverlayTrigger
                              key={"2"}
                              placement={"top"}
                              overlay={
                                <Tooltip id={`tooltip-${"top"}`}>
                                  Delete Question
                                </Tooltip>
                              }
                            >
                              <i
                                onClick={this.openWarning}
                                id={data.id}
                                className="fas fa fa-trash pr-2"
                              ></i>
                            </OverlayTrigger>
                          ) : null}
                        </>,
                      ]}
                    >
                      <Meta
                        title={data.title}
                        description={data.question_details}
                      />
                    </Card>
                    {/* <div className="forumCard my-4">
                      <h5>{data.title}</h5>
                      <p>{data.question_details}</p>{" "}
                      {this.props.user.user_type == "3" ||
                      this.props.user.id == data.author.id ? (
                        <Button
                          onClick={this.openWarning}
                          id={data.id}
                          className="forumBtn"
                        >
                          {" "}
                          <i className="fas fa-trash pr-1"></i> Question
                        </Button>
                      ) : null}{" "}
                      <Button className="forumBtn">
                        {" "}
                        <Link to={`/view_forum_question_answers/${data.id}`}>
                          <i className="fas fa-eye pr-1"></i> Answers
                        </Link>
                      </Button>
                    </div> */}
                  </Col>
                );
              })
            ) : this.state.loading ? (
              <Spin className="text-center" tip="...loading" />
            ) : (
              <Empty className="text-center" />
            )}
          </Row>
          <Row>
            {this.state.data.length !== 0 ? (
              <Pagination
                onChange={this.onChange}
                defaultCurrent={this.state.current}
                total={this.state.count}
                current={this.state.current}
              />
            ) : null}
          </Row>
        </Container>

        <Modal show={this.state.showWarningMsg} onHide={this.closeWarning}>
          <Container className="p-4">
            <Row>
              <Col className="text-center" sm={12}>
                {" "}
                <h6>Are u sure u want to delete this Question?</h6>
              </Col>
              <Row style={{ margin: "auto" }}>
                <Col md={12}>
                  <Button
                    onClick={this.deleteQuestion}
                    className="forumBtn1 mx-3"
                  >
                    Delete
                  </Button>
                  <Button className="forumBtn1" onClick={this.closeWarning}>
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Row>
          </Container>
        </Modal>
        <ToastContainer position="bottom-right" />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isSubject: state.isSubject.isSubject,
  user: state.auth.user,
});

export default connect(
  mapStateToProps,
  null
)(ErrorHandler(ViewForumQues, axiosApiInstance));
