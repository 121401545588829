import React, { useState } from "react";
import axios from "axios";
import { APILINK } from "../../EndPoint";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { Container, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Input, Button } from "antd";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";

const axiosApiInstance = axios.create();

const CreateTraining = (props) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();
  const [disabled, setDisabled] = useState(false);
  const onTrainingSubmit = async (data) => {
    setDisabled(true);
    console.log(data);
    if (props.isSubject) {
      data.subject_round_id = props.match.params.id;
    } else {
      data.course_round_id = props.match.params.id;
    }
    let result = await axiosApiInstance
      .post(APILINK + `/create_training`, data)
      .then((res) => {
        setTimeout(() => {
          toast.info("Training has been created successfully");
        }, 500);
        setTimeout(() => {
          props.history.push(`/view_training/${props.match.params.id}`);
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
        setDisabled(false);
      });
  };
  console.log(props.isSubject);
  return (
    <Container className="viewSuper align-left">
      <Row>
        <Col lg={12}>
          <h2 style={{ marginTop: "50px" }}>Create Training</h2>
          <form onSubmit={handleSubmit(onTrainingSubmit)}>
            <Row>
              <Col lg={6}>
                <label className="label">Training Title</label>
                <Input
                  {...register("title", { required: true })}
                  maxlength="60"
                />

                <p>
                  {errors.title?.type === "required" &&
                    "This field is required"}
                </p>
              </Col>
            </Row>

            <Button
              disabled={disabled}
              className="forumBtn1"
              type="submit"
              onClick={handleSubmit(onTrainingSubmit)}
            >
              Create
            </Button>
          </form>
        </Col>
      </Row>
      <ToastContainer position="bottom-right" />
    </Container>
  );
};
const mapStateToProps = (state) => ({
  isSubject: state.isSubject.isSubject,
});
export default connect(
  mapStateToProps,
  null
)(ErrorHandler(CreateTraining, axiosApiInstance));
