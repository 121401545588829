import React, { Component } from "react";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { APILINK } from "../../EndPoint";
import axios from "axios";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { DatePicker, TimePicker } from "antd";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";

const axiosApiInstance = axios.create();

class EditMeeting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      link: "",
      duration: "",
      startDate: "",
      startTime: "",
    };
  }
  getDetails = async () => {
    let result = await axiosApiInstance.get(
      APILINK + `/meeting_update/${this.props.match.params.id}`
    );
    console.log(result);
    this.setState({
      link: result.data.link,
      duration: result.data.duration,
      startDate: result.data.date.date,
      startTime: result.data.start_time,
    });
  };
  componentDidMount = async () => {
    this.getDetails();
  };
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleStartDate = (date, dateString) => {
    // console.log(date, dateString);
    this.setState({ startDate: dateString });
  };

  edit = async () => {
    this.setState({ disabled: true });

    let data = {
      link: this.state.link,
      duration: this.state.duration,
      start_date: this.state.startDate,
      start_time: this.state.startTime,
      subject_round_id: this.props.match.params.round,
    };
    console.log(data);
    let result = await axiosApiInstance
      .put(APILINK + `/meeting_update/${this.props.match.params.id}`, data)
      .then((res) => {
        setTimeout(() => {
          toast.info("lecture has been updated successfully");
        }, 500);
        this.props.history.goBack();
      })
      .catch((err) => {
        this.setState({ disabled: false });

        console.log(err);
      });
  };
  handleTime = (time, timeString) => {
    this.setState({ startTime: timeString });
  };
  render() {
    return (
      <div className="admin-home pb-5">
        <Container className="content-margin">
          <h2>Edit Lecture</h2>
          <Form id="infoForm">
            <Row>
              <Col sm={6}>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Lecture Link</Form.Label>
                  <Form.Control
                    name="link"
                    value={this.state.link}
                    onChange={this.handleChange}
                    type="text"
                    placeholder="Lecture link"
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Duration</Form.Label>
                  <Form.Control
                    name="duration"
                    value={this.state.duration}
                    onChange={this.handleChange}
                    type="text"
                    placeholder="Duration"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm="6">
                <Form.Label>Lecture start date</Form.Label>
                <DatePicker
                  value={moment(this.state.startDate)}
                  onChange={this.handleStartDate}
                />
              </Col>
              <Col sm="6">
                <Form.Label>Start Time</Form.Label>
                <TimePicker
                  onChange={this.handleTime}
                  value={moment(this.state.startTime, "HH:mm:ss")}
                />
              </Col>
            </Row>

            <Row>
              <Col sm="12">
                <Button
                  disabled={this.state.disabled}
                  className="forumBtn1 mt-4"
                  onClick={this.edit}
                >
                  Save changes
                </Button>
              </Col>
            </Row>
          </Form>
        </Container>
        <ToastContainer position="bottom-right" />
      </div>
    );
  }
}
export default ErrorHandler(EditMeeting, axiosApiInstance);
