import { Empty } from "antd";
import React from "react";
import { Row, Col, Container, Modal } from "react-bootstrap";

export default function WrongAnswersModal({ results, show, hide }) {
  return (
    <Modal className="" show={show} onHide={hide} size="xl">
      <Container fluid className="p-4">
        <Modal.Header closeButton>
          <Modal.Title>Review your wrong answers</Modal.Title>
        </Modal.Header>
        {results?.incorrect?.length > 0 ? (
          results?.incorrect?.map((info) => {
            return (
              <>
                <Row>
                  <Col sm="6">
                    <p>
                      <strong>{info.question}</strong>
                    </p>
                  </Col>
                  <Col sm="6">
                    <p>The correct answer : {info.correct_choice.choice}</p>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <p>The explaination : {info.explanation}</p>
                  </Col>
                </Row>
                <hr />
              </>
            );
          })
        ) : (
          <Empty />
        )}
      </Container>
    </Modal>
  );
}
