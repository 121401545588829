import React from 'react'
import styles from './Switch.module.css'
export default function SwitchButton({ className, id, onClick, defaultChecked, onChange, ...props }) {
  return (
    <div className={className}>
      <label className={styles.switch}>
        <input type="checkbox"
          id={id}
          onClick={(e) => onClick(e.target.checked, e)}
          defaultChecked={defaultChecked}
          onChange={(e) => onChange(e.target.checked, e)}
          {...props}
        />
        <span className={`${styles.slider} ${styles.round}`}></span>
      </label>
    </div>
  )
}
