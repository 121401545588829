import React, { Component } from "react";
import "../../style-sheets/sidemenu.css";
import { NavLink, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import ErrorHandler from "../../error-handler/ErrorHandler";
import axios from "axios";

const axiosApiInstance = axios.create();

class SideMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  addCourse = () => {
    this.props.history.push(`/create-course`);
  };

  requestCourse = () => {
    this.props.history.replace(`/`);
  };

  render() {
    return (
      <>
      <aside className="my-menu">
        {this.props.user.user_type === "3" ? (
          <React.Fragment>
            <Button className="addCourseBtn">
              <Link to="/create-course">+ Add Course</Link>
            </Button>
            <ul className="side-list">
              <li>
                <NavLink
                  to="/admin_courses"
                  exact
                  activeClassName="navitem-active"
                >
                  Courses
                </NavLink>
                <NavLink
                  to="/view_students"
                  exact
                  activeClassName="navitem-active"
                >
                  Students
                </NavLink>
                <NavLink
                  to="/view_tutors"
                  exact
                  activeClassName="navitem-active"
                >
                  Tutors
                </NavLink>
                <NavLink
                  to="/view_fourms"
                  exact
                  activeClassName="navitem-active"
                >
                  All Forums
                </NavLink>
                <NavLink
                  to="/verify_payments"
                  exact
                  activeClassName="navitem-active"
                >
                  Verify Payments
                </NavLink>
                <NavLink
                  to="/speciality_forums"
                  exact
                  activeClassName="navitem-active"
                >
                 Community
                </NavLink>
              </li>
            </ul>
          </React.Fragment>
        ) : this.props.user.user_type === "2" ? (
          <React.Fragment>
            <Button className="addCourseBtn">
              <Link to="/">Request Course</Link>
            </Button>
            <ul className="side-list">
              <li>
                <NavLink to="/courses" exact activeClassName="navitem-active">
                 
                  Courses
                </NavLink>
               
                <NavLink
                  to="/speciality_forums"
                  exact
                  activeClassName="navitem-active"
                >
                 Community
                </NavLink>
              </li>
            </ul>
          </React.Fragment>
        ) : (
          <ul className="side-list">
            <li>
              <NavLink to="/" exact activeClassName="navitem-active">
                Courses
              </NavLink>
              
              <NavLink
                to="/speciality_forums"
                exact
                activeClassName="navitem-active"
              >
               Community
              </NavLink>
              
            </li>
          </ul>
        )}
      </aside>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(
  ErrorHandler(SideMenu, axiosApiInstance)
);
