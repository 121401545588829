import React, { Component } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import {
  Container,
  Row,
  Col,
  Button,
  OverlayTrigger,
  Tooltip,
  Modal,
  Form,
  Table,
} from "react-bootstrap";
// import { Link } from "react-router-dom";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { APILINK } from "../../EndPoint";
import { Link } from "react-router-dom";
import { Empty, Pagination, Spin } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isSubject } from "../../global-state/actions/isSubjectAction";
const axiosApiInstance = axios.create();
class StudentPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      tutors: [],
      data2: [],
      count: 1,
      loading: false,
      policy: "",
      current: 1,
      showSubjectModel: false,
      showSubjectRoundForm: false,
      showEnroll: false,
      showEdit: false,
      startDate: "",
      endDate: "",
      BEerr: "",
      columns: [
        {
          name: "Course",
          selector: (row) => (row.course !== null ? row.course.title : null),
          sortable: true,
          right: true,
        },
        {
          name: "Installment Policy",
          selector: (row) => (row.installment_policy === "2" ? "Full" : "Half"),
          sortable: true,
          right: true,
        },
        {
          name: "Amount",
          selector: "amount",
          sortable: true,
          right: true,
        },
      ],
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.getPayment();
  }
  getPayment = async () => {
    let result = await axiosApiInstance
      .get(
        APILINK +
          `/admin_student_payments?student_id=${this.props.match.params.id}&page=${this.state.current}`
      )
      .catch((err) => {
        console.log(err.response);
        if (err.response) {
          this.setState({ BEerr: "No Payments Found", loading: false });
        }
      });

    if (result) {
      this.setState({ data: result.data.data, loading: false });
    }
  };

  onChange = (page) => {
    console.log(page);
    this.setState(
      {
        current: page,
      },
      async () => {
        const result = await axiosApiInstance
          .get(
            APILINK +
              `/admin_student_payments?student_id=${this.props.match.params.id}&page=${this.state.current}`
          )
          .then((res) => {
            console.log(res);
            this.setState({ data: res.data.data, count: res.data.count });
          })
          .catch((err) => {
            console.log(err.response);
            if (err.response) {
              this.setState({ BEerr: "No Payments Found", loading: false });
            }
          });
      }
    );
  };

  render() {
    // console.log(this.state.data[0].course)
    return (
      <div>
        {this.state.loading ? (
          <Spin className="spinElement" tip="..loading" />
        ) : (
          <Container className="viewSuper">
            <Row>
              <Col>
                <h1 className="course-title">Payment</h1>
              </Col>
            </Row>

            <Row>
              <Col style={{ textAlign: "center" }} sm={12}>
                <DataTable
                  columns={this.state.columns}
                  data={this.state.data}
                />
              </Col>
            </Row>
            <Row>
              <Col style={{ textAlign: "center" }} sm={12}>
                {this.state.BEerr !== "" && this.state.loading == false ? (
                  <Empty />
                ) : null}
              </Col>
            </Row>
            <Row className="p-4">
              {this.state.data.length !== 0 ? (
                <Pagination
                  onChange={this.onChange}
                  defaultCurrent={this.state.current}
                  total={this.state.count}
                  current={this.state.current}
                />
              ) : null}
            </Row>
          </Container>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  isAuth: state.auth.isAuth,
});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ isSubject }, dispatch);
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ErrorHandler(StudentPayment, axiosApiInstance));
