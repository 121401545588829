import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import img1 from "../../images/loginImg.png";
import "../../style-sheets/loginStyle.css";
import { Form, Input, Button } from "antd";
import "antd/dist/antd.css";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import axios from "axios";
//redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { passwordReset } from "../../global-state/actions/passwordAction";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { APILINK } from "../../EndPoint";
// import bcrypt from "bcrypt"
const saltRounds = 10;
const axiosApiInstance = axios.create();

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      BeErr: "",
      passErr: "",
    };
  }
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });

    if (
      e.target.value.match(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/
      )
    ) {
      this.setState({ password: e.target.value });

      this.setState({ passErr: "", disable: false });
    } else {
      this.setState({
        passErr:
          "password minimum 8 characters with at least one upper case and one lower case and number and no special charcters",
        disable: true,
      });
    }
  };
  onSend = async (e) => {
    let request = {};

    request.password = this.state.password;

    const result = await axiosApiInstance
      .post(this.props.url, request)
      .then((res) => {
        setTimeout(() => {
          toast.info("Password Reset Successfully");
        }, 500);
      });
  };

  componentDidMount() {}

  render() {
    return (
      <div className="admin-home pb-5">
        <Container className="content-margin">
          <Row>
            <Col className="pl-5 text-left" sm={6}>
              <Form
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
                style={{
                  margin: "30px auto 0px auto",
                }}
                layout="vertical"
              >
                <h1 className="title1">Password reset</h1>

                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    { required: true, message: "Please input your password!" },
                  ]}
                  layout="vertical"
                >
                  <Input
                    className="loginInput"
                    placeholder="Password"
                    onChange={this.handleChange}
                    type="password"
                    name="password"
                    value={this.state.password}
                  />
                  {this.state.passErr !== "" ? (
                    <p style={{ color: "red" }}>{this.state.passErr}</p>
                  ) : null}
                </Form.Item>

                <Form.Item>
                  <Button
                    className="loginBtn1"
                    onClick={this.onSend}
                    type="primary"
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                </Form.Item>
                <Row>
                  <Col style={{ margin: "auto" }} sm={12}>
                    {this.state.BeErr !== "" ? (
                      <p style={{ color: "red", fontWeight: "bold" }}>
                        {this.state.BeErr}
                      </p>
                    ) : null}
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <Row></Row>
        </Container>
        <ToastContainer position="bottom-right" />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  url: state.url.url,
});
export default connect(mapStateToProps)(ResetPassword);
