import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import img1 from "../images/avecImg.jpg";
import "../style-sheets/loginStyle.css";
import { Form, Input, Button } from "antd";
import "antd/dist/antd.css";

import axios from "axios";
//redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { passwordReset } from "../global-state/actions/passwordAction";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { APILINK } from "../EndPoint";
// import bcrypt from "bcrypt"
const saltRounds = 10;
const axiosApiInstance = axios.create();

class ForgetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      BeErr: "",
    };
  }
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onSend = async (e) => {
    e.preventDefault();
    let request = {};

    request.email = this.state.email;

    const result = await axiosApiInstance
      .post(APILINK + "/request-reset-email/", request).catch(err=> {
        setTimeout(() => {
          toast.error("This email is invalid");
        }, 3000);
      })
      if(result){

        this.props.passwordReset(result.data.url);
        setTimeout(() => {
          toast.info("an email has been sent to you");
          this.props.history.push(`/login`);

        }, 3000);
      }

  };

  componentDidMount() {}

  render() {
    return (
      <div className="loginPage py-5">
        {/* <Container className="p-4"></Container> */}
        <Container className="loginContainer">
          <Row>
            <Col className="imgCol1" sm={6}>
              <div className="img-box">
                {" "}
                <img
                  alt="img-login"
                  src={img1}
                  className="img-fluid loginImg"
                />
              </div>
            </Col>
            <Col className="pl-5 text-left" sm={6}>
              <Form
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
                style={{
                  margin: "30px auto 0px auto",
                }}
                layout="vertical"
              >
                <h1 className="title1">Forgot Password</h1>

                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    { required: true, message: "Please input your Email!" },
                  ]}
                  layout="vertical"
                >
                  <Input
                    className="loginInput"
                    placeholder="Email"
                    onChange={this.handleChange}
                    type="email"
                    name="email"
                    value={this.state.email}
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    className="loginBtn1"
                    onClick={this.onSend}
                    type="primary"
                    htmlType="submit"
                  >
                    Send Email{" "}
                  </Button>
                </Form.Item>
                <Row>
                  <Col style={{ margin: "auto" }} sm={12}>
                    {this.state.BeErr !== "" ? (
                      <p style={{ color: "red", fontWeight: "bold" }}>
                        {this.state.BeErr}
                      </p>
                    ) : null}
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <Row></Row>
        </Container>
        <ToastContainer position="bottom-right" />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ passwordReset }, dispatch);
};
export default connect(null, mapDispatchToProps)(ForgetPassword);
