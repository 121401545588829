import React, { Component } from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import "../../style-sheets/training.css";
import ErrorHandler from "../../error-handler/ErrorHandler";
import axios from "axios";
import { APILINK } from "../../EndPoint";
import { Empty, Spin } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

const axiosApiInstance = axios.create();
class ViewAllTrainings extends Component {
  state = {
    loading: false,
    data: [],
  };
  componentDidMount = async () => {
    this.setState({ loading: true });
    let result;
    if (this.props.isSubject) {
      result = await axiosApiInstance.get(
        APILINK +
          `/create_training?subject_round_id=${this.props.match.params.id}`
      );
      console.log(result);
    } else {
      result = await axiosApiInstance.get(
        APILINK +
          `/create_training?course_round_id=${this.props.match.params.id}`
      );
      console.log(result);
    }
    this.setState({ data: result.data, loading: false });
  };
  render() {
    return (
      <div>
        <Container className="content-margin">
          <Row>
            <Col sm="12">
              <h4 style={{ color: "#124E68" }}>
                <i
                  onClick={() => this.props.history.goBack()}
                  className="fas fa-chevron-left pr-4"
                ></i>
                Course Training Questions
              </h4>
            </Col>
          </Row>

          <Row className="mt-5">
            {this.state.data.length !== 0 && this.state.loading === false ? (
              this.state.data.map((data) => {
                return (
                  <Col sm="12" md="3" className='my-2'>
                    <div className="trainCard">
                      <p>{data.title}</p>
                      <Link to={`/training_questions/${data.id}`}>
                        {" "}
                        <Button>Practices</Button>
                      </Link>
                    </div>
                  </Col>
                );
              })
            ) : this.state.loading ? (
              <Spin className="text-center" tip="...loading" />
            ) : (
              <Empty className="text-center" />
            )}
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isSubject: state.isSubject.isSubject,
});

export default connect(
  mapStateToProps,
  null
)(ErrorHandler(ViewAllTrainings, axiosApiInstance));
