import React, { Component } from "react";
import "../../style-sheets/createTutor.css";
import { Row, Col, Button, Form, Container } from "react-bootstrap";
import axios from "axios";
import { APILINK } from "../../EndPoint";
import ErrorHandler from "../../error-handler/ErrorHandler";
//redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { userRegister, LogOut } from "../../global-state/actions/authAction";
import { showNav } from "../../global-state/actions/showNavAction";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Select } from "antd";
// import bcrypt from "bcrypt"
let days = [];
let months = [];
let years = [];
const saltRounds = 10;

for (let i = 1; i <= 31; i++) {
  days.push(i);
}

for (let i = 1; i <= 12; i++) {
  months.push(i);
}
for (let i = 1940; i <= 2021; i++) {
  years.push(i);
}
const axiosApiInstance = axios.create();

class CreateTutor extends Component {
  state = {
    city: "",
    country: "",
    password: "",
    fullName: "",
    gradYear: "",
    gender: "",
    mobile: "",
    email: "",
    day: "1",
    month: "1",
    year: "1948",
    lname: "",
    selectedCountry: "",
    selectedCountryID: "",
    countries: [],
    disabled: false
  };

  generalFormHandler = () => {
    this.setState({ switchView: false });
    let dash = document.getElementById("dash");
    dash.style.transform = "translateX(0px)";
  };

  infoFormHandler = () => {
    this.setState({ switchView: true });
    let dash = document.getElementById("dash");
    dash.style.transform = "translateX(200px)";
  };
  handleDate = (e) => {
    console.log(e.target.value);
    this.setState({ [e.target.name]: e.target.value });
  };
  responseGoogle = (response) => {
    console.log(response);
  };

  componentDidMount() {
    this.props.showNav(false);
  }

  handleChange = (e) => {
    if (e.target.name == "city") {
      if (e.target.value.match(/^[a-zA-Z ]*$/)) {
        this.setState({ [e.target.name]: e.target.value });
      }
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  handleEmail = (e) => {
    this.setState({ [e.target.name]: e.target.value });

    if (
      e.target.value.match(
        /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/
      )
    ) {
      this.setState({ password: e.target.value });

      this.setState({ emailErr: "", disable: false });
    } else {
      this.setState({
        emailErr: "Enter valid email!",
        disable: true
      });
    }
  };
  handleNumber = (e) => {
    this.setState({ [e.target.name]: e.target.value });

    if (e.target.value.match(/^[0-9]{8,15}$/)) {
      this.setState({ mobile: e.target.value });

      this.setState({ phoneErr: "", disable: false });
    } else {
      this.setState({
        phoneErr: "Phone Number must be min 8 and max 15 numbers",
        disable: true
      });
    }
  };
  handlePassword = (e) => {
    this.setState({ [e.target.name]: e.target.value });

    if (
      e.target.value.match(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/
      )
    ) {
      this.setState({ password: e.target.value });

      this.setState({ passErr: "", disable: false });
    } else {
      this.setState({
        passErr:
          "password minimum 8 characters with at least one upper case and one lower case and number and no special charcters",
        disable: true
      });
    }
  };

  onCreate = async (e) => {
    this.setState({ disabled: true });

    e.preventDefault();
    let registerrequest = {};
    registerrequest.email = this.state.email;
    registerrequest.last_name = this.state.lname;
    registerrequest.password = this.state.password;
    registerrequest.full_name = this.state.fullName;
    registerrequest.city = this.state.city;
    registerrequest.country = this.state.country;
    registerrequest.mobile = this.state.mobile;
    registerrequest.user_type = "1";
    registerrequest.gender = this.state.gender;
    registerrequest.birth_date =
      this.state.year + "-" + this.state.month + "-" + this.state.day;

    const result = await axiosApiInstance
      .post(APILINK + "/tutor_signup", registerrequest)
      .then((res) => {
        setTimeout(() => {
          toast.success("Tutor Created Successfully");
        }, 2000);
        setTimeout(() => {
          this.props.history.push(`/view_tutors`);
        }, 3500);
      })
      .catch((err) => {
        this.setState({ disabled: false });
        console.log(err.response);
        for (const error in err.response.data.error) {
          console.log(err.response.data.error[error][0]);
          setTimeout(() => {
            toast.error(
              `${
                error +
                " : " +
                err.response.data.error[error][0].charAt(0).toUpperCase() +
                err.response.data.error[error][0].slice(1)
              }`
            );
          }, 500);
        }
      });
  };
  getCountries = async () => {
    let result = await axiosApiInstance.get(APILINK + "/get_countries");
    if (result) {
      console.log(result);
      this.setState({ countries: result.data });
    }
  };
  handleCountries = (v, e) => {
    console.log(e);
    this.setState({
      selectedCountry: e.value,
      selectedCountryID: e.id
    });
  };
  render() {
    return (
      <div>
        <Container className="tutor-form">
          <Col className="col-padding2 form" lg={12}>
            <h1 onClick={this.generalFormHandler} className="sub-header">
              Tutor Creation
            </h1>

            <div id="dash"></div>
            <React.Fragment>
              <Form>
                <Row>
                  <Col md="6" xs="12">
                    <Form.Group controlId="exampleForm.ControlInput2">
                      <Form.Label>
                        <strong style={{ color: "red", paddingRight: "5px" }}>
                          *
                        </strong>
                        First Name
                      </Form.Label>
                      <Form.Control
                        name="fullName"
                        onChange={this.handleChange}
                        value={this.state.fullName}
                        type="text"
                        placeholder="Your name"
                      />
                    </Form.Group>
                    <Form.Group controlId="exampleForm.ControlInput4">
                      <Form.Label>Country</Form.Label>
                      <Select
                        allowClear
                        placeholder="select country"
                        value={this.state.selectedCountry}
                        onChange={this.handleCountries}
                        style={{ width: "100%" }}
                        onClick={this.getCountries}
                      >
                        {this.state.countries.map((country) => (
                          <Select.Option id={country.id} value={country.name}>
                            {country.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Group>
                    <Form.Group controlId="exampleForm.ControlInput6">
                      <Form.Label>City</Form.Label>
                      <Form.Control
                        name="city"
                        onChange={this.handleChange}
                        value={this.state.city}
                        type="text"
                        placeholder="Your City"
                      />
                    </Form.Group>
                    <Form.Group controlId="exampleForm.ControlSelect1">
                      <Form.Label>Gender</Form.Label>
                      <Form.Control
                        name="gender"
                        value={this.state.gender}
                        onChange={this.handleChange}
                        as="select"
                      >
                        <option selected style={{ display: "none" }}>
                          I am a
                        </option>
                        <option value="1">Male</option>
                        <option value="2">Female</option>
                      </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="exampleForm.ControlSelect2">
                      <Form.Label>Date of Birth</Form.Label>
                      <Row>
                        <Col className="col-padding3" lg={4}>
                          <Form.Control
                            name="day"
                            value={this.state.day}
                            onChange={this.handleChange}
                            as="select"
                          >
                            {days.map((day) => {
                              return <option key={day}>{day}</option>;
                            })}
                          </Form.Control>
                        </Col>

                        <Col className="col-padding3" lg={4}>
                          <Form.Control
                            name="month"
                            value={this.state.month}
                            onChange={this.handleChange}
                            as="select"
                          >
                            {months.map((month) => {
                              return <option key={month}>{month}</option>;
                            })}
                          </Form.Control>
                        </Col>
                        <Col className="col-padding3" lg={4}>
                          <Form.Control
                            name="year"
                            value={this.state.year}
                            onChange={this.handleChange}
                            as="select"
                          >
                            {years.map((year) => {
                              return <option key={year}>{year}</option>;
                            })}
                          </Form.Control>
                        </Col>
                      </Row>
                    </Form.Group>
                  </Col>
                  <Col md="6" xs="12">
                    <Form.Group controlId="exampleForm.ControlInput3">
                      <Form.Label>
                        <strong style={{ color: "red", paddingRight: "5px" }}>
                          *
                        </strong>
                        Last Name
                      </Form.Label>
                      <Form.Control
                        onChange={this.handleChange}
                        value={this.state.lname}
                        name="lname"
                        type="text"
                        placeholder="Last Name"
                      />
                    </Form.Group>
                    <Form.Group controlId="exampleForm.ControlInput3">
                      <Form.Label>
                        <strong style={{ color: "red", paddingRight: "5px" }}>
                          *
                        </strong>
                        Email
                      </Form.Label>
                      <Form.Control
                        onChange={this.handleEmail}
                        value={this.state.email}
                        name="email"
                        type="email"
                        placeholder="Your email"
                      />
                      <p style={{ color: "red", fontWeight: "bold" }}>
                        {this.state.emailErr}
                      </p>
                    </Form.Group>
                    <Form.Group controlId="exampleForm.ControlInput4">
                      <Form.Label>
                        <strong style={{ color: "red", paddingRight: "5px" }}>
                          *
                        </strong>
                        Phone Number
                      </Form.Label>
                      <Form.Control
                        onChange={this.handleNumber}
                        name="mobile"
                        value={this.state.mobile}
                        type="number"
                        placeholder="Your number"
                      />
                      <p style={{ color: "red", fontWeight: "bold" }}>
                        {this.state.phoneErr}
                      </p>
                    </Form.Group>
                    <Form.Group controlId="exampleForm.ControlInput3">
                      <Form.Label>
                        <strong style={{ color: "red", paddingRight: "5px" }}>
                          *
                        </strong>
                        Password
                      </Form.Label>
                      <Form.Control
                        name="password"
                        onChange={this.handlePassword}
                        value={this.state.password}
                        type="password"
                      />
                      <p style={{ color: "red", fontWeight: "bold" }}>
                        {this.state.passErr}
                      </p>
                    </Form.Group>

                    <Button
                      disabled={this.state.disabled}
                      onClick={this.onCreate}
                      className="submit-btn"
                      type="submit"
                    >
                      Create
                    </Button>
                  </Col>
                </Row>
              </Form>
            </React.Fragment>
          </Col>
        </Container>
        <ToastContainer position="bottom-right" />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ userRegister, LogOut, showNav }, dispatch);
};

export default connect(
  null,
  mapDispatchToProps
)(ErrorHandler(CreateTutor, axiosApiInstance));
