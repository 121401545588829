import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { APILINK } from "../../EndPoint";
import { ToastContainer, toast } from "react-toastify";
import "../../style-sheets/createMaterial.css";
import { Select, Form, Button, Input, Upload } from "antd";

const { Option } = Select;
const axiosApiInstance = axios.create();
const CreateMaterials = (props) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [fileList, setFileList] = useState([]);
  const [subjectID, setSubjectID] = useState("");
  const [disabled, setDisabled] = useState(false);

  const onFinish = async (values) => {
    setDisabled(true);
    let eventdata;
    if (selectedOption == "1") {
      eventdata = new FormData();
      eventdata.append("data", fileList[0]);
      eventdata.append("title", values.title);
      eventdata.append("display_order", values.display_order);
      eventdata.append("subject_round_id", props.match.params.id);
      eventdata.append("subject", subjectID);
      if (fileList[0].type.includes("audio")) {
        eventdata.append("material_type", "1");
      } else {
        eventdata.append("material_type", "2");
      }
    }
    let result = await axiosApiInstance
      .post(
        APILINK + `/create_material`,
        selectedOption == "1"
          ? eventdata
          : {
              ...values,
              subject: subjectID,
              subject_round_id: props.match.params.id
            }
      )
      .catch((err) => {
        setDisabled(false);

        console.log(err.response);
        for (const error in err.response.data) {
          setTimeout(() => {
            toast.error(`${err.response.data[error][0]}`);
          }, 500);
        }
      });
    if (result) {
      setTimeout(() => {
        toast.info(`Course material has been created successfully`);
      }, 500);
      setTimeout(() => {
        props.history.push(`/view_materials/${props.match.params.id}`);
      }, 3000);
    }
  };
  const uploadprops = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      console.log(file.type);
      setFileList([file]);
      return false;
    },
    fileList
    
  };
  const getSubject = async () => {
    let result = await axiosApiInstance.get(
      APILINK + `/subject_round_details/${props.match.params.id}`
    );
    if (result.data) {
      setSubjectID(result.data.subject.id);
    }
  };
  useEffect(() => {
    getSubject();
  }, []);
  return (
    <div className="admin-home pb-5">
      <Container className="content-margin">
        <Row>
          <Col className="p-4 mb-4" style={{ textAlign: "left" }} sm={6}>
            <h2> Create Materials</h2>
          </Col>
        </Row>
        <Form onFinish={onFinish} layout="vertical">
          <Row>
            <Col sm="12" md={6}>
              <Form.Item
                label="Title"
                rules={[
                  {
                    required: true,
                    message: "This field is required"
                  }
                ]}
                name="title"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col sm="12" md={6}>
              <Form.Item
                label="Display Order"
                name="display_order"
                rules={[
                  {
                    required: true,
                    message: "This field is required"
                  }
                ]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col sm="12" md={6}>
              <Form.Item
                label="Upload Option"
                rules={[
                  {
                    required: true,
                    message: "This field is required"
                  }
                ]}
                name="option"
              >
                <Select
                  onChange={(e) => setSelectedOption(e)}
                  value={selectedOption}
                  placeholder="Select Upload Option"
                >
                  <Option value="1">Upload Material</Option>
                  <Option value="2">Drop Box uploaded</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col sm="12" md={6}>
              {selectedOption == "1" ? (
                <Form.Item label="Uploaded material here">
                  {" "}
                  <Upload {...uploadprops} multiple={false} maxCount={1}>
                    <Button>Click to Upload</Button>
                  </Upload>
                </Form.Item>
              ) : selectedOption == "2" ? (
                <Form.Item name="data_text" label="File Name">
                  <Input />
                </Form.Item>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <Button
                disabled={disabled}
                className="forumBtn1"
                htmlType="submit"
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
      <ToastContainer position="bottom-right" />
    </div>
  );
};

export default ErrorHandler(CreateMaterials, axiosApiInstance);
