import React, { Component } from "react";
import { Row, Col, Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import "../../style-sheets/studentCourses.css";
import axios from "axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import DataTable from "react-data-table-component";
import { APILINK } from "../../EndPoint";
import { Link } from "react-router-dom";
import { Pagination, Spin } from "antd";
import { bindActionCreators } from "redux";
import { getRoundID } from "../../global-state/actions/getRoundIDAction";
import { connect } from "react-redux";
import { isSubject } from "../../global-state/actions/isSubjectAction";
const axiosApiInstance = axios.create();

class StudentCourses extends Component {
  state = {
    loading: false,
    courses: [],
    count: 1,
    current: 1,
    columns: [
      {
        name: "Title",
        selector: "course.title",
        sortable: true,
        right: true,
      },
      {
        name: "Start Date",
        selector: "start_date.date",
        sortable: true,
        right: true,
      },
      {
        name: "End Date",
        
        selector: "end_date.date",
        sortable: true,
        right: true,
      },
      {
        name: "Actions",
        width:"100px",
        button: true,
        cell: (row) => (
          <div className="table-data-feature">
            <React.Fragment>
              <OverlayTrigger
                key={"2"}
                placement={"top"}
                overlay={
                  <Tooltip id={`tooltip-${"top"}`}>View Subjects</Tooltip>
                }
              >
                {this.props.user.user_type == "3" ? (
                  <Link
                    to={{pathname :`/course-details/${row.id}/${this.props.match.params.id}` ,data :row }}
                    
                  >
                    {" "}
                    <i
                      id={row.id}
                      onClick={this.getRoundid}
                      className="fas fa-eye pr-2"
                    ></i>
                  </Link>
                ) : (
                  <Link to={{pathname :`/course-details/${row.id}` , data : row}}>
                    {" "}
                    <i
                      id={row.id}
                      onClick={this.getRoundid}
                      className="fas fa-eye pr-2"
                    ></i>
                  </Link>
                )}
              </OverlayTrigger>
              <OverlayTrigger
                key={"2"}
                placement={"top"}
                overlay={
                  <Tooltip id={`tooltip-${"top"}`}>
                    View Training Questions
                  </Tooltip>
                }
              >
                <Link to={`/view_all_training/${row.id}`}>
                  {" "}
                  <i
                    id={row.id}
                    onClick={this.getRoundid}
                    className="fas fa-eye pr-2"
                  ></i>
                </Link>
              </OverlayTrigger>

              <OverlayTrigger
                key={"2"}
                placement={"top"}
                overlay={
                  <Tooltip id={`tooltip-${"top"}`}>
                   View Forum
                  </Tooltip>
                }
              >
                <Link to={`/view_round_fourm/${row.id}`}>
                  {" "}
                  <i
                   
                    className="fas fa-eye pr-2"
                  ></i>
                </Link>
              </OverlayTrigger>

            </React.Fragment>
          </div>
        ),
      },
    ],
    things: {
      idk: {
        createdAt: new Date(),
        title: "thing 1",
      },
      another: {
        createdAt: new Date("2010-10-10"),
        title: "thing 2",
      },
      more: {
        createdAt: new Date("2011-11-11"),
        title: "thing 3",
      },
    },
  };
  openCollapse = (e) => {
    console.log(e.target.value);
    this.setState((prevState) => {
      return { open: !prevState.open };
    });
  };

  myCourses = async () => {
    this.setState({ loading: true });
    let result;
    if (this.props.user.user_type === "3") {
      result = await axiosApiInstance.get(
        APILINK + `/view_course_round?student=${this.props.match.params.id}`
      );
    } else {
      result = await axiosApiInstance.get(
        APILINK + `/view_course_round?student=${this.props.user.id}`
      );
    }

    if (result) {
      this.setState({
        courses: result.data.data,
        loading: false,
        count: result.data.count,
      });
    }
  };

  componentDidMount() {
    this.myCourses();
  }

  onChange = (page) => {
    console.log(page);
    this.setState(
      {
        current: page,
      },
      async () => {
        this.setState({ loading: true });
        let result;
        if (this.props.user.user_type === "3") {
          result = await axiosApiInstance.get(
            APILINK +
              `/view_course_round?student=${this.props.match.params.id}&page=${this.state.current}`
          );
        } else {
          result = await axiosApiInstance.get(
            APILINK +
              `/view_course_round?student=${this.props.user.id}&page=${this.state.current}`
          );
        }

        if (result) {
          this.setState({
            courses: result.data.data,
            loading: false,
            count: result.data.count,
          });
        }
      }
    );
  };
  getRoundid = (e) => {
    this.props.getRoundID(e.target.id);
    this.props.isSubject(false);
  };

  render() {
    return (
      <Container className="content-margin">
        {this.state.loading ? (
          <Spin className="spinElement" tip="..loading" />
        ) : (
          <React.Fragment>
            <Row>
              <Col lg={12} md={12}>
                <h1 className="course-title">Enrolled Courses</h1>
                <DataTable
                  columns={this.state.columns}
                  data={this.state.courses}
                />
              </Col>
            </Row>
            <Row className="p-4">
              {this.state.courses.length !== 0 ? (
                <Pagination
                  onChange={this.onChange}
                  defaultCurrent={this.state.current}
                  total={this.state.count}
                  current={this.state.current}
                />
              ) : null}
            </Row>
          </React.Fragment>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  isAuth: state.auth.isAuth,
});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getRoundID, isSubject }, dispatch);
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ErrorHandler(StudentCourses, axiosApiInstance));
