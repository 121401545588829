import React, { Component } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import axios from "axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { APILINK } from "../../EndPoint";
import { ToastContainer, toast } from "react-toastify";
import { DatePicker } from "antd";
import "../../style-sheets/createCourse.css";

const axiosApiInstance = axios.create();
class EditCourse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      desc: "",
      cost: "",
      policy: "1",
      startDate: "",
      endDate: "",
      disabled: false,
      selectedFile: null,
      image: "",
      fileName: ""
    };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  EditCourse = async () => {
    this.setState({ disabled: true });
    let result;
    let eventdata;

    if (this.state.selectedFile == null) {
      let data = {
        title: this.state.title,
        description: this.state.desc,
        installment_policy: this.state.policy,
        display_order: this.state.order,
        installments_cost: this.state.iCost,
        cost: this.state.cost
      };
      console.log(data);
      result = await axiosApiInstance.put(
        APILINK + `/update_courses/${this.props.match.params.id}`,
        data
      );
    } else {
      eventdata = new FormData();
      eventdata.append("title", this.state.title);
      eventdata.append("description", this.state.desc);
      eventdata.append("installment_policy", this.state.policy);
      eventdata.append("image", this.state.selectedFile);
      eventdata.append("display_order", this.state.order);
      eventdata.append("cost", this.state.cost);
      eventdata.append("installments_cost", this.state.iCost);
      result = await axiosApiInstance.put(
        APILINK + `/update_courses/${this.props.match.params.id}`,
        eventdata
      );
    }

    if (result) {
      console.log(result);
      setTimeout(() => {
        toast.info(`Course has been updated Successfully`);
      }, 500);
      setTimeout(() => {
        this.props.history.push("/admin_courses");
      }, 3000);
      console.log(result);
    }
  };

  handleStartDate = (date, dateString) => {
    // console.log(date, dateString);
    this.setState({ startDate: dateString });
  };
  handleEndDate = (date, dateString) => {
    // console.log(date, dateString);
    this.setState({ endDate: dateString });
  };
  getCourse = async () => {
    let result = await axiosApiInstance.get(
      APILINK + `/course_details/${this.props.match.params.id}`
    );
    this.setState({
      title: result.data.title,
      desc: result.data.description,
      policy: result.data.installment_policy,
      image: result.data.image,
      order: result.data.display_order,
      iCost: result.data.installments_cost,
      cost: result.data.cost
    });
  };
  componentDidMount() {
    this.getCourse();
  }
  onFileUpload = (e) => {
    e.preventDefault();

    this.setState({
      selectedFile: e.target.files[0],
      fileName: e.target.files[0].name
    });
  };
  render() {
    return (
      <div className="admin-home pb-5">
        <Container className="content-margin">
          <Form id="infoForm">
            <Row>
              <Col sm={6}>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Course Title</Form.Label>
                  <Form.Control
                    name="title"
                    value={this.state.title}
                    onChange={this.handleChange}
                    type="text"
                    placeholder="Course Title"
                    maxlength="60"
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Course Description</Form.Label>
                  <Form.Control
                    name="desc"
                    value={this.state.desc}
                    onChange={this.handleChange}
                    type="text"
                    placeholder="Course Description"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group controlId="exampleForm.ControlInput">
                  <Form.Label>Installment Policy</Form.Label>
                  <Form.Control
                    name="policy"
                    value={this.state.policy}
                    onChange={this.handleChange}
                    as="select"
                  >
                    <option selected disabled style={{ display: "none" }}>
                      Choose installment policy
                    </option>
                    <option value="1">Half/Full </option>
                    <option value="2">Full</option>
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group controlId="exampleForm.ControlInput2">
                  <Form.Label>Display Order</Form.Label>
                  <Form.Control
                    name="order"
                    value={this.state.order}
                    onChange={this.handleChange}
                    type="number"
                    placeholder="Display Order"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm="6">
                <Row>
                  <Col style={{ textAlign: "left" }} sm="6">
                    <img
                      className="courseImg"
                      src={APILINK + this.state.image}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="2"></Col>
                  <Col sm="8">
                    <Form.Group controlId="exampleForm.ControlInput3">
                      <Form.Label>upload new Image</Form.Label>
                      <div class="wrapper">
                        <div class="file-upload">
                          <input onChange={this.onFileUpload} type="file" />
                          <i class="fa fa-plus"></i>
                        </div>
                      </div>{" "}
                      <p>{this.state.fileName}</p>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col sm="12" md="6">
                <Form.Label>Installment Cost</Form.Label>
                <Form.Control
                  name="iCost"
                  value={this.state.iCost}
                  onChange={this.handleChange}
                  type="number"
                  placeholder="Display Order"
                />
                <Form.Label>Course Cost</Form.Label>
                <Form.Control
                  name="cost"
                  value={this.state.cost}
                  onChange={this.handleChange}
                  type="number"
                  placeholder="Display Order"
                />
              </Col>
            </Row>
          </Form>
          <Row className="mt-3">
            <Col className="text-right" sm="12">
              <Button
                className="forumBtn1"
                disabled={this.state.disabled}
                onClick={this.EditCourse}
              >
                Edit Course
              </Button>
            </Col>
          </Row>
        </Container>
        <ToastContainer position="bottom-right" />
      </div>
    );
  }
}

export default ErrorHandler(EditCourse, axiosApiInstance);
