import React, { Component } from "react";
import { ListGroupItem, Collapse } from "reactstrap";
import { Button, Container, Row, Col, Modal } from "react-bootstrap";
import Switch from "react-switch";
import axios from "axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { APILINK } from "../../EndPoint";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { CardBody, Card } from "reactstrap";
import { Input } from "antd";
const axiosApiInstance = axios.create();

class QuestionReplies extends Component {
  state = {
    isOpen: false,
    showInput: false,
  };
  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };
  showInput = (e) => {
    this.setState({ showInput: !this.state.showInput });
  };
  addReply = async (e) => {
    let data = {
      question_id: this.props.questionID,
      answer_details: this.state.reply,
      parent_id: this.props.id,
    };
    let result = await axiosApiInstance
      .post(APILINK + `/create_forum_answer`, data)
      .then((res) => {
        this.setState({ showInput: false, answer: "" });
        this.props.updateList();
      });
    console.log(data);
  };
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  openWarning = (e) => {
    this.setState({ showWarningMsg: true, answerID: e.target.id });
  };
  closeWarning = () => {
    this.setState({ showWarningMsg: false });
  };

  deleteAnswer = async (e) => {
    let result = await axiosApiInstance.delete(
      APILINK + `/edit_forum_answer/${this.state.answerID}`
    );
    console.log(result);
    if (result.data == "") {
      this.setState({ showWarningMsg: false });
      this.props.updateList();
    }
  };

  render() {
    console.log(this.props);
    return (
      <div>
        {this.props.id !== "" ? (
          <React.Fragment>
            {" "}
            {this.props.level == 0 ? (
              <React.Fragment>
                <div
                  color="primary"
                  style={{ marginBottom: "1rem" }}
                  className="questionDiv2"
                >
                  <p style={{ cursor: "pointer" }} onClick={this.toggle}>
                    {this.props.mainAnswers}{" "}
                    {this.state.isOpen ? (
                      <i class="fas fa-sort-up"></i>
                    ) : (
                      <i class="fas fa-sort-down"></i>
                    )}
                  </p>
                  <Button
                    id={this.props.id}
                    className="replyBtn"
                    onClick={this.showInput}
                  >
                    Reply
                  </Button>
                  {this.props.user.id == this.props.author.id ||
                  this.props.user.user_type == "3" ? (
                    <Button
                      id={this.props.id}
                      onClick={this.openWarning}
                      className="replyBtn"
                    >
                      Delete
                    </Button>
                  ) : null}

                  {this.state.showInput ? (
                    <Row className="mb-2">
                      <Col sm="10">
                        <textarea
                          rows={6}
                          name="reply"
                          value={this.state.reply}
                          placeholder="Add your reply..."
                          onChange={this.handleChange}
                        />
                      </Col>
                      <Col className="text-left" sm="2">
                        {" "}
                        <i
                          onClick={this.addReply}
                          class="fas fa-paper-plane"
                        ></i>
                      </Col>
                    </Row>
                  ) : null}
                </div>
                {this.props.replies.length !== 0 ? (
                  this.props.replies.map((reply) => {
                    return (
                      <Collapse isOpen={this.state.isOpen}>
                        <Card>
                          <CardBody>
                            <div className="repliesCard">
                              {reply.answer_details}

                              {this.props.user.id == reply.author.id ||
                              this.props.user.user_type == "3" ? (
                                <i
                                  id={reply.id}
                                  onClick={this.openWarning}
                                  class="fas fa-trash"
                                ></i>
                              ) : null}
                            </div>
                          </CardBody>
                        </Card>
                      </Collapse>
                    );
                  })
                ) : (
                  <Card>
                    <Collapse isOpen={this.state.isOpen}>
                      <Card>
                        {" "}
                        <CardBody>
                          {" "}
                          <div className="repliesCard">
                            <p>No Replies yet</p>
                          </div>{" "}
                        </CardBody>
                      </Card>
                    </Collapse>
                  </Card>
                )}
              </React.Fragment>
            ) : null}
          </React.Fragment>
        ) : null}

        <Modal show={this.state.showWarningMsg} onHide={this.closeWarning}>
          <Container className="p-4">
            <Row>
              <Col className="text-center" sm={12}>
                {" "}
                <h6>Are u sure u want to delete this Answer?</h6>
              </Col>
              <Row style={{ margin: "auto" }}>
                <Col md={12}>
                  <Button
                    onClick={this.deleteAnswer}
                    className="forumBtn1 mx-3"
                  >
                    Delete
                  </Button>
                  <Button className="forumBtn1" onClick={this.closeWarning}>
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Row>
          </Container>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(
  mapStateToProps,
  null
)(ErrorHandler(QuestionReplies, axiosApiInstance));
