import React, { Component } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import axios from "axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { APILINK } from "../../EndPoint";
import { ToastContainer, toast } from "react-toastify";
import { Select } from "antd";
import "../../style-sheets/createCourse.css";

const axiosApiInstance = axios.create();
class CreateSpecialityForum extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      desc: "",
      public: false,
      specialities: [
        { id: "1", name: "Pet animals " },
        { id: "2", name: "Cattle" },
        { id: "3", name: "Avian" },
        { id: "4", name: "Camels" },
        { id: "5", name: "Swine" },
        { id: "6", name: "Ovine" },
        { id: "7", name: "Caprine" },
        { id: "8", name: "Fish hygiene" },
        { id: "9", name: "Wild animals" },
        { id: "10", name: "Veterinary Pharmaceuticals" },
        { id: "11", name: "Food hygiene" },
        { id: "12", name: "Laboratory medicine" },
        { id: "13", name: "Other" },
      ],
      selectedSpeciality: null,
      selectedSpecialityID: "",
      disabled: false,
    };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  CreateForum = async () => {
    if (this.state.title == "" || this.state.selectedSpeciality == "") {
      setTimeout(() => {
        toast.error(`Please Fill out the required fields !`);
      }, 500);
    } else {
      this.setState({ disabled: true });

      let data = {
        title: this.state.title,
        description: this.state.desc,
        speciality: this.state.selectedSpecialityID,
        is_public: true,
      };

      let result = await axiosApiInstance.post(APILINK + "/create_forum", data);

      if (result) {
        console.log(result);
        setTimeout(() => {
          toast.info(`Forum Added Successfully`);
        }, 500);
        setTimeout(() => {
          this.props.history.push(`/speciality_forums`);
        }, 3000);
      }
    }
  };

  handleSpecialities = (v, e) => {
    console.log(e);
    this.setState({
      selectedSpeciality: e.value,
      selectedSpecialityID: e.id,
    });
  };
  render() {
    return (
      <div className="admin-home pb-5">
        <Container className="content-margin">
          <h3 style={{ color: "#124E68" }}>Create Forum</h3>
          <Form id="infoForm">
            <Row>
              <Col sm={6}>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>
                    {" "}
                    <strong style={{ color: "red", paddingRight: "5px" }}>
                      *
                    </strong>
                    Forum Title
                  </Form.Label>
                  <Form.Control
                    name="title"
                    value={this.state.title}
                    onChange={this.handleChange}
                    type="text"
                    placeholder="Forum Title"
                    maxlength="60"

                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group controlId="exampleForm.ControlInput2">
                  <Form.Label>Forum Description</Form.Label>
                  <Form.Control
                    name="desc"
                    value={this.state.desc}
                    onChange={this.handleChange}
                    type="text"
                    placeholder="Forum Description"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm="6">
                <Form.Label>
                  {" "}
                  <strong style={{ color: "red", paddingRight: "5px" }}>
                    *
                  </strong>
                  Select Speciality
                </Form.Label>

                <Select
                  allowClear
                  placeholder="Select Speciality"
                  value={this.state.selectedSpeciality}
                  onChange={this.handleSpecialities}
                  style={{ width: "100%" }}
                  showSearch
                >
                  {this.state.specialities.map((speciality) => (
                    <Select.Option id={speciality.id} value={speciality.name}>
                      {speciality.name}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            </Row>
          </Form>
          <Row className="mt-3">
            <Col sm="12">
              <Button
                disabled={this.state.disabled}
                className="forumBtn1"
                onClick={this.CreateForum}
              >
                Create Forum
              </Button>
            </Col>
          </Row>
        </Container>
        <ToastContainer position="bottom-right" />
      </div>
    );
  }
}

export default ErrorHandler(CreateSpecialityForum, axiosApiInstance);
