import React, {Component} from "react";
import "../style-sheets/register.css";
import {Row, Col, Button, Form, Container, Modal} from "react-bootstrap";
import avec from "../images/avecImg.jpg";
//redux
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {userRegister, LogOut} from "../global-state/actions/authAction";
import {showNav} from "../global-state/actions/showNavAction";
import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {APILINK} from "../EndPoint";
import axios from "axios";
import {Select, Checkbox, DatePicker, Input} from "antd";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import ReactSelect from "react-select";
import countryList from "react-select-country-list";
import PasswordInput from "./common/PasswordInput";

const {Option} = Select;

// import bcrypt from "bcrypt"
let days = [];
let months = [];
let years = [];
const saltRounds = 10;

for (let i = 1; i <= 31; i++) {
    days.push(i);
}

for (let i = 1; i <= 12; i++) {
    months.push(i);
}
for (let i = 1940; i <= 2021; i++) {
    years.push(i);
}

class Register extends Component {
    state = {
        staffSpeciality: "",
        university: "",
        city: "",
        workPlace: "",
        selectedCountry: null,
        password: "",
        confirmPassword: "",
        fullName: "",
        lastName: "",
        gradYear: "",
        gradYearDate: undefined,
        gender: "",
        mobile: "",
        email: "",
        employment: "",
        college: "",
        day: "1",
        month: "1",
        year: "1948",
        employeesNumber: "",
        businessType: "",
        switchView: false,
        showStaffMember: false,
        showPostGraduate: false,
        postGrade: [{certificate_type: "", speciality: ""}],
        passErr: "",
        emailErr: "",
        phoneErr: "",
        countries: [],
        selectedCountryID: "",
        unis: [],
        selectedUni: "",
        selectedUniID: "",
        colleges: [],
        selectedCollege: null,
        selectedCollegeID: "",
        mobileModal: false,
        loginData: {},
        confirmErr: "",
        showErr: "",
        selectedCountryRes: [],
        selectedCountryResID: [],
        emailNeeded: false,
        email2: "",
        phone: ""
    };
    close = () => {
        this.setState({mobileModal: false});
    };
    generalFormHandler = () => {
        this.setState({switchView: false});
        let dash = document.getElementById("dash");
        dash.style.transform = "translateX(0px)";
    };

    infoFormHandler = () => {
        this.setState({switchView: true});
        let dash = document.getElementById("dash");
        dash.style.transform = "translateX(200px)";
    };
    handleDate = (e, index = null) => {
        console.log(e.target.value);
        this.setState({[e.target.name]: e.target.value});
    };

    componentDidMount() {
        const options = countryList().getData();
        this.setState({options: options});
        this.props.showNav(false);
    }

    handleChange = (e) => {
        if (e.target.name == "city") {
            if (e.target.value.match(/^[a-zA-Z ]*$/)) {
                this.setState({[e.target.name]: e.target.value});
            }
        } else {
            this.setState({[e.target.name]: e.target.value});
        }
    };
    handleEmail = (e) => {
        this.setState({[e.target.name]: e.target.value});

        if (
            e.target.value.match(
                /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/
            )
        ) {
            this.setState({email: e.target.value});

            this.setState({emailErr: "", disable: false});
        } else {
            this.setState({
                emailErr: "Enter valid email!",
                disable: true
            });
        }
    };
    handleNumber = (e) => {
        this.setState({[e.target.name]: e.target.value});

        if (e.target.value.match(/^[0-9]{8,15}$/)) {
            this.setState({mobile: e.target.value});

            this.setState({phoneErr: "", disable: false});
        } else {
            this.setState({
                phoneErr: "Phone Number must be min 8 and max 15 numbers",
                disable: true
            });
        }
    };
    handlePassword = (e) => {
        this.setState({[e.target.name]: e.target.value});

        if (
            e.target.value.match(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/
            )
        ) {
            this.setState({password: e.target.value});

            this.setState({passErr: "", disable: false});
        } else {
            this.setState({
                passErr:
                    "password minimum 8 characters with at least one upper case and one lower case and number and no special charcters",
                disable: true
            });
        }
    };

    handleSpeciality2 = async (e) => {
        console.log(e);
    };

    handleIsWorking = (e) => {
        this.setState({isWorking: e.target.checked});
    };
    handleStaff = (e) => {
        console.log(e.target.checked);
        this.setState({showStaffMember: e.target.checked});
    };

    handlePostGrad = (e) => {
        console.log(e.target.checked);

        this.setState({showPostGraduate: e.target.checked});
    };

    handleAddClick = () => {
        let postGradeCopy = [...this.state.postGrade];
        this.setState(
            {
                postGrade: [...postGradeCopy, {certificate_type: "", speciality: ""}]
            },
            () => {
                console.log(this.state.postGrade);
            }
        );
    };

    handlePostGradDropDown = (e, index) => {
        const {name, value} = e.target;

        const list = [...this.state.postGrade];
        console.log(index);
        console.log(list);
        list[index][name] = value;
        console.log(list);
        // this.setState({postGrade : list},()=>{
        //   console.log(this.state.postGrade)
        // })
    };

    handleGradYear = (date, dateString) => {
        this.setState({gradYearDate: date});
        this.setState({gradYear: dateString});
    };
    onRegister = async (e) => {
        e.preventDefault();
        let registerrequest = {};
        if (
            this.state.fullName == "" ||
            this.state.lastName == "" ||
            this.state.email == "" ||
            this.state.password == "" ||
            this.state.gradYear == "" ||
            this.state.selectedCountry == "" ||
            this.state.city == "" ||
            this.state.selectedUni == ""
        ) {
            this.setState({showErr: "Please Fill out all required fields first"});
        } else {
            this.setState({showErr: ""});

            if (this.state.showPostGraduate) {
                // const salt = bcrypt.genSaltSync(saltRounds);
                // const hash = bcrypt.hashSync(this.state.password, salt);
                registerrequest.email = this.state.email;
                registerrequest.password = this.state.password;
                registerrequest.full_name = this.state.fullName;
                registerrequest.last_name = this.state.lastName;
                registerrequest.employment_type = this.state.employment;
                registerrequest.business_type = this.state.businessType;
                registerrequest.no_of_employees = this.state.employeesNumber;
                registerrequest.speciality = this.state.staffSpeciality;
                registerrequest.grad_year = this.state.gradYear;
                registerrequest.university = this.state.selectedUni;
                registerrequest.city = this.state.city;
                registerrequest.country = this.state.selectedCountry;
                registerrequest.mobile = this.state.mobile;
                registerrequest.college = this.state.selectedCollege;
                registerrequest.gender = this.state.gender;
                registerrequest.institution_mail = this.state.institutionalEmail;
                registerrequest.user_type = "2";
                registerrequest.is_social = false;
                registerrequest.post_grad_study = this.state.postGrade;
                registerrequest.country_residence = this.state.selectedCountryRes;
                registerrequest.birth_date =
                    this.state.year + "-" + this.state.month + "-" + this.state.day;
            } else {
                registerrequest.email = this.state.email;
                registerrequest.password = this.state.password;
                registerrequest.full_name = this.state.fullName;
                registerrequest.last_name = this.state.lastName;
                registerrequest.employment_type = this.state.employment;
                registerrequest.business_type = this.state.businessType;
                registerrequest.no_of_employees = this.state.employeesNumber;
                registerrequest.speciality = this.state.staffSpeciality;
                registerrequest.grad_year = this.state.gradYear;
                registerrequest.university = this.state.selectedUni;
                registerrequest.city = this.state.city;
                registerrequest.country = this.state.selectedCountry;
                registerrequest.mobile = this.state.mobile;
                registerrequest.college = this.state.college;
                registerrequest.gender = this.state.gender;
                registerrequest.institution_mail = this.state.institutionalEmail;
                registerrequest.user_type = "2";
                registerrequest.is_social = false;
                registerrequest.country_residence = this.state.selectedCountryRes;
                registerrequest.birth_date =
                    this.state.year + "-" + this.state.month + "-" + this.state.day;
            }

            console.log(registerrequest);
            const userData = await this.props.userRegister(
                registerrequest,
                this.props.history
            );
            // console.log(userData);
            if (userData) {
                console.log(userData);

                this.setState({BeErr: userData});

                if (this.state.BeErr.error) {
                    for (const error in userData.error) {
                        console.log(userData.error[error][0]);
                        setTimeout(() => {
                            toast.error(
                                `${
                                    error +
                                    " : " +
                                    userData.error[error][0].charAt(0).toUpperCase() +
                                    userData.error[error][0].slice(1)
                                }`
                            );
                        }, 500);
                    }
                } else {
                    setTimeout(() => {
                        toast.success("User Created Successfully");
                    }, 500);
                    this.props.history.push("/login");
                }
            }
        }
    };

    getCountries = () => {
        let result = axios.get(APILINK + "/get_countries").then((res) => {
            this.setState({countries: res.data});
            console.log(res.data);
        });
    };
    handleCountries = (v, e) => {
        console.log(e);
        this.setState({
            selectedCountry: e.value,
            selectedCountryID: e.id
        });
    };
    handleCountriesRes = (v, e) => {
        console.log(e);
        this.setState({
            selectedCountryRes: e.value,
            selectedCountryResID: e.id
        });
    };

    // handleCountriesRes = (value) => {
    //   console.log(value.label);
    //   this.setState({ selectedCountryRes: value.label });
    // };

    getUnis = () => {
        let result = axios
            .get(APILINK + `/get_schools/${this.state.selectedCountryID}`)
            .then((res) => {
                let sorted = res.data.sort((a, b) => {
                    let fa = a.name.toLowerCase(),
                        fb = b.name.toLowerCase();

                    if (fa < fb) {
                        return -1;
                    }
                    if (fa > fb) {
                        return 1;
                    }
                    return 0;
                });
                this.setState({unis: sorted});
            });
    };
    handleUni = (v, e) => {
        console.log(e);
        this.setState({
            selectedUni: e.value,
            selectedUniID: e.id
        });
    };

    getColleges = async () => {
        let result = axios.get(APILINK + `/get_schools`).then((res) => {
            let sorted = res.data.sort((a, b) => {
                let fa = a.name.toLowerCase(),
                    fb = b.name.toLowerCase();

                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
                return 0;
            });
            this.setState({colleges: sorted});
        });
    };
    handleCollege = (v, e) => {
        console.log(e);
        this.setState({
            selectedCollege: e.value,
            selectedCollegeID: e.id
        });
    };
    responseGoogle = (response) => {
        console.log(response.profileObj.email);
        let res = response.profileObj;
        this.regGoogle(res);
    };

    regGoogle = (response) => {
        let registerrequest = {
            email: response.email,
            is_social: true,
            full_name: response.givenName,
            last_name: response.familyName,
            social_provider: "google",
            social_id: response.googleId
        };

        this.setState({loginData: registerrequest, mobileModal: true});
    };

    responseFacebook = (response) => {
        console.log(response);
        let res = response;
        this.regFb(res);
    };
    regFb = (response) => {
        let name = response.name.split(" ");
        let registerrequest = {
            full_name: name[0],
            is_social: true,
            last_name: name[1],
            email: response.email,
            social_id: response.id,
            social_provider: "facebook"
        };

        if (response.email) {
            this.setState({loginData: registerrequest, mobileModal: true});
        } else {
            setTimeout(() => {
                toast.error(
                    "Sorry , we can't complete your facebook registration due to account issues , please try registartion with another way"
                );
            }, 500);
        }
    };

    handleConfirmPassword = (e) => {
        this.setState({confirmPassword: e.target.value})
        if (e.target.value !== this.state.password) {
            this.setState({confirmErr: "The password confirmation doesn't match!"});
        } else {
            this.setState({confirmErr: ""});
        }
    };

    render() {
        // console.log(this.state.employment + " : " +typeof(this.state.employment))
        return (
            <div className="registerPage py-5">
                <Container className="registerContainer">
                    <Row>
                        <Col className="imgCol1" lg={6} md={6}>
                            <div className="img-box">
                                <img alt="registerImg" className="avec" src={avec}></img>
                            </div>
                        </Col>
                        <Col className="col-padding2 form" lg={6} md={6}>
                            <h1 className="heading">Sign Up</h1>
                            <h2 onClick={this.generalFormHandler} className="sub-header">
                                Personal Info
                            </h2>
                            <h2 onClick={this.infoFormHandler} className="sub-header ml-5">
                                Other Info
                            </h2>
                            <div id="dash"></div>
                            {this.state.switchView ? (
                                <React.Fragment>
                                    <Form id="infoForm">
                                        <Form.Group controlId="exampleForm.ControlInput1">
                                            <Form.Label>
                                                {" "}
                                                <strong style={{color: "red", paddingRight: "5px"}}>
                                                    *
                                                </strong>
                                                Year of Graduation
                                            </Form.Label>
                                            <DatePicker
                                                value={this.state.gradYearDate}
                                                className="form-control"
                                                onChange={this.handleGradYear}
                                                picker="year"
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="exampleForm.ControlInput4">
                                            <Form.Label>
                                                {" "}
                                                <strong style={{color: "red", paddingRight: "5px"}}>
                                                    *
                                                </strong>
                                                Country
                                            </Form.Label>

                                            <Select
                                                showSearch
                                                allowClear
                                                placeholder="select country"
                                                value={this.state.selectedCountry}
                                                onChange={this.handleCountries}
                                                style={{width: "100%"}}
                                                onClick={this.getCountries}
                                            >
                                                {this.state.countries.map((country) => (
                                                    <Select.Option id={country.id} value={country.name}>
                                                        {country.name}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Group>

                                        <Form.Group controlId="exampleForm.ControlInput8">
                                            <Form.Label>
                                                {" "}
                                                <strong style={{color: "red", paddingRight: "5px"}}>
                                                    *
                                                </strong>
                                                University
                                            </Form.Label>
                                            <Select
                                                allowClear
                                                showSearch
                                                placeholder="select Uni"
                                                value={this.state.selectedUni}
                                                onChange={this.handleUni}
                                                style={{width: "100%"}}
                                                onClick={this.getUnis}
                                            >
                                                {this.state.selectedCountry ? (
                                                    this.state.unis.map((uni) => (
                                                        <Select.Option id={uni.id} value={uni.name}>
                                                            {uni.name}
                                                        </Select.Option>
                                                    ))
                                                ) : (
                                                    <Select.Option>Select country first</Select.Option>
                                                )}
                                            </Select>
                                        </Form.Group>
                                        <Checkbox checked={this.state.showStaffMember ?? false} onChange={this.handleStaff}>
                                            Are you a staff member?
                                        </Checkbox>
                                        {this.state.showStaffMember ? (
                                            <React.Fragment>
                                                <Form.Group controlId="exampleForm.ControlInput">
                                                    <Form.Label>Veterinary College</Form.Label>
                                                    <Select
                                                        showSearch
                                                        allowClear
                                                        placeholder="Select College"
                                                        value={this.state.selectedCollege}
                                                        onChange={this.handleCollege}
                                                        style={{width: "100%"}}
                                                        onClick={this.getColleges}
                                                    >
                                                        {this.state.colleges.map((college) => (
                                                            <Select.Option
                                                                id={college.id}
                                                                value={college.name}
                                                            >
                                                                {college.name}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Group>
                                                <Form.Group controlId="exampleForm.ControlInput5">
                                                    <Form.Label>Speciality</Form.Label>
                                                    <Form.Control
                                                        name="staffSpeciality"
                                                        value={this.state.staffSpeciality}
                                                        onChange={this.handleChange}
                                                        as="select"
                                                    >
                                                        <option selected style={{display: "none"}}>
                                                            Choose Speciality
                                                        </option>

                                                        <option>Anatomy and embryology</option>
                                                        <option>Animal breeding and production</option>
                                                        <option>Animal behavior and management</option>
                                                        <option>
                                                            Animal poultry and environmental hygiene
                                                        </option>
                                                        <option>Avian and rabbit diseases</option>

                                                        <option>Biochemistry</option>
                                                        <option>Biostatistics</option>
                                                        <option>Bacteriology</option>
                                                        <option>Fish diseases and management</option>
                                                        <option>Forensic medicine and toxicology</option>
                                                        <option>Genetics</option>
                                                        <option>Gynecology</option>
                                                        <option>Histology</option>
                                                        <option>Immunity</option>
                                                        <option>Infectious diseases</option>
                                                        <option>Meat hygiene</option>
                                                        <option>Milk hygiene</option>
                                                        <option>Nutrition</option>
                                                        <option>Pathology</option>
                                                        <option>Parasitology</option>
                                                        <option>Pharmacology</option>
                                                        <option>Physiology</option>
                                                        <option>Radiology</option>
                                                        <option>Surgery and anesthesia</option>
                                                        <option>
                                                            Veterinary economics and farm management
                                                        </option>
                                                        <option>Virology</option>
                                                        <option>Zoonoses</option>
                                                    </Form.Control>
                                                </Form.Group>
                                                <Form.Group controlId="exampleForm.ControlInput3">
                                                    <Form.Label>Institutional Email</Form.Label>
                                                    <Form.Control
                                                        onChange={this.handleChange}
                                                        value={this.state.institutionalEmail}
                                                        name="institutionalEmail"
                                                        type="email"
                                                        placeholder="Institutional Email"
                                                    />
                                                </Form.Group>
                                            </React.Fragment>
                                        ) : null}
                                        <Checkbox checked={this.state.showPostGraduate ?? false} onChange={this.handlePostGrad}>
                                            Do you have post graduate studies?
                                        </Checkbox>
                                        {this.state.showPostGraduate ? (
                                            <React.Fragment>
                                                {this.state.postGrade.map((study, i) => {
                                                    return (
                                                        <React.Fragment key={i}>
                                                            <Form.Group controlId="exampleForm.ControlInput">
                                                                <Form.Label>Degree</Form.Label>
                                                                <Form.Control
                                                                    name="certificate_type"
                                                                    value={this.state.certificate_type}
                                                                    onChange={(e) =>
                                                                        this.handlePostGradDropDown(e, i)
                                                                    }
                                                                    as="select"
                                                                >
                                                                    <option selected style={{display: "none"}}>
                                                                        Choose your degree
                                                                    </option>
                                                                    <option value="1">Diploma</option>
                                                                    <option value="2">Master</option>
                                                                    <option value="3">PHD</option>
                                                                </Form.Control>
                                                            </Form.Group>
                                                            <Form.Group controlId="exampleForm.ControlInput5">
                                                                <Form.Label>Speciality</Form.Label>
                                                                <Form.Control
                                                                    name="speciality"
                                                                    value={this.state.speciality}
                                                                    onChange={(e) =>
                                                                        this.handlePostGradDropDown(e, i)
                                                                    }
                                                                    as="select"
                                                                >
                                                                    <option selected style={{display: "none"}}>
                                                                        Choose Speciality
                                                                    </option>

                                                                    <option>Anatomy and embryology</option>
                                                                    <option>
                                                                        Animal breeding and production
                                                                    </option>
                                                                    <option>
                                                                        Animal behavior and management
                                                                    </option>
                                                                    <option>
                                                                        Animal poultry and environmental hygiene
                                                                    </option>
                                                                    <option>Avian and rabbit diseases</option>

                                                                    <option>Biochemistry</option>
                                                                    <option>Biostatistics</option>
                                                                    <option>Bacteriology</option>
                                                                    <option>Fish diseases and management</option>
                                                                    <option>
                                                                        Forensic medicine and toxicology{" "}
                                                                    </option>
                                                                    <option>Genetics</option>
                                                                    <option>Gynecology</option>
                                                                    <option>Histology</option>
                                                                    <option>Immunity</option>
                                                                    <option>Infectious diseases</option>
                                                                    <option>Meat hygiene</option>
                                                                    <option>Milk hygiene</option>
                                                                    <option>Nutrition</option>
                                                                    <option>Pathology</option>
                                                                    <option>Parasitology</option>
                                                                    <option>Pharmacology</option>
                                                                    <option>Physiology</option>
                                                                    <option>Radiology</option>
                                                                    <option>Surgery and anesthesia</option>
                                                                    <option>
                                                                        Veterinary economics and farm management
                                                                    </option>
                                                                    <option>Virology</option>
                                                                    <option>Zoonoses</option>
                                                                </Form.Control>
                                                            </Form.Group>
                                                            <div className="btn-box">
                                                                {this.state.postGrade.length - 1 === i && (
                                                                    <Button
                                                                        className="add-study"
                                                                        onClick={this.handleAddClick}
                                                                    >
                                                                        Add Study
                                                                    </Button>
                                                                )}
                                                            </div>
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </React.Fragment>
                                        ) : null}
                                        <Checkbox checked={this.state.isWorking ?? false} onChange={this.handleIsWorking}>
                                            Are you actively working in the field?
                                        </Checkbox>
                                        {this.state.isWorking ? (
                                            <React.Fragment>
                                                <Form.Group controlId="exampleForm.ControlInputf">
                                                    <Form.Label>Type of Employment</Form.Label>
                                                    <Form.Control
                                                        name="employment"
                                                        value={this.state.employment}
                                                        onChange={this.handleChange}
                                                        as="select"
                                                    >
                                                        <option selected style={{display: "none"}}>
                                                            Choose your employment type
                                                        </option>
                                                        <option value="1">Business Owner</option>
                                                        <option value="2">Employee</option>
                                                        <option value="3">Both</option>
                                                    </Form.Control>
                                                </Form.Group>
                                                {this.state.employment === "1" ? (
                                                    <React.Fragment>
                                                        <Form.Group controlId="exampleForm.ControlInputr">
                                                            <Form.Label>Business Type</Form.Label>
                                                            <Form.Control
                                                                name="businessType"
                                                                value={this.state.businessType}
                                                                onChange={this.handleChange}
                                                                as="select"
                                                            >
                                                                <option selected style={{display: "none"}}>
                                                                    Choose your business type
                                                                </option>
                                                                <option>Pet animal clinic owner</option>
                                                                <option>Drug store owner</option>
                                                                <option>Laboratory owner</option>
                                                                <option>Dairy Farm owner</option>
                                                                <option>Beef cattle farm owner</option>
                                                                <option>Poultry farm owner</option>
                                                                <option>Horse farm owner</option>
                                                                <option>Sheep farm owner</option>
                                                                <option>Goat farm owner</option>
                                                                <option>Pigs farm owner</option>
                                                                <option>Ration factory owner</option>
                                                                <option>Rabbit farm owner</option>
                                                                <option>other</option>
                                                            </Form.Control>
                                                        </Form.Group>
                                                        <Form.Group controlId="exampleForm.ControlInpute">
                                                            <Form.Label>Number of Employees</Form.Label>
                                                            <Form.Control
                                                                name="employeesNumber"
                                                                value={this.state.employeesNumber}
                                                                onChange={this.handleChange}
                                                                as="select"
                                                            >
                                                                <option selected style={{display: "none"}}>
                                                                    Choose Employee Range
                                                                </option>
                                                                <option value="0-50">0-50</option>
                                                                <option value="50-100">50-100</option>
                                                                <option value="Above 100">Above 100</option>
                                                            </Form.Control>
                                                        </Form.Group>
                                                    </React.Fragment>
                                                ) : this.state.employment == "3" ? (
                                                    <React.Fragment>
                                                        <Form.Group controlId="exampleForm.ControlInputw">
                                                            <Form.Label>Business Type</Form.Label>
                                                            <Form.Control
                                                                name="businessType"
                                                                value={this.state.businessType}
                                                                onChange={this.handleChange}
                                                                as="select"
                                                            >
                                                                <option selected style={{display: "none"}}>
                                                                    Choose your business type
                                                                </option>
                                                                <option value="commercial">Commercial</option>
                                                                <option value="medicine">Medicine</option>
                                                                <option value="engineering">Engineering</option>
                                                            </Form.Control>
                                                        </Form.Group>
                                                        <Form.Group controlId="exampleForm.ControlInputq">
                                                            <Form.Label>Number of Employees</Form.Label>
                                                            <Form.Control
                                                                name="employeesNumber"
                                                                value={this.state.employeesNumber}
                                                                onChange={this.handleChange}
                                                                as="select"
                                                            >
                                                                <option selected style={{display: "none"}}>
                                                                    Choose Employee Range
                                                                </option>
                                                                <option value="0-50">0-50</option>
                                                                <option value="50-100">50-100</option>
                                                                <option value="Above 100">Above 100</option>
                                                            </Form.Control>
                                                        </Form.Group>
                                                    </React.Fragment>
                                                ) : null}
                                            </React.Fragment>
                                        ) : null}

                                        <Button
                                            onClick={this.onRegister}
                                            className="submit-btn"
                                            type="submit"
                                        >
                                            Register
                                        </Button>
                                        <p
                                            className="error text-center"
                                            style={{fontWeight: "bold"}}
                                        >
                                            {this.state.showErr}
                                        </p>
                                        {/* <Form.Group>
                      <FacebookLogin
                        appId="523020129041693"
                        autoLoad={false}
                        fields="name,email,picture"
                        callback={this.responseFacebook}
                        render={(renderProps) => (
                          <Button  onClick={renderProps.onClick} className="loginBtn2" type="primary">
                            <i className="fab fa-facebook-f mr-5"></i> Login
                            With Facebook{" "}
                          </Button>
                        )}
                        state="test"
                      />
                    </Form.Group>
                    <Form.Group>
                      <GoogleLogin
                        clientId="834798242328-9b6rc4gin4p64v2sqqr8pulnlhuh52i6.apps.googleusercontent.com"
                        onSuccess={this.responseGoogle}
                        onFailure={this.responseGoogle}
                        cookiePolicy={"single_host_origin"}
                        className="loginBtn3"
                        icon={false}
                        buttonText="Login With Google"
                        isSignedIn={true}
                        autoLoad={false}
                      />
                    </Form.Group> */}
                                    </Form>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <Form id="generalForm">
                                        <Form.Group controlId="exampleForm.ControlInput2">
                                            <Form.Label>
                                                <strong style={{color: "red", paddingRight: "5px"}}>
                                                    *
                                                </strong>
                                                First Name
                                            </Form.Label>
                                            <Form.Control
                                                name="fullName"
                                                onChange={this.handleChange}
                                                value={this.state.fullName}
                                                type="text"
                                                placeholder="First name"
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="exampleForm.ControlInput89">
                                            <Form.Label>
                                                {" "}
                                                <strong style={{color: "red", paddingRight: "5px"}}>
                                                    *
                                                </strong>
                                                Last Name
                                            </Form.Label>
                                            <Form.Control
                                                name="lastName"
                                                onChange={this.handleChange}
                                                value={this.state.lastName}
                                                type="text"
                                                placeholder="Last name"
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="exampleForm.ControlInput3">
                                            <Form.Label>
                                                <strong style={{color: "red", paddingRight: "5px"}}>
                                                    *
                                                </strong>
                                                Password
                                            </Form.Label>
                                            <PasswordInput
                                                name="password"
                                                onChange={this.handlePassword}
                                                value={this.state.password}
                                                type="password"
                                            />
                                            <p style={{color: "red", fontWeight: "bold"}}>
                                                {this.state.passErr}
                                            </p>
                                        </Form.Group>
                                        <Form.Group controlId="exampleForm.ControlInput3">
                                            <Form.Label>
                                                <strong style={{color: "red", paddingRight: "5px"}}>
                                                    *
                                                </strong>
                                                Confirm Password
                                            </Form.Label>
                                            <PasswordInput
                                                name="password"
                                                onChange={this.handleConfirmPassword}
                                                value={this.state.confirmPassword}
                                                type="password"
                                            />
                                            <p style={{color: "red", fontWeight: "bold"}}>
                                                {this.state.confirmErr}
                                            </p>
                                        </Form.Group>
                                        <Form.Group controlId="exampleForm.ControlInput4">
                                            <Form.Label>
                                                {" "}
                                                <strong style={{color: "red", paddingRight: "5px"}}>
                                                    *
                                                </strong>
                                                Country of Residence
                                            </Form.Label>
                                            {/* <ReactSelect
                        options={this.state.options}
                        value={this.state.selectedCountryRes}
                        onChange={this.handleCountriesRes}
                      /> */}
                                            <Select
                                                showSearch
                                                allowClear
                                                placeholder="select country"
                                                value={this.state.selectedCountryRes}
                                                onChange={this.handleCountriesRes}
                                                style={{width: "100%"}}
                                                onClick={this.getCountries}
                                            >
                                                {this.state.options?.map((country) => (
                                                    <Select.Option
                                                        id={country.value}
                                                        value={country.label}
                                                    >
                                                        {country.label}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Group>
                                        <Form.Group controlId="exampleForm.ControlInput6">
                                            <Form.Label>
                                                {" "}
                                                <strong style={{color: "red", paddingRight: "5px"}}>
                                                    *
                                                </strong>
                                                City
                                            </Form.Label>
                                            <Form.Control
                                                name="city"
                                                onChange={this.handleChange}
                                                value={this.state.city}
                                                type="text"
                                                placeholder="City"
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="exampleForm.ControlSelect1">
                                            <Form.Label>Gender</Form.Label>
                                            <Form.Control
                                                name="gender"
                                                value={this.state.gender}
                                                onChange={this.handleChange}
                                                as="select"
                                            >
                                                <option selected style={{display: "none"}}>
                                                    I am a
                                                </option>
                                                <option value="1">Male</option>
                                                <option value="2">Female</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group controlId="exampleForm.ControlSelect2">
                                            <Form.Label>Date of Birth</Form.Label>
                                            <Row>
                                                <Col className="col-padding3" lg={4}>
                                                    <Form.Control
                                                        name="day"
                                                        value={this.state.day}
                                                        onChange={this.handleChange}
                                                        as="select"
                                                    >
                                                        {days.map((day) => {
                                                            return <option key={day}>{day}</option>;
                                                        })}
                                                    </Form.Control>
                                                </Col>

                                                <Col className="col-padding3" lg={4}>
                                                    <Form.Control
                                                        name="month"
                                                        value={this.state.month}
                                                        onChange={this.handleChange}
                                                        as="select"
                                                    >
                                                        {months.map((month) => {
                                                            return <option key={month}>{month}</option>;
                                                        })}
                                                    </Form.Control>
                                                </Col>
                                                <Col className="col-padding3" lg={4}>
                                                    <Form.Control
                                                        name="year"
                                                        value={this.state.year}
                                                        onChange={this.handleChange}
                                                        as="select"
                                                    >
                                                        {years.map((year) => {
                                                            return <option key={year}>{year}</option>;
                                                        })}
                                                    </Form.Control>
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                        <Form.Group controlId="exampleForm.ControlInput3">
                                            <Form.Label>
                                                <strong style={{color: "red", paddingRight: "5px"}}>
                                                    *
                                                </strong>
                                                Email
                                            </Form.Label>
                                            <Form.Control
                                                onChange={this.handleEmail}
                                                value={this.state.email}
                                                name="email"
                                                type="email"
                                                placeholder="Your email"
                                            />
                                            <p style={{color: "red", fontWeight: "bold"}}>
                                                {this.state.emailErr}
                                            </p>
                                        </Form.Group>
                                        <Form.Group controlId="exampleForm.ControlInput4">
                                            <Form.Label>
                                                <strong style={{color: "red", paddingRight: "5px"}}>
                                                    *
                                                </strong>
                                                Phone Number
                                            </Form.Label>
                                            <Form.Control
                                                onChange={this.handleNumber}
                                                name="mobile"
                                                value={this.state.mobile}
                                                type="number"
                                                placeholder="Your number"
                                            />
                                            <p style={{color: "red", fontWeight: "bold"}}>
                                                {this.state.phoneErr}
                                            </p>
                                        </Form.Group>
                                    </Form>
                                    {/* <FacebookLogin
                    appId="620355319059926"
                    autoLoad={false}
                    fields="name,email,picture"
                    callback={this.responseFacebook}
                    render={(renderProps) => (
                      <Button
                        onClick={renderProps.onClick}
                        className="loginBtn2"
                        type="primary"
                      >
                        <i className="fab fa-facebook-f mr-5"></i> Sign up With
                        Facebook{" "}
                      </Button>
                    )}
                    state="test"
                  />

                  <GoogleLogin
                    clientId="834798242328-9b6rc4gin4p64v2sqqr8pulnlhuh52i6.apps.googleusercontent.com"
                    onSuccess={this.responseGoogle}
                    onFailure={this.responseGoogle}
                    cookiePolicy={"single_host_origin"}
                    className="loginBtn3"
                    icon={false}
                    buttonText="Sign up With Google"
                    isSignedIn={false}
                    autoLoad={false}
                  /> */}
                                </React.Fragment>
                            )}

                            <p className="sign-in">
                                Already have an account?{" "}
                                <a href="/login" className="sign-in-link">
                                    Sign In
                                </a>
                            </p>
                        </Col>
                    </Row>
                </Container>
                <Modal show={this.state.mobileModal} onHide={this.close}>
                    <Container className="p-4">
                        <Row>
                            <Col className="text-center" sm={12}>
                                {" "}
                                <h6>
                                    Please enter your phone number{" "}
                                    {this.state.emailNeeded ? "and your email" : null}
                                </h6>
                            </Col>

                            <Col className="text-center" sm={12}>
                                <Input
                                    type="number"
                                    placeholder="Enter your phone"
                                    value={this.state.phone}
                                    name="phone"
                                    onChange={this.handleChange}
                                />
                            </Col>
                            {this.state.emailNeeded ? (
                                <Col className="text-center" sm={12}>
                                    <Input
                                        type="text"
                                        placeholder="Enter your email"
                                        value={this.state.email2}
                                        name="email2"
                                        onChange={this.handleChange}
                                    />
                                </Col>
                            ) : null}

                            <Col className="text-center mt-3" md={12}>
                                <Button
                                    onClick={() => {
                                        let data = {...this.state.loginData};
                                        data.mobile = this.state.phone;
                                        this.setState({loginData: data}, async () => {
                                            const userData = await this.props.userRegister(
                                                this.state.loginData,
                                                this.props.history
                                            );
                                            if (userData) {
                                                console.log(userData);

                                                this.setState({BeErr: userData});

                                                if (this.state.BeErr.error) {
                                                    for (const error in userData.error) {
                                                        console.log(userData.error[error][0]);
                                                        setTimeout(() => {
                                                            toast.error(
                                                                `${
                                                                    error +
                                                                    " : " +
                                                                    userData.error[error][0]
                                                                        .charAt(0)
                                                                        .toUpperCase() +
                                                                    userData.error[error][0].slice(1)
                                                                }`
                                                            );
                                                        }, 500);
                                                    }
                                                } else {
                                                    setTimeout(() => {
                                                        toast.success("User Created Successfully");
                                                    }, 500);
                                                    this.props.history.push("/login");
                                                }
                                            }
                                        });
                                    }}
                                    className="forumBtn1 mx-3"
                                >
                                    Confirm
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal>
                <ToastContainer position="bottom-right"/>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({userRegister, LogOut, showNav}, dispatch);
};
export default connect(null, mapDispatchToProps)(Register);
