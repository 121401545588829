import React, { Component } from "react";
import axios from "axios";
import { Container, Row, Col } from "react-bootstrap";
// import { Link } from "react-router-dom";
import ErrorHandler from "../error-handler/ErrorHandler";
import { APILINK } from "../EndPoint";
import { Radio, Space, Spin, Input } from "antd";

import { connect } from "react-redux";
import ChatMessages from "./ChatMessages";

const axiosApiInstance = axios.create();

class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      tutors: [],
      data2: [],
      loading: false,
      policy: "",
      searchText: "",
      searchResult: [],
      selectedUser: "",
      message: "",
      rooms: [],
      messages: [],
      roomID: "",
      selectedUserName: "",
      loadMore: false,
      current: 1,
      count: 1,
    };
  }

  componentDidMount() {
    this.getRooms();
  }

  getRooms = async () => {
    let result = await axiosApiInstance.get(APILINK + "/get_my_rooms");
    if (result) {
      this.setState({ rooms: result.data.data }, () => {});
    }
  };

  getChat = (e) => {
    this.setState({ searchResult: [], roomID: e.target.id }, async () => {
      if (!this.state.roomID || this.state.roomID.length == 0) return;
      let result = await axiosApiInstance
        .get(APILINK + `/get_room_messages?room_id=${this.state.roomID}&page=1`)
        .catch((err) => console.log({ err }));
      if (result.data) {
        console.log(result.data);
        if (result.data.data.length == 10) {
          this.setState({ current: this.state.current + 1 });
        } else {
          console.log("lsa mwslch");
        }
        this.setState(
          {
            messages: result.data.data.reverse(),
            selectedUserName:
              this.props.user.id == result.data.data[0].receiver.id
                ? result.data.data[0].sender.full_name
                : result.data.data[0].receiver.full_name,
            selectedUser:
              this.props.user.id == result.data.data[0].receiver.id
                ? result.data.data[0].sender.id
                : result.data.data[0].receiver.id,
          },
          () => {
            // console.log(this.state.selectedUser);
          }
        );
      }
    });
  };

  onSearch = (e) => {
    this.setState(
      {
        searchText: e.target.value,
        selectedUser: "",
        selectedUserName: "",
        messages: "",
      },
      () => {
        if (this.state.searchText.length > 1) {
          setTimeout(async () => {
            let result = await axiosApiInstance.get(
              APILINK + `/search_for_user?name=${this.state.searchText}`
            );
            if (result) {
              this.setState({ searchResult: result.data }, () => {
                console.log(this.state.searchResult);
              });
            }
          }, 3000);
        }
      }
    );
  };
  onChange = (e) => {
    this.setState({ selectedUser: e.target.value }, () => {
      console.log(this.state.selectedUser);
    });
  };
  onMessage = (e) => {
    this.setState({ message: e.target.value });
  };
  detectLoading = (e) => {
    if (e) {
      this.setState({ loadMore: e });
    }
  };
  componentDidUpdate = async (prevProps, prevState) => {
    if (this.state.rooms !== prevState.rooms) {
      // console.log("changed");
    }
    if (this.state.loadMore !== prevState.loadMore) {
      let result = await axiosApiInstance.get(
        APILINK +
          `/get_room_messages?room_id=${this.state.roomID}&page=${this.state.current}`
      );
      if (result.data) {
        if (result.data.data.length == 10) {
          this.setState({ current: this.state.current + 1 });
        } else {
          console.log("lsa mwslch");
        }
        var children = [...result.data.data.reverse(), ...this.state.messages];
        var length = children.length;
        this.setState({ messages: children });

        this.setState(
          {
            // messages: result.data.data.reverse(),
            selectedUserName:
              this.props.user.id == result.data.data[0].receiver.id
                ? result.data.data[0].sender.full_name
                : result.data.data[0].receiver.full_name,
            selectedUser:
              this.props.user.id == result.data.data[0].receiver.id
                ? result.data.data[0].sender.id
                : result.data.data[0].receiver.id,
          },
          () => {
            console.log(this.state.selectedUser);
          }
        );
      }
    }
  };

  render() {
    return (
      <div>
        {this.state.loading ? (
          <Spin className="spinElement" tip="..loading" />
        ) : (
          <Container className="viewSuper">
            <Row>
              <Col>
                <h1 className="course-title">Chats</h1>
              </Col>
            </Row>
            <Row>
              <Col sm="4"></Col>
              <Col className="ml-4" sm="7">
                <h4 className="text-left">{this.state.selectedUserName}</h4>
              </Col>
            </Row>
            <Row>
              <Col className="alignLeft scroll rooms" sm={4}>
                <Input
                  className="w-100"
                  placeholder="Search for users to start chatting"
                  value={this.state.searchText}
                  type="text"
                  onChange={this.onSearch}
                />
                <Radio.Group
                  className="mt-2"
                  onChange={this.onChange}
                  value={this.state.selectedUser}
                >
                  <Space direction="vertical">
                    {Array.isArray(this.state.searchResult) &&
                      this.state.searchResult.map((user) => {
                        return (
                          <Radio id={user.id} value={user.id}>
                            {user.full_name} {user.last_name}
                          </Radio>
                        );
                      })}
                  </Space>
                </Radio.Group>
                <h4 className="mt-4 mb-4">Chat Rooms</h4>
                {this.state.rooms.length > 0 ? (
                  this.state.rooms.map((room) => {
                    return (
                      <div className="chatTag" key={room.id}>
                        {room.participant1 == null ||
                        room.participant2 == null ? null : (
                          <>
                            {" "}
                            <h6
                              className={
                                this.state.roomID === room.id
                                  ? "selected-chat chat"
                                  : "chat"
                              }
                              value={room.participant1.full_name}
                              id={room.id}
                              onClick={this.getChat}
                            >
                              {room.participant1.id !== this.props.user.id
                                ? room.participant1.full_name +
                                  " " +
                                  room.participant1.last_name
                                : room.participant2.full_name +
                                  " " +
                                  room.participant2.last_name}
                            </h6>
                          </>
                        )}
                      </div>
                    );
                  })
                ) : (
                  <p>No Chat Rooms Yet</p>
                )}
              </Col>
              <Col className="ml-4" sm={7}>
                {this.state.selectedUser === "" ? (
                  <h5>Select a user to start a chat</h5>
                ) : (
                  <React.Fragment>
                    {" "}
                    <ChatMessages
                      selectedUser={this.state.selectedUser}
                      msgs={this.state.messages}
                      detectLoading={this.detectLoading}
                    />
                  </React.Fragment>
                )}
              </Col>
            </Row>
          </Container>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  isAuth: state.auth.isAuth,
});

export default connect(mapStateToProps)(ErrorHandler(Chat, axiosApiInstance));
