import React, { Component } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
// import { Link } from "react-router-dom";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { APILINK } from "../../EndPoint";
import { Link } from "react-router-dom";
import { Spin, Empty } from "antd";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { isQuiz } from "../../global-state/actions/isQuizAction";

const axiosApiInstance = axios.create();
class ViewQuizzes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      count: 1,
      current: 1,
      tutors: [],
      time: "",
      showEditModel: false,
      columns: [
        {
          name: "Title",
          selector: "title",
          sortable: true,
          right: true,
        },

        {
          name: "Actions",
          width: "150px",
          button: true,
          cell: (row) => (
            <div className="table-data-feature">
              <OverlayTrigger
                key={"1"}
                placement={"top"}
                overlay={<Tooltip id={`tooltip-${"top"}`}>Edit Quiz</Tooltip>}
              >
                <Link to={`/edit_quiz/${row.id}`}>
                  {" "}
                  <i
                    onClick={this.props.isQuiz(true)}
                    className="fas fa-edit pr-2"
                  ></i>
                </Link>
              </OverlayTrigger>
              <OverlayTrigger
                key={"1"}
                placement={"top"}
                overlay={<Tooltip id={`tooltip-${"top"}`}>Delete Quiz</Tooltip>}
              >
                <i
                  onClick={this.showWarning}
                  id={row.id}
                  className="fas fa-trash pr-2"
                ></i>
              </OverlayTrigger>
              <OverlayTrigger
                key={"1"}
                placement={"top"}
                overlay={
                  <Tooltip id={`tooltip-${"top"}`}>Add Questions</Tooltip>
                }
              >
                <Link to={`/create_questions/${row.id}`}>
                  {" "}
                  <i
                    onClick={this.props.isQuiz(true)}
                    className="fas fa-plus-circle pr-2"
                  ></i>
                </Link>
              </OverlayTrigger>
              <OverlayTrigger
                key={"1"}
                placement={"top"}
                overlay={
                  <Tooltip id={`tooltip-${"top"}`}>View Questions</Tooltip>
                }
              >
                <Link to={`/quiz_questions/${row.id}`}>
                  {" "}
                  <i
                    onClick={this.props.isQuiz(true)}
                    className="fas fa-eye pr-2"
                  ></i>
                </Link>
              </OverlayTrigger>
            </div>
          ),
        },
      ],
      loading: false,
    };
  }

  getQuiz = async () => {
    const result = await axiosApiInstance.get(
      APILINK + `/create_quiz?subject_round_id=${this.props.match.params.id}`
    );
    console.log(result);
    if (result) {
      this.setState({
        data: result.data,
        count: result.data.count,
        loading: false,
      });
    }
  };
  componentDidMount() {
    this.setState({ loading: true });
    this.getQuiz();
  }

  onChange = (page) => {
    console.log(page);
    this.setState(
      {
        current: page,
      },
      async () => {
        const result = await axiosApiInstance.get(
          APILINK +
            `/create_quiz?subject_round_id=${this.props.match.params.id}`
        );
        console.log(result);
        if (result) {
          this.setState({
            data: result.data,
            count: result.data.count,
            loading: false,
          });
        }
      }
    );
  };

  showWarning = (e) => {
    this.setState({ show: true, quizID: e.target.id });
  };
  close = (e) => {
    this.setState({ show: false });
  };
  deleteQuiz = async () => {
    let result = await axiosApiInstance.delete(
      APILINK + `/edit_quiz/${this.state.quizID}`
    );
    console.log(result);
    if (result.data == "") {
      this.setState({ show: false });
      this.getQuiz();
    }
  };

  render() {
    return (
      <div>
        <Container className="viewSuper">
          <Row>
            <Col className="p-4 mb-4" style={{ textAlign: "left" }} sm={6}>
              <h2>Round Quizzes</h2>
            </Col>
          </Row>
          {this.state.data.length !== 0 && this.state.loading == false ? (
            <Row>
              <Col style={{ textAlign: "center" }} sm={12}>
                <DataTable
                  columns={this.state.columns}
                  data={this.state.data}
                />
              </Col>
            </Row>
          ) : this.state.data.length === 0 && this.state.loading == false ? (
            <React.Fragment>
              <Empty className="text-center" />
              <Button className="forumBtn1">
                <Link to={`/create_quiz/${this.props.match.params.id}`}>
                  <i className="fas fa-plus-circle pr-2"></i> Create Quiz?
                </Link>
              </Button>
            </React.Fragment>
          ) : (
            <Spin tip="...loading" />
          )}

          <Modal show={this.state.show} onHide={this.close}>
            <Container className="p-4">
              <Row>
                <Col className="text-center" sm={12}>
                  {" "}
                  <h6>Are u sure u want to delete this Quiz?</h6>
                </Col>
                <Row style={{ margin: "auto" }}>
                  <Col md={12}>
                    <Button
                      onClick={this.deleteQuiz}
                      className="forumBtn1 mx-3"
                    >
                      Delete
                    </Button>
                    <Button className="forumBtn1" onClick={this.close}>
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </Row>
            </Container>
          </Modal>
        </Container>

        <ToastContainer position="bottom-right" />
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ isQuiz }, dispatch);
};

export default connect(
  null,
  mapDispatchToProps
)(ErrorHandler(ViewQuizzes, axiosApiInstance));
