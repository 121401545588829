import React, { Component } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import {
  Container,
  Row,
  Col,
  Button,
  OverlayTrigger,
  Tooltip,
  Modal,
  Form,
} from "react-bootstrap";
// import { Link } from "react-router-dom";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { APILINK } from "../../EndPoint";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Pagination, Spin } from "antd";
import { connect } from "react-redux";

const axiosApiInstance = axios.create();
class ViewAllSubjects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      tutors: [],
      loading: false,
      count: 1,
      current: 1,
      showSubjectModel: false,
      showEditModel: false,
      columns: [
        {
          name: "Subject title",
          selector: "title",
          sortable: true,
          right: true,
        },
        {
          name: "Description",
          selector: "description",
          sortable: true,
          right: true,
        },
        {
          name: "Cost",
          selector: "cost",
          sortable: true,
          right: true,
        },

        {
          name: "Actions",
          button: true,
          cell: (row) => (
            <div className="table-data-feature">
              {this.props.user.user_type === "3" ? (
                <>
                  <OverlayTrigger
                    key={"3"}
                    placement={"top"}
                    overlay={
                      <Tooltip id={`tooltip-${"top"}`}>Edit Subject</Tooltip>
                    }
                  >
                    <i
                      id={row.id}
                      onClick={this.editModal}
                      className="fas fa-edit pr-2"
                    ></i>
                  </OverlayTrigger>
                  <OverlayTrigger
                    key={"3"}
                    placement={"top"}
                    overlay={
                      <Tooltip id={`tooltip-${"top"}`}>Delete Subject</Tooltip>
                    }
                  >
                    <i
                      id={row.id}
                      onClick={this.showWarning}
                      className="fas fa-trash pr-2"
                    ></i>
                  </OverlayTrigger>
                </>
              ) : null}
            </div>
          ),
        },
      ],
    };
  }
  editModal = async (e) => {
    this.setState({ subjectID: e.target.id }, () => {
      let subject = this.state.data.find(
        (data) => data.id == this.state.subjectID
      );
      console.log(subject);
      this.setState(
        {
          title: subject.title,
          description: subject.description,
          cost: subject.cost,
        },
        () => {
          this.setState({ showEditModel: true });
        }
      );
    });
  };
  getSubjects = async () => {
    this.setState({ loading: true });
    let result;
    if (this.props.user.user_type == "1") {
      result = await axiosApiInstance.get(APILINK + `/create_subjects`);
    } else {
      result = await axiosApiInstance.get(
        APILINK +
          `/create_subjects?course_id=${this.props.match.params.id}&page=${this.state.current}`
      );
    }

    console.log(result);
    let sorted = result.data.data.sort((a, b) =>
      a.display_order > b.display_order ? 1 : -1
    );
    // console.log(sorted);
    this.setState({ data: sorted, count: result.data.count, loading: false });
  };
  componentDidMount() {
    this.getSubjects();
  }

  openSubject = (e) => {
    console.log(e.target.id);
    this.setState({ showSubjectModel: true, subjectID: e.target.id });
  };
  hideSubject = () => {
    this.setState({ showSubjectModel: false, showEditModel: false });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  editSubject = async () => {
    let data = {
      title: this.state.title,
      description: this.state.description,
      cost: this.state.cost,
    };
    console.log(data);

    let result = await axiosApiInstance.patch(
      APILINK + `/update_subject/${this.state.subjectID}`,
      data
    );
    if (result) {
      if (result.data) {
        this.setState({ showEditModel: false });
        setTimeout(() => {
          toast.info("Subject has been edited successfully");
        }, 500);
      }
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.showEditModel !== this.state.showEditModel) {
      this.getSubjects();
    }
  }

  onChange = (page) => {
    console.log(page);
    this.setState(
      {
        current: page,
      },
      async () => {
        let result;
        if (this.props.user.user_type == "1") {
          result = await axiosApiInstance.get(APILINK + `/create_subjects`);
        } else {
          result = await axiosApiInstance.get(
            APILINK +
              `/create_subjects?course_id=${this.props.match.params.id}&page=${this.state.current}`
          );
        }

        console.log(result);
        let sorted = result.data.data.sort((a, b) =>
          a.display_order > b.display_order ? 1 : -1
        );
        // console.log(sorted);
        this.setState({ data: sorted, count: result.data.count });
      }
    );
  };

  showWarning = (e) => {
    this.setState({ showDelete: true, subjectID: e.target.id });
  };
  close = (e) => {
    this.setState({ showDelete: false });
  };
  deleteSubject = async () => {
    let result = await axiosApiInstance.delete(
      APILINK + `/update_subject/${this.state.subjectID}`
    );
    console.log(result);
    if (result.data == "") {
      this.setState({ showDelete: false });
      this.getSubjects();
    }
  };


  render() {
    return (
      <div>
        {this.state.loading ? (
          <Spin className="spinElement" tip="..loading" />
        ) : (
          <Container className="viewSuper">
            <Row>
              <Col className="p-4 mb-4" style={{ textAlign: "left" }} sm={6}>
                <h2> All Subjects</h2>
              </Col>
            </Row>
            <Row>
              <Col style={{ textAlign: "center" }} sm={12}>
                <DataTable
                  columns={this.state.columns}
                  data={this.state.data}
                />
              </Col>
            </Row>
            <Row className="p-4">
              {this.state.data.length !== 0 ? (
                <Pagination
                  onChange={this.onChange}
                  defaultCurrent={this.state.current}
                  total={this.state.count}
                  current={this.state.current}
                />
              ) : null}
            </Row>
          </Container>
        )}

        <Modal show={this.state.showEditModel} onHide={this.hideSubject}>
          <Container fluid>
            <Row>
              <Col className="text-center pt-2" sm={12}>
                {" "}
                <h6>Edit Subject</h6>
              </Col>
            </Row>
            <Form>
              <Row className="mt-2">
                <Col sm={6}>
                  <Form.Label>Subject Title</Form.Label>

                  <Form.Control
                    name="title"
                    value={this.state.title}
                    onChange={this.handleChange}
                    type="text"
                  />
                </Col>
                <Col sm={6}>
                  <Form.Label>Description</Form.Label>

                  <Form.Control
                    name="description"
                    value={this.state.description}
                    onChange={this.handleChange}
                    type="text"
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <Form.Label>Cost</Form.Label>
                  <Form.Control
                    name="cost"
                    value={this.state.cost}
                    onChange={this.handleChange}
                    type="number"
                  />
                </Col>
              </Row>
            </Form>

            <Row style={{ margin: "auto" }}>
              <Col className="text-center" md={12}>
                <Button
                  className="ml-3 forumBtn1"
                  onClick={this.editSubject}
                >
                  Edit
                </Button>
                <Button className="forumBtn1 m-2" onClick={this.hideSubject}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal>

        <ToastContainer position="bottom-right" />

        <Modal show={this.state.showDelete} onHide={this.close}>
          <Container className="p-4">
            <Row>
              <Col className="text-center" sm={12}>
                {" "}
                <h6>Are u sure u want to delete this Subject?</h6>
              </Col>
              <Row style={{ margin: "auto" }}>
                <Col md={12}>
                  <Button onClick={this.deleteSubject} className="forumBtn1 mx-3">
                    Delete
                  </Button>
                  <Button className="forumBtn1" onClick={this.close}>
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Row>
          </Container>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.auth.user,
  isAuth: state.auth.isAuth,
});

export default connect(
  mapStateToProps,
  null
)(ErrorHandler(ViewAllSubjects, axiosApiInstance));
