import React, { Component } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
// import { Link } from "react-router-dom";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { APILINK } from "../../EndPoint";
import { Link } from "react-router-dom";
import { Spin, Pagination } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { isQuiz } from "../../global-state/actions/isQuizAction";

const axiosApiInstance = axios.create();
class ViewTraining extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      count: 1,
      current: 1,
      tutors: [],
      time: "",
      showEditModel: false,
      columns: [
        {
          name: "Title",
          selector: "title",
          sortable: true,
          right: true,
        },

        {
          name: "Actions",
          button: true,
          cell: (row) => (
            <div className="table-data-feature">
              <OverlayTrigger
                key={"1"}
                placement={"top"}
                overlay={
                  <Tooltip id={`tooltip-${"top"}`}>Add Questions</Tooltip>
                }
              >
                <Link to={`/create_questions/${row.id}`}>
                  {" "}
                  <i
                    onClick={this.props.isQuiz(false)}
                    className="fas fa-plus-circle pr-2"
                  ></i>
                </Link>
              </OverlayTrigger>
              <OverlayTrigger
                key={"1"}
                placement={"top"}
                overlay={
                  <Tooltip id={`tooltip-${"top"}`}>Delete Training</Tooltip>
                }
              >
                <i
                  onClick={this.showWarning}
                  id={row.id}
                  className="fas fa-trash pr-2"
                ></i>
              </OverlayTrigger>
              <OverlayTrigger
                key={"1"}
                placement={"top"}
                overlay={
                  <Tooltip id={`tooltip-${"top"}`}>
                    View Training Questions
                  </Tooltip>
                }
              >
                <Link to={`/training_questions/${row.id}`}>
                  {" "}
                  <i className="fas fa-eye pr-2"></i>
                </Link>
              </OverlayTrigger>
            </div>
          ),
        },
      ],
    };
  }

  getTraining = async () => {
    let result;
    if (this.props.isSubject) {
      result = await axiosApiInstance.get(
        APILINK +
          `/create_training?subject_round_id=${this.props.match.params.id}&${this.state.current}`
      );
      console.log(result);
    } else {
      result = await axiosApiInstance.get(
        APILINK +
          `/create_training?course_round_id=${this.props.match.params.id}&${this.state.current}`
      );
      console.log(result);
    }

    this.setState({ data: result.data, count: result.data.count });
  };

  componentDidMount() {
    this.getTraining();
  }

  onChange = (page) => {
    console.log(page);
    this.setState(
      {
        current: page,
      },
      async () => {
        let result;
        if (this.props.isSubject) {
          result = await axiosApiInstance.get(
            APILINK +
              `/create_training?subject_round_id=${this.props.match.params.id}`
          );
          
        } else {
          result = await axiosApiInstance.get(
            APILINK +
              `/create_training?course_round_id=${this.props.match.params.id}`
          );
          
        }
        console.log(result);
        if (result) {
          this.setState({ data: result.data, count: result.data.count });
        }
      }
    );
  };
  showWarning = (e) => {
    this.setState({ show: true, trainingID: e.target.id });
  };
  close = (e) => {
    this.setState({ show: false });
  };
  deleteTraining = async () => {
    let result = await axiosApiInstance.delete(
      APILINK + `/edit_training/${this.state.trainingID}`
    );
    console.log(result);
    if (result.data == "") {
      this.setState({ show: false });
      this.getTraining();
    }
  };
  render() {
    return (
      <div>
        <Container className="viewSuper">
          <Row>
            <Col className="p-4 mb-4" style={{ textAlign: "left" }} sm={6}>
              <h2>Round Trainings</h2>
            </Col>
          </Row>
          <Row>
            <Col style={{ textAlign: "center" }} sm={12}>
              <DataTable columns={this.state.columns} data={this.state.data} />
            </Col>
          </Row>
          <Row className="p-4">
            {this.state.data.length !== 0 ? (
              <Pagination
                onChange={this.onChange}
                defaultCurrent={this.state.current}
                total={this.state.count}
                current={this.state.current}
              />
            ) : null}
          </Row>
        </Container>
        <Modal show={this.state.show} onHide={this.close}>
          <Container className="p-4">
            <Row>
              <Col className="text-center" sm={12}>
                {" "}
                <h6>Are u sure u want to delete this Training?</h6>
              </Col>
              <Row style={{ margin: "auto" }}>
                <Col md={12}>
                  <Button
                    onClick={this.deleteTraining}
                    className="forumBtn1 mx-3"
                  >
                    Delete
                  </Button>
                  <Button className="forumBtn1" onClick={this.close}>
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Row>
          </Container>
        </Modal>
        <ToastContainer position="bottom-right" />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  isSubject: state.isSubject.isSubject,
});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ isQuiz }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ErrorHandler(ViewTraining, axiosApiInstance));
