import React, { Component } from "react";
import axios from "axios";
import { Switch } from "antd";
import DataTable from "react-data-table-component";
import { Container, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
// import { Link } from "react-router-dom";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { APILINK } from "../../EndPoint";
import { Pagination, Spin } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
const axiosApiInstance = axios.create();
class QuizGrades extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: [],
      studentID: "",
      count: 1,
      current: 1,
      showSubjectModel: false,
      columns: [
        {
          name: "Name",
          selector: "student.full_name",
          sortable: true,
          right: true,
        },
        {
          name: "Name",
          selector: "student.last_name",
          sortable: true,
          right: true,
        },
        {
          name: "Quiz Title",
          selector: "quiz.title",
          sortable: true,
          right: true,
        },
        {
          name: "Quiz Grade",
          selector: "grade",
          sortable: true,
          right: true,
        },
        {
          name: "Status",
          width: "140px",
          button: true,
          cell: (row) =>
            row.did_pass ? (
              <p className="ml-3">Passed</p>
            ) : (
              <p className="ml-3">Failed</p>
            ),
        },
        {
          name: "Date Taken",
          width: "140px",
          button: true,
          cell: (row) => <p className="ml-3">{row.date_taken.split("T")[0]}</p>,
        },
      ],
    };
  }

  getGrades = async () => {
    this.setState({ loading: true });
    let result;
    result = await axiosApiInstance.get(
      APILINK +
        `/tutor_get_grades?subject_round_id=${this.props.match.params.id}&page=1`
    );
    if (result) {
      this.setState({
        data: result.data.data,
        count: result.data.count,
        loading: false,
      });
    }
  };
  componentDidMount() {
    this.getGrades();
  }

  onChange = (page) => {
    console.log(page);
    this.setState(
      {
        current: page,
      },
      async () => {
        await axiosApiInstance
          .get(APILINK + `/tutor_get_grades?subject_round_id=${this.props.match.params.id}&page=${page}`)
          .then((res) => {
            console.log(res);
            this.setState({ data: res.data.data, count: res.data.count });
          });
      }
    );
  };

  render() {
    console.log(this.state.data);
    return (
      <div>
        {this.state.loading ? (
          <Spin className="spinElement" tip="..loading" />
        ) : (
          <Container className="viewSuper">
            <Row>
              <Col className="p-4 mb-4" style={{ textAlign: "left" }} sm={6}>
                <h2>Quiz Grades</h2>
              </Col>
            </Row>
            <Row>
              <Col style={{ textAlign: "center" }} sm={12}>
                <DataTable
                  columns={this.state.columns}
                  data={this.state.data}
                />
              </Col>
            </Row>
            <Row className="p-4">
              {this.state.data.length !== 0 ? (
                <Pagination
                  onChange={this.onChange}
                  defaultCurrent={this.state.current}
                  total={this.state.count}
                  current={this.state.current}
                />
              ) : null}
            </Row>
          </Container>
        )}
        <ToastContainer position="bottom-right" />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  isAuth: state.auth.isAuth,
});

export default connect(mapStateToProps)(
  ErrorHandler(QuizGrades, axiosApiInstance)
);
