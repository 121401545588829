import React, { Component } from "react";
import { ListGroupItem, Collapse } from "reactstrap";
import { Button } from "react-bootstrap";
import "../../style-sheets/studentCourses.css";
import Switch from "react-switch";
import axios from "axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { APILINK } from "../../EndPoint";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { isSubject } from "../../global-state/actions/isSubjectAction";
import { Row, Col } from "react-bootstrap";
const axiosApiInstance = axios.create();

class Subjects extends Component {
  state = {
    collapse: false,
    checked: false,
    clicked: false
  };

  toggle = () => {
    if (this.props.isActive) {
      this.setState({ collapse: !this.state.collapse });
    } else {
      this.setState({ collapse: false });
    }
  };
  handleChange = async (checked) => {
    this.setState({ checked });
    let result;
    if (this.props.user.user_type === "3") {
      if (this.props.isActive) {
        result = await axiosApiInstance.put(
          APILINK +
            `/subject_activate/${this.props.subjectRoundID}?student=${this.props.studentID}&state=false`
        );
      } else {
        result = await axiosApiInstance.put(
          APILINK +
            `/subject_activate/${this.props.subjectRoundID}?student=${this.props.studentID}&state=true`
        );
      }
    } else {
      result = await axiosApiInstance
        .put(APILINK + `/subject_activate/${this.props.subjectRoundID}`)
        .catch((err) => {
          console.log(err.response);
          if (err.response.data.detail == "Not found.") {
            setTimeout(() => {
              toast.error("You Cannot activate this subject");
            }, 2000);
          }
        });
    }
    if (result) {
      if (this.props.isActive == false) {
        setTimeout(() => {
          toast.info("Subject has been Activated successfully");
        }, 500);
      } else {
        if (this.props.user.user_type === "3") {
          setTimeout(() => {
            toast.info("Subject has been DeActivated successfully");
          }, 500);
        }
      }
      // this.setState({ clicked: !this.state.clicked }, () => {
        this.props.toggle("change");
      //   console.log(this.state.clicked);
      // });
    }
  };

  render() {
    // console.log(this.state.clicked);

    return (
      <ListGroupItem>
        <div>
          {" "}
          <div className="toggler" onClick={this.toggle}>
            <div className="nameSwitch">
              {" "}
              <Switch
                onChange={this.handleChange}
                disabled={!this.props.canActive}
                checked={this.props.isActive}
              />
              <p>{this.props.title}</p>
            </div>
            {/* <Button> */}
            <i className="fas fa-2x fa-sort-down subjectArrow"></i>
            {/* </Button> */}
          </div>
          <Collapse className="details" isOpen={this.state.collapse}>
            <p>
              <strong>Cost : </strong>
              {this.props.cost}
            </p>
            <p>
              <strong>Start Date : </strong>
              {this.props.startDate}
            </p>
            <p>
              <strong>End Date : </strong>
              {this.props.endDate}
            </p>
            <p>
              <strong>Instructor : </strong>
              {this.props.tutor.full_name}
            </p>

            <Button className="view-more-btn">
              {" "}
              <Link to={`/view_materials/${this.props.subjectRoundID}`}>
                View Materials{" "}
              </Link>
            </Button>

            <Button
              onClic={this.props.isSubject(true)}
              className="view-more-btn"
            >
              {" "}
              <Link to={`/view_all_training/${this.props.subjectRoundID}`}>
                View Trainings{" "}
              </Link>
            </Button>
            <Button
              onClic={this.props.isSubject(true)}
              className="view-more-btn"
            >
              {" "}
              <Link to={`/view_all_quizes/${this.props.subjectRoundID}`}>
                View Quizes{" "}
              </Link>
            </Button>
          </Collapse>
        </div>
        <ToastContainer position="bottom-right" />
      </ListGroupItem>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuth: state.auth.isAuth,
  user: state.auth.user
});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ isSubject }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ErrorHandler(Subjects, axiosApiInstance));
