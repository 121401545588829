import React, { Component } from "react";
import {
  Row,
  Col,
  Container,
  Button,
  Form,
  Modal,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import "../../style-sheets/training.css";
import ErrorHandler from "../../error-handler/ErrorHandler";
import axios from "axios";
import { APILINK } from "../../EndPoint";
import { Empty, Spin, Result, Card } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const axiosApiInstance = axios.create();
const { Meta } = Card;

class RoundForum extends Component {
  state = {
    loading: false,
    data: "",
    count: 1,
    current: 1,
    showAdd: false,
    forumID: "",
    title: "",
    details: "",
    showCreate: false,
    err: "",
    disabled: false,
    show: false
  };
  componentDidMount = async () => {
    this.setState({ loading: true });
    this.getForum();
  };
  getForum = async () => {
    let result;

    result = await axiosApiInstance.get(
      APILINK + `/get_forums?course_round_id=${this.props.match.params.id}`
    );

    console.log(result);
    if (result) {
      if (result.data == "Forum not found") {
        this.setState({
          data: "",
          showCreate: true,
          loading: false
        });
      } else if (result.data == "Course Round ID is Invalid") {
        this.setState({
          data: "",
          loading: false,
          err: "Invalid Course Round ID"
        });
      } else {
        this.setState({
          data: result.data,
          loading: false,
          showCreate: false
        });
        console.log(result.data);
      }
    }
  };
  openAddModal = (e) => {
    this.setState({ showAdd: true, forumID: e.target.id }, () =>
      console.log(this.state.forumID)
    );
  };
  hideAdd = (e) => {
    this.setState({ showAdd: false });
  };
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  addQuestion = async () => {
    if (this.state.title == "") {
      setTimeout(() => {
        toast.error(`Please Fill out the required fields !`);
      }, 500);
    } else {
      this.setState({ disabled: true });
      let data = {
        forum_id: this.state.forumID,
        title: this.state.title,
        question_details: this.state.details
      };
      console.log(data.forum_id);
      let result = await axiosApiInstance.post(
        APILINK + `/create_forum_question`,
        data
      );
      if (result.data) {
        console.log(result);
        setTimeout(() => {
          toast.info(`A new Question added successfully`);
        }, 500);
        this.setState({ showAdd: false });
        setTimeout(() => {
          this.props.history.push(
            `/view_fourm_questions/${this.state.forumID}`
          );
        }, 3000);
      }
    }
  };
  showWarning = (e) => {
    this.setState({ show: true, forumID: e.target.id });
  };
  close = (e) => {
    this.setState({ show: false });
  };
  deleteForum = async (e) => {
    let result = await axiosApiInstance.delete(
      APILINK + `/delete_forum/${this.state.forumID}`
    );
    this.setState({ show: false });
    this.getForum();
  };
  render() {
    return (
      <div>
        <Container className="content-margin">
          <Row>
            <Col sm="12">
              <h4 style={{ color: "#124E68" }}>
                <i
                  onClick={() => this.props.history.goBack()}
                  className="fas fa-chevron-left pr-4"
                ></i>
                Round Forum
              </h4>
            </Col>
          </Row>

          <Row className="my-5">
            {this.state.data !== "" &&
            this.state.loading === false &&
            this.state.err == "" ? (
              <Col sm="12" md="4">
                <Card
                  className="my-3"
                  actions={[
                    <>
                      <OverlayTrigger
                        key={"2"}
                        placement={"top"}
                        overlay={
                          <Tooltip id={`tooltip-${"top"}`}>
                            Add Question
                          </Tooltip>
                        }
                      >
                        <i
                          id={this.state.data.id}
                          className="fas fa-plus-circle pr-2"
                          onClick={this.openAddModal}
                        ></i>
                      </OverlayTrigger>
                    </>,
                    <>
                      <OverlayTrigger
                        key={"2"}
                        placement={"top"}
                        overlay={
                          <Tooltip id={`tooltip-${"top"}`}>
                            View Questions
                          </Tooltip>
                        }
                      >
                        <Link
                          to={`/view_fourm_questions/${this.state.data.id}`}
                        >
                          <i className="fas fa-eye pr-2 viewForum"></i>
                        </Link>
                      </OverlayTrigger>
                      
                    </>,
                    <>
                    {this.props.user.user_type === "3" ? (
                        <OverlayTrigger
                          key={"3"}
                          placement={"top"}
                          overlay={
                            <Tooltip id={`tooltip-${"top"}`}>
                              Delete Forum
                            </Tooltip>
                          }
                        >
                          <i
                            onClick={this.showWarning}
                            id={this.state.data.id}
                            className="fas fa fa-trash pr-2"
                          ></i>
                        </OverlayTrigger>
                      ) : null}
                  
                    </>
                  ]}
                >
                  <Meta
                    title={this.state.data.title}
                    description={this.state.data.description}
                  />
                </Card>

                {/* <div className="forumCard my-4">
                  <h5>{this.state.data.title}</h5>
                  <p>{this.state.data.description}</p>{" "}
                  <Button
                    onClick={this.openAddModal}
                    id={this.state.data.id}
                    className="forumBtn"
                  >
                    <i
                      id={this.state.data.id}
                      className="fas fa-plus-circle pr-2"
                    ></i>{" "}
                    Questions
                  </Button>
                  <Button className="forumBtn">
                    {" "}
                    <Link to={`/view_fourm_questions/${this.state.data.id}`}>
                      <i className="fas fa-eye pr-2"></i> Questions
                    </Link>
                  </Button>
                  <Button
                    onClick={this.showWarning}
                    id={this.state.data.id}
                    className="forumBtn"
                  >
                    <i id={this.state.data.id} className="fas fa-trash pr-2" />
                    Forum
                  </Button>
                 
                </div> */}
              </Col>
            ) : this.state.loading ? (
              <Spin className="text-center" tip="...loading" />
            ) : this.state.loading === false && this.state.err == "" ? (
              <Col className="text-center" sm="12">
                {" "}
                <Empty className="text-center" />
                {this.props.user.user_type == "3" ||
                this.props.user.user_type == "1" ? (
                  <Button className="forumBtn1">
                    {" "}
                    <Link to={`/create_forum/${this.props.match.params.id}`}>
                      {" "}
                      <i className="fas fa-plus-circle pr-2"></i> Create Forum?
                    </Link>
                  </Button>
                ) : null}
              </Col>
            ) : this.state.err !== "" ? (
              <Col className="text-center" sm="12">
                <Result status="warning" title="There are some problems." />
              </Col>
            ) : null}
          </Row>
        </Container>
        <Modal show={this.state.showAdd} onHide={this.hideAdd}>
          <Container fluid className="py-4">
            <Form id="infoForm">
              <Row>
                <Col className="text-center pt-2" sm={12}>
                  {" "}
                  <h6>Add Question</h6>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col sm={12}>
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label>
                      <strong style={{ color: "red", paddingRight: "5px" }}>
                        *
                      </strong>
                      Title
                    </Form.Label>
                    <Form.Control
                      name="title"
                      value={this.state.title}
                      onChange={this.handleChange}
                      type="text"
                      placeholder="Title"
                      as="textarea"
                      rows={4}
                    />
                  </Form.Group>
                </Col>
                <Col sm={12} className="mt-2">
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label>Details</Form.Label>
                    <Form.Control
                      name="details"
                      value={this.state.details}
                      onChange={this.handleChange}
                      type="text"
                      placeholder="Details"
                      as="textarea"
                      rows={4}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="">
                <Col sm={12} className="mt-4 text-center">
                  <Button className="m-2 forumBtn1" onClick={this.hideAdd}>
                    Cancel
                  </Button>
                  <Button
                    disabled={this.state.disabled}
                    className="m-2 forumBtn1"
                    onClick={this.addQuestion}
                  >
                    Add Question
                  </Button>{" "}
                </Col>
              </Row>
            </Form>
          </Container>
        </Modal>
        <Modal show={this.state.show} onHide={this.close}>
          <Container className="p-4">
            <Row>
              <Col className="text-center" sm={12}>
                {" "}
                <h6>Are u sure u want to delete this Forum?</h6>
              </Col>
              <Row style={{ margin: "auto" }}>
                <Col md={12}>
                  <Button onClick={this.deleteForum} className="forumBtn1 mx-3">
                    Delete
                  </Button>
                  <Button className="forumBtn1" onClick={this.close}>
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Row>
          </Container>
        </Modal>
        <ToastContainer position="bottom-right" />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isSubject: state.isSubject.isSubject,
  user: state.auth.user
});

export default connect(
  mapStateToProps,
  null
)(ErrorHandler(RoundForum, axiosApiInstance));
