import React, { Component } from "react";
import { Container, Row, Col, Carousel, Button } from "react-bootstrap";
import "../style-sheets/home.css";
import img1 from "../images/Shadow.png";
import slide1 from "../images/slide1.png";
import slide2 from "../images/slide2.svg";
import slide3 from "../images/img3.png";
import slide4 from "../images/slide4.svg";
import slide5 from "../images/slide5.svg";
import slide6 from "../images/slide6.svg";
import axios from "axios";
import HomeCourses from "./sections/HomeCourses";
// import HomeFooter from './layout/HomeFooter'
// import HomeHeader from "./layout/HomeHeader";
//redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { showNav } from "../global-state/actions/showNavAction";
import { LogOut } from "../global-state/actions/authAction";
import { APILINK } from "../EndPoint";
import HomeHeader from "./layout/HomeHeader";
import HomeFooter from "./layout/HomeFooter";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Pagination } from "antd";
import ErrorHandler from "../error-handler/ErrorHandler";
const axiosApiInstance = axios.create();
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      courses: [],
      loading: false,
      count: 1,
      current: 1
    };
  }

  getCourses = async () => {
    this.setState({ loading: true });
    let result;
    if (this.props.isAuth) {
      result = await axiosApiInstance
        .get(APILINK + "/view_courses")
        .then((res) => {
          console.log(res.data);
          this.setState(
            { courses: res.data.data, loading: false, count: res.data.count },
            () => console.log(this.state.loading)
          );
        });
    } else {
      result = await axios.get(APILINK + "/view_courses").then((res) => {
        console.log(res.data);
        this.setState(
          { courses: res.data.data, loading: false, count: res.data.count },
          () => console.log(this.state.loading)
        );
      });
    }
  };

  componentDidMount() {
    this.props.showNav(true);
    this.getCourses();
  }
  onChange = (page) => {
    console.log(page);
    this.setState(
      {
        current: page
      },
      async () => {
        let result = await axiosApiInstance
          .get(APILINK + `/view_courses?page=${this.state.current}`)
          .then((res) => {
            console.log(res);
            this.setState({ courses: res.data.data, count: res.data.count });
          });
      }
    );
  };
  render() {
    console.log(this.state.loading);

    return (
      <div className="homePage py-5">
        <HomeHeader />
        <Container className="homeContainer">
          <Row>
            <Col sm="12"></Col>
          </Row>
          <Row style={{ marginTop: "140px" }}>
            <Carousel className="home-carousel">
              <Carousel.Item>
                <img className="d-block w-100" src={img1} alt="First slide" />
                <Carousel.Caption>
                  <Container fluid>
                    <Row>
                      <Col
                        className="carouselTitles my-auto"
                        style={{ textAlign: "left" }}
                        md="6"
                        sm="12"
                        xs="12"
                      >
                        <h1>Get internationally recognized</h1>
                        <p>
                          Get ready for board exams, through our expert advice
                          and guidance you will achieve your goal of getting
                          licensed in the USA, Canada, Britain or Australia.
                        </p>
                      </Col>
                      <Col md="6" sm="12" xs="12">
                        <img
                          src={slide1}
                          alt="fvg"
                          className="img-fluid carouselImg"
                        />
                      </Col>
                    </Row>
                  </Container>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img className="d-block w-100" src={img1} alt="Second slide" />
                <Carousel.Caption>
                  <Container fluid>
                    <Row>
                      <Col
                        className="carouselTitles my-auto"
                        style={{ textAlign: "left" }}
                        sm="6"
                      >
                        <h1>Get trained by the best</h1>
                        <p>
                          Internationally recognized and top class instructors
                          guiding you through the whole process. Our instructors
                          are experts in their fields and in the process of the
                          board.{" "}
                        </p>

                        {/* <Button>LEARN MORE</Button> */}
                      </Col>
                      <Col sm="6">
                        <img
                          src={slide2}
                          alt="fvg"
                          className="img-fluid carouselImg"
                        />
                      </Col>
                    </Row>
                  </Container>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img className="d-block w-100" src={img1} alt="Second slide" />
                <Carousel.Caption>
                  <Container fluid>
                    <Row>
                      <Col
                        className="carouselTitles my-auto"
                        style={{ textAlign: "left" }}
                        sm="6"
                      >
                        <h1>Creative study methods</h1>
                        <p>
                          Innovative educational techniques that will get you
                          ready in the least time possible and with the least
                          effort. We combine several tools to help you stay on
                          top of your game.
                        </p>
                        {/* <Button>LEARN MORE</Button> */}
                      </Col>
                      <Col sm="6">
                        <img
                          src={slide3}
                          alt="fvg"
                          className="img-fluid carouselImg"
                        />
                      </Col>
                    </Row>
                  </Container>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img className="d-block w-100" src={img1} alt="Second slide" />
                <Carousel.Caption>
                  <Container fluid>
                    <Row>
                      <Col
                        className="carouselTitles my-auto"
                        style={{ textAlign: "left" }}
                        sm="6"
                      >
                        <h1>Professionally designed materials </h1>
                        <p>
                          Study materials that are designed by successful
                          candidates and tailored for the requirements of each
                          board to guarantee your success.
                        </p>
                        {/* <Button>LEARN MORE</Button> */}
                      </Col>
                      <Col sm="6">
                        <img
                          src={slide4}
                          alt="fvg"
                          className="img-fluid carouselImg"
                        />
                      </Col>
                    </Row>
                  </Container>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img className="d-block w-100" src={img1} alt="Second slide" />
                <Carousel.Caption>
                  <Container fluid>
                    <Row>
                      <Col
                        className="carouselTitles my-auto"
                        style={{ textAlign: "left" }}
                        sm="6"
                      >
                        <h1>Learning by doing</h1>
                        <p>
                          We provide more than 4000 interactive practice
                          questions for all subjects of the course, in addition
                          to simulation exams so you can get mentally ready for
                          the real ones.{" "}
                        </p>

                        {/* <Button>LEARN MORE</Button> */}
                      </Col>
                      <Col sm="6">
                        <img
                          src={slide5}
                          alt="fvg"
                          className="img-fluid carouselImg"
                        />
                      </Col>
                    </Row>
                  </Container>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img className="d-block w-100" src={img1} alt="Second slide" />
                <Carousel.Caption>
                  <Container fluid>
                    <Row>
                      <Col
                        className="carouselTitles my-auto"
                        style={{ textAlign: "left" }}
                        sm="6"
                      >
                        <h1>Join a global network</h1>
                        <p>
                          Be part of a lifelong global network. Join and
                          exchange experience with veterinarians all over the
                          world who share the same dreams and aspirations. Image
                          showing the globe
                        </p>
                        {/* <Button>LEARN MORE</Button> */}
                      </Col>
                      <Col sm="6">
                        <img
                          src={slide6}
                          alt="fvg"
                          className="img-fluid carouselImg"
                        />
                      </Col>
                    </Row>
                  </Container>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          </Row>

          <Row>
            <Col style={{ textAlign: "left" }} sm={12}>
              <Button className="home-btn2">Courses</Button>
            </Col>
          </Row>
          {this.state.loading ? (
            <Spin indicator={antIcon} />
          ) : (
            <Row className="py-5">
              {this.state.courses.map((course) => {
                return (
                  <Col key={course.id} sm={4}>
                    <div className="course-card my-4">
                      <HomeCourses
                        key={course.id}
                        title={course.title}
                        image={course.image}
                        cost={course.cost}
                        description={course.description}
                        id={course.id}
                        enroll={course.enroll}
                        // studentEnroll={course.student_enroll}
                      />
                    </div>
                  </Col>
                );
              })}
            </Row>
          )}
          <Row className="p-4">
            {this.state.courses.length !== 0 ? (
              <Pagination
                onChange={this.onChange}
                defaultCurrent={this.state.current}
                total={this.state.count}
                current={this.state.current}
              />
            ) : null}
          </Row>
        </Container>
        <HomeFooter />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuth: state.auth.isAuth
});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ showNav, LogOut }, dispatch);
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ErrorHandler(Home, axiosApiInstance));
