import React, { Component } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import axios from "axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { APILINK } from "../../EndPoint";
import { ToastContainer, toast } from "react-toastify";
import "../../style-sheets/createCourse.css";
import {Select} from 'antd'

const axiosApiInstance = axios.create();
class EditTutor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
      emailErr: "",
      countries: [],
      selectedCountryID: "",
      selectedCountry:""
    };
  }

  handleChange = (e) => {
    if (
      e.target.name == "city" ||
      e.target.name == "fullName" ||
      e.target.name == "lname"
    ) {
      if (e.target.value.match(/^[a-zA-Z ]*$/)) {
        this.setState({ [e.target.name]: e.target.value });
      }
    } else if (e.target.name == "email") {
      if (
        e.target.value.match(
          /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/
        )
      ) {
        this.setState({ [e.target.name]: e.target.value });
      }
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  getDetails = async () => {
    let result = await axiosApiInstance.get(
      APILINK + `/get_del_user/${this.props.match.params.id}`
    );
    this.setState({
      fullName: result.data.full_name,
      email: result.data.email,
      selectedCountry: result.data.country,
      phone: result.data.mobile,
      city: result.data.city,
      gender: result.data.gender,
      lname: result.data.last_name,
    });
    console.log(result);
  };
  componentDidMount() {
    this.getDetails();
  }

  edit = async () => {
    this.setState({ disabled: true });

    let data = {
      gender: this.state.gender,
      email: this.state.email,
      full_name: this.state.fullName,
      mobile: this.state.phone,
      city: this.state.city,
      country: this.state.selectedCountry,
      last_name: this.state.lname,
    };
    let result = await axiosApiInstance.put(
      APILINK + `/tutor_signup?tutor_id=${this.props.match.params.id}`,
      data
    );
    console.log(result);
    if (result.status == 200) {
      setTimeout(() => {
        toast.success("Tutor Updated Successfully");
      }, 2000);
      setTimeout(() => {
        this.props.history.push(`/view_tutors`);
      }, 3500);
    }
  };

  getCountries = () => {
    let result = axios.get(APILINK + "/get_countries").then((res) => {
      this.setState({ countries: res.data });
    });
  };
  handleCountries = (v, e) => {
    console.log(e);
    this.setState({
      selectedCountry: e.value,
      selectedCountryID: e.id,
    });
  };
  render() {
    return (
      <div className="admin-home pb-5">
        <Container className="content-margin">
          <Form id="infoForm">
            <Row>
              <Col sm={6}>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    name="fullName"
                    value={this.state.fullName}
                    onChange={this.handleChange}
                    type="text"
                    placeholder="First Name"
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    name="lname"
                    value={this.state.lname}
                    onChange={this.handleChange}
                    type="text"
                    placeholder="Last Name"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={6}>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    name="email"
                    value={this.state.email}
                    onChange={this.handleChange}
                    type="email"
                    placeholder="Email"
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    name="phone"
                    value={this.state.phone}
                    onChange={this.handleChange}
                    type="number"
                    placeholder="Phone"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Country</Form.Label>
                  <Select
                    allowClear
                    placeholder="select country"
                    value={this.state.selectedCountry}
                    onChange={this.handleCountries}
                    style={{ width: "100%" }}
                    onClick={this.getCountries}
                    showSearch
                  >
                    {this.state.countries.map((country) => (
                      <Select.Option id={country.id} value={country.name}>
                        {country.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>city</Form.Label>
                  <Form.Control
                    name="city"
                    value={this.state.city}
                    onChange={this.handleChange}
                    type="text"
                    placeholder="city"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm="6">
                <Form.Label>Gender</Form.Label>
                <Form.Control
                  name="gender"
                  value={this.state.gender}
                  onChange={this.handleChange}
                  as="select"
                >
                  <option selected style={{ display: "none" }}>
                    I am a
                  </option>
                  <option value="1">Male</option>
                  <option value="2">Female</option>
                </Form.Control>
              </Col>
            </Row>
          </Form>
          <Row className="mt-3">
            <Col sm="12">
              <Button
                disabled={this.state.disabled}
                className="forumBtn1"
                onClick={this.edit}
              >
                Edit Tutor
              </Button>
            </Col>
          </Row>
        </Container>
        <ToastContainer position="bottom-right" />
      </div>
    );
  }
}

export default ErrorHandler(EditTutor, axiosApiInstance);
