import React, { Component } from "react";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { APILINK } from "../../EndPoint";
import axios from "axios";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { DatePicker } from "antd";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";

const axiosApiInstance = axios.create();

class EditQuiz extends Component {
  constructor(props) {
    super(props);
    this.state = {
      details: "",
      title: "",
      startDate: "",
      endDate: "",
      expirationTime: "",
      subjectRound: "",
      grade: "",
      disabled: false,

    };
  }
  getQuizDetails = async () => {
    let result = await axiosApiInstance.get(
      APILINK + `/edit_quiz/${this.props.match.params.id}`
    );
    console.log(result);
    this.setState({
      title: result.data.title,
      grade: result.data.passing_grade,
      startDate: result.data.start_date.date,
      endDate: result.data.end_date.date,
      expirationTime: result.data.expiration_time,
    });
  };
  componentDidMount = async () => {
    this.getQuizDetails();
  };
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleStartDate = (date, dateString) => {
    // console.log(date, dateString);
    this.setState({ startDate: dateString });
  };
  handleEndDate = (date, dateString) => {
    // console.log(date, dateString);
    this.setState({ endDate: dateString });
  };
  editQuiz = async () => {
    this.setState({ disabled: true });

    let data = {
      expiration_time: this.state.expirationTime,
      title: this.state.title,
      passing_grade: this.state.grade,
      start_date_: this.state.startDate,
      end_date_: this.state.endDate,
    };
    let result = await axiosApiInstance
      .put(APILINK + `/edit_quiz/${this.props.match.params.id}`, data)
      .then((res) => {
        setTimeout(() => {
          toast.info("Quiz has been updated successfully");
        }, 500);
        setTimeout(() => {
          this.props.history.goBack();
        }, 2500);
      })
      .catch((err) => {
        this.setState({ disabled: false });

        console.log(err);
      });
  };
  render() {
    console.log(this.props);
    return (
      <div className="admin-home pb-5">
        <Container className="content-margin">
          <h2>Edit Quiz</h2>
          <Form id="infoForm">
            <Row>
              <Col sm={6}>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Quiz Title</Form.Label>
                  <Form.Control
                    name="title"
                    value={this.state.title}
                    onChange={this.handleChange}
                    type="text"
                    placeholder="Course Title"
                    maxlength="60"

                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Passing Grade</Form.Label>
                  <Form.Control
                    name="grade"
                    value={this.state.grade}
                    onChange={this.handleChange}
                    type="text"
                    placeholder="Passing Grade"
                    maxlength="10"

                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm="6">
                <Form.Label>Quiz start date</Form.Label>
                <DatePicker
                  value={moment(this.state.startDate)}
                  onChange={this.handleStartDate}
                />
              </Col>
              <Col sm="6">
                <Form.Label>Quiz end date</Form.Label>
                <DatePicker
                  value={moment(this.state.endDate)}
                  onChange={this.handleEndDate}
                />
              </Col>
            </Row>
            <Row>
              <Col sm="6">
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Expiration Time</Form.Label>
                  <Form.Control
                    name="expirationTime"
                    value={this.state.expirationTime}
                    onChange={this.handleChange}
                    type="text"
                    placeholder="Expiration Time"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <Button
                  disabled={this.state.disabled}
                  className="forumBtn1 mt-4"
                  onClick={this.editQuiz}
                >
                  Save changes
                </Button>
              </Col>
            </Row>
          </Form>
        </Container>
        <ToastContainer position="bottom-right" />
      </div>
    );
  }
}
export default ErrorHandler(EditQuiz, axiosApiInstance);
