import React, { useState } from "react";
import axios from "axios";
import { APILINK } from "../../EndPoint";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { Container, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { DatePicker, Input, Button } from "antd";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const axiosApiInstance = axios.create();
const CreateQuiz = (props) => {
  const [disabled, setDisabled] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();
  const onSubmit = async (data) => {
    setDisabled(true);

    data.expiration_time = data.expiration_time + ":00:00";
    data.subject_round_id = props.match.params.id;
    let result = await axiosApiInstance
      .post(APILINK + `/create_quiz`, data)
      .then((res) => {
        setTimeout(() => {
          toast.info("Quiz has been created successfully");
        }, 500);
        setTimeout(() => {
          props.history.push(`/view_quiz/${props.match.params.id}`);
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
        setDisabled(false);
      });
  };

  const getChangeHandlerWithValue = (name) => (value, val2) => {
    setValue(name, val2);
  };
  return (
    <Container className="viewSuper align-left">
      <Row>
        <Col lg={12}>
          <h2>Create Quiz</h2>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col lg={6}>
                <label className="label">Quiz Title</label>
                <Input
                  {...register("title", { required: true })}
                  maxlength="60"
                />

                <p className="error">
                  {errors.title?.type === "required" &&
                    "This field is required"}
                </p>
              </Col>

              <Col lg={6}>
                <label className="label">Passing Grade</label>
                {/* <Controller
                                    control={control}
                                    name="passing_grade"
                                    render={({ field }) => <Input type="number" {...field} />}
                                /> */}
                <Input
                  type="number"
                  {...register("passing_grade", { required: true })}
                />
                <p className="error">
                  {errors.passing_grade?.type === "required" &&
                    "This field is required"}
                </p>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <label className="label">Start Date</label>

                <DatePicker
                  {...register("start_date_", { required: true })}
                  className="w-100"
                  name="start_date_"
                  onChange={getChangeHandlerWithValue("start_date_")}
                />
                <p className="error">
                  {errors.start_date_?.type === "required" &&
                    "This field is required"}
                </p>
              </Col>
              <Col lg={6}>
                <label className="label">End Date</label>
                <DatePicker
                  {...register("end_date_", { required: true })}
                  className="w-100"
                  name="end_date_"
                  onChange={getChangeHandlerWithValue("end_date_")}
                />
                <p className="error">
                  {errors.end_date_?.type === "required" &&
                    "This field is required"}
                </p>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <label className="label">Expiration Time in Hours</label>
                <Input
                  type="number"
                  {...register("expiration_time", {
                    required: true,
                    min: 1,
                    max: 23,
                  })}
                />
                <p className="error">
                  {errors.expiration_time?.type === "required" &&
                    "This field is required"}
                </p>
                <p className="error">
                  {errors.expiration_time?.type === "min" &&
                    "Minimum Value is 1"}
                </p>
                <p className="error">
                  {errors.expiration_time?.type === "max" &&
                    "Maximum Value is 23"}
                </p>
              </Col>
            </Row>
            <Button
              disabled={disabled}
              className="forumBtn1"
              type="submit"
              onClick={handleSubmit(onSubmit)}
            >
              Create
            </Button>
          </form>
        </Col>
      </Row>

      <ToastContainer position="bottom-right" />
    </Container>
  );
};

export default ErrorHandler(CreateQuiz, axiosApiInstance);
