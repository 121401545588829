import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import img1 from "../images/logoFooter.PNG";

export default function policies(props) {
  return (
    <div className="loginPage py-5">
      <Container className="loginContainer p-5">
        <Row>
          <Col className="text-left" sm="12">
            <h3 style={{ color: "#124E68" }}>
              <i
                onClick={() => props.history.goBack()}
                className="fas fa-chevron-left pr-4"
              ></i>
              Privacy policies
            </h3>
          </Col>
        </Row>
        <Row className="paddingLeft">
          <Col className="text-left" sm="12" md="8">
            All rights reserved. No part of our publications may be reproduced,
            distributed, or transmitted in any form or by any means, including
            photocopying, recording, or other electronic or mechanical methods,
            without the prior written permission of the publisher, except in the
            case of brief quotations embodied in critical reviews and certain
            other noncommercial uses permitted by copyright law. For permission
            requests, write to the publisher, AVEC
          </Col>
        </Row>
      </Container>
    </div>
  );
}
