import React, { useEffect, useCallback, useState } from "react";
import axios from "axios";
import { APILINK } from "../../EndPoint";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { Container, Row, Col, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Checkbox, Input, Select, Button, DatePicker, TimePicker } from "antd";
import { ToastContainer, toast } from "react-toastify";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

const axiosApiInstance = axios.create();
const { TextArea } = Input;

const CreateMeeting = (props) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm();
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {}, []);

  const onSubmit = async (data) => {
    setDisabled(true);
    data.subject_round_id = props.match.params.id;
    data.start_time = time;
    data.start_date = date;
    console.log(data);
    let result = await axiosApiInstance
      .post(APILINK + `/create_meeting`, data)
      .catch((err) => {
        console.log(err);
        setDisabled(false);
      });
    console.log(result);
    if (result) {
      setTimeout(() => {
        toast.info("Meeting has been created successfully");
      }, 500);
      setTimeout(() => {
        props.history.push(`/view_lectures/${props.match.params.id}`);
      }, 3500);
    }
  };
  const handleStartDate = (date, dateString) => {
    console.log(date, dateString);
    setDate(dateString);
  };
  const handleTime = (time, timeString) => {
    console.log(timeString);
    setTime(timeString);
  };
  return (
    <Container className="viewSuper align-left">
      <Row>
        <Col sm="4">
          {" "}
          <h2>Create Meeting</h2>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col lg={6}>
                <label className="label">Lecture Title</label>
                <Input
                  placeholder="Title"
                  {...register("title", { required: true })}
                />

                <p style={{ color: "red" }}>
                  {errors.title?.type === "required" &&
                    "This field is required"}
                </p>
              </Col>
            </Row>

            <Row>
              <Col lg={6}>
                <label className="label">Zoom Meeting Link</label>
                <Input
                  placeholder="Link"
                  {...register("link", { required: true })}
                />

                <p style={{ color: "red" }}>
                  {errors.link?.type === "required" && "This field is required"}
                </p>
              </Col>

              <Col lg={6}>
                <label className="label">Duration</label>

                <Input
                  placeholder="Duration in 00:00:00"
                  type="text"
                  {...register("duration", { required: true })}
                />
                <p style={{ color: "red" }}>
                  {errors.duration?.type === "required" &&
                    "This field is required"}
                </p>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <label className="label">Date</label>
                <DatePicker
                  className="meetingDate"
                  onChange={handleStartDate}
                />
              </Col>
              <Col lg={6}>
                <label className="label">Start Time</label>
                <TimePicker
                  onChange={handleTime}
                  defaultValue={moment("00:00:00", "HH:mm:ss")}
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg={12}>
                <Button
                  disabled={disabled}
                  className="forumBtn1"
                  type="submit"
                  onClick={handleSubmit(onSubmit)}
                >
                  Create
                </Button>
              </Col>
            </Row>
          </form>
        </Col>
      </Row>

      <ToastContainer position="bottom-right" />
    </Container>
  );
};
const mapStateToProps = (state) => ({
  isQuiz: state.isQuiz.isQuiz
});
export default connect(mapStateToProps)(
  ErrorHandler(CreateMeeting, axiosApiInstance)
);
