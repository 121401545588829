import React, { Component } from "react";
import ErrorHandler from "../../error-handler/ErrorHandler";
import axios from "axios";
import { Col, Container, Row } from "react-bootstrap";
import { APILINK } from "../../EndPoint";
import { Calendar, Alert, Spin } from "antd";
import CalenderDetails from "./CalenderDetails";
import moment from "moment";

const axiosApiInstance = axios.create();

class CalendarComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [{ title: "test-event", date: "2021-03-22" }],
      value: moment(),
      selectedValue: moment(),
      eventsDays: [],
      selectedDateData: [],
      loading: false,
    };
  }

  componentDidMount = async () => {
    this.loadCalendarData();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.selectedValue.month() !== this.state.selectedValue.month()) {
      this.loadCalendarData();
    }
  };

  loadCalendarData = async () => {
    this.changeLoader(true);
    try {
      const response = await axiosApiInstance.get(
        `${APILINK}/calender?month=${this.state.selectedValue.format("MM")}`
      );
      const { subject_rounds, meeting, quiz } = response.data;

      const eventsDays = this.extractEventDays([...subject_rounds, ...meeting, ...quiz]);

      this.setState({
        daysData: subject_rounds,
        meetings: meeting,
        quizes: quiz,
        eventsDays,
      }, this.highlightSelectedEvents);
    } catch (error) {
      console.error("Failed to load calendar data:", error);
    } finally {
      this.changeLoader(false);
    }
  };

  extractEventDays = (events) => {
    return events.map((event) => {
      const day = event.start_date?.date || event.date?.date;
      return parseInt(day.split("-")[2].replace(/^0/, ""), 10);
    });
  };

  highlightSelectedEvents = () => {
    const eventElements = document.getElementsByClassName("events");
    const dateElements = document.getElementsByClassName("ant-picker-calendar-date");
    
    Array.from(dateElements).forEach((dateElement, index) => {
      dateElement.classList.remove("selectedEvents");
      if (eventElements[index]?.childNodes.length) {
        dateElement.classList.add("selectedEvents");
      }
    });
  };

  changeLoader = (loading) => {
    this.setState({ loading });
  };

  onSelect = (value) => {
    const selectedDay = value.format("YYYY-MM-DD");
    const selectedDateData = this.filterEventsByDate(selectedDay);
    this.setState({ value, selectedValue: value, selectedDateData, selectedDay });
  };

  filterEventsByDate = (selectedDay) => {
    const { daysData, meetings, quizes } = this.state;
    return [
      ...daysData.filter((item) => item.start_date.date === selectedDay),
      ...meetings.filter((item) => item.date.date === selectedDay),
      ...quizes.filter((item) => item.start_date.date === selectedDay),
    ];
  };

  onPanelChange = (value) => {
    this.setState({ value });
  };

  getListData = (value) => {
    const currentMonth = this.state.selectedValue.format("YY-MM");
    const valueMonth = value.format("YY-MM");
    
    if (currentMonth === valueMonth && this.state.eventsDays.includes(value.date())) {
      return [{ type: "success", content: "Action needed" }];
    }
    return [];
  };

  dateCellRender = (value) => {
    const listData = this.getListData(value);
    return (
      <ul className="events">
        {listData.map((item) => (
          <li key={item.content} style={{ listStyle: "none" }}>
            {/* <Badge status={item.type} text={item.content} /> */}
          </li>
        ))}
      </ul>
    );
  };

  render() {
    return (
      <div>
        <Container className="py-3 studentcalender">
          <Row>
            <Col sm={8}>
              <Alert
                message={`You selected date: ${this.state.selectedValue.format("YYYY-MM-DD")}`}
              />
              <Calendar
                value={this.state.value}
                onSelect={this.onSelect}
                onPanelChange={this.onPanelChange}
                dateCellRender={this.dateCellRender}
                fullscreen={false}
              />
            </Col>
            <Col sm={4}>
              <CalenderDetails
                meetings={this.state.meetings}
                details={this.state.selectedDateData}
                quizes={this.state.quizes}
                selectedDay={this.state.selectedDay}
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default ErrorHandler(CalendarComponent, axiosApiInstance);
