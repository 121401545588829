import React from "react";
import PDF from "react-pdf-watermark";
class TestLogin extends React.Component {
  state = {};

  handlePrevious = () => {
    this.setState({ page: this.state.page - 1 });
  };

  handleNext = () => {
    this.setState({ page: this.state.page + 1 });
  };

  renderPagination = (page, pages) => {
    let previousButton = (
      <li className="previous" onClick={this.handlePrevious}>
        <a href="#">
          <i className="fa fa-arrow-left"></i> Previous
        </a>
      </li>
    );
    if (page === 1) {
      previousButton = (
        <li className="previous disabled">
          <a href="#">
            <i className="fa fa-arrow-left"></i> Previous
          </a>
        </li>
      );
    }
    let nextButton = (
      <li className="next" onClick={this.handleNext}>
        <a href="#">
          Next <i className="fa fa-arrow-right"></i>
        </a>
      </li>
    );
    if (page === pages) {
      nextButton = (
        <li className="next disabled">
          <a href="#">
            Next <i className="fa fa-arrow-right"></i>
          </a>
        </li>
      );
    }
    return (
      <nav>
        <ul className="pager">
          {previousButton}
          {nextButton}
        </ul>
      </nav>
    );
  };
  applyWatermark = (canvas, context) => {

    context.globalAlpha = 0.15;
    context.font = "55px bold Arial";
    context.translate(canvas.width / 2, canvas.height / 2);
    context.rotate(-Math.atan(canvas.height / canvas.width)); // Rotate watermark to show diagonally

    const text = "Strictly Confidential. Not to be circulated";
    let metrics = context.measureText(text);
    context.fillText(text, -metrics.width / 2, 55 / 2);
  };

  render() {
    let pagination = null;
    if (this.state.pages) {
      pagination = this.renderPagination(this.state.page, this.state.pages);
    }
    return (
      <div>
        <PDF
          file="https://ucf0484cc6e1ae6161d40743f9db.dl.dropboxusercontent.com/cd/0/get/BYXkCmB7pSEd7T2Wh4su7qH32LRs30lDKhQqA4hCgbi27Fdn-WeRxuw8LEThs9JGOw6y3LSiLByZJp8SA8RwEj4aN5otSYastKaRGuwq8c32AzQ9HpwvXMnVWT62eVIcrlSEJIj6nXd7wc-5Q5gvuxvb/file#"
          page={this.state.page}
          watermark={this.applyWatermark}
          watermarkOptions={{
            transparency: 0.5,
            fontSize: 55,
            fontStyle: "Bold",
            fontFamily: "Arial",
            transform: "rotate(90deg)",
          }}
          onDocumentComplete={() => {
            /* Do anything on document loaded like remove loading, etc */
          }}
          onPageRenderComplete={(pages, page) => {
            this.setState({ page, pages });
            console.log(page);
          }}
        />
        {pagination}
      </div>
    );
  }
}

export default TestLogin;
