import React, { Component } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import axios from "axios";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { APILINK } from "../../EndPoint";
import "../../style-sheets/createCourse.css";
import HomeCourses from "../sections/HomeCourses";
import { LoadingOutlined } from "@ant-design/icons";
import { Pagination, Spin, Empty } from "antd";
import { connect } from "react-redux";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const axiosApiInstance = axios.create();
class Courses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      courses: [],
      count: 1,
      current: 1,
      loading: false
    };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  createCourse = async () => {
    let data = {
      title: this.state.title,
      cost: this.state.cost,
      description: this.state.desc,
      installment_policy: this.state.policy
    };
    console.log(data);
    let result = await axiosApiInstance.post(APILINK + "/create_course", data);
    console.log(result);
  };
  addRound = (e) => {
    console.log(e.target.value);
    this.props.history.push(`/add_round/${e.target.id}`);
  };
  viewRounds = (e) => {
    this.props.history.push(`/view_all_rounds/${e.target.id}`);
  };
  addCourse = () => {
    this.props.history.push(`/create-course`);
  };
  viewSubjects = (e) => {
    this.props.history.push(`/subjects/${e.target.id}`);
  };
  addSubject = (e) => {
    this.props.history.push(`/create-subject/${e.target.id}`);
  };

  getCourses = async () => {
    let result = await axiosApiInstance
      .get(APILINK + "/view_courses")
      .then((res) => {
        console.log(res);
        this.setState({
          courses: res.data.data,
          count: res.data.count,
          loading: false
        });
      });
  };
  onChange = (page) => {
    console.log(page);
    this.setState(
      {
        current: page
      },
      async () => {
        let result = await axiosApiInstance
          .get(APILINK + `/view_courses?page=${this.state.current}`)
          .then((res) => {
            console.log(res);
            this.setState({ courses: res.data.data, count: res.data.count });
          });
      }
    );
  };
  componentDidMount() {
    this.setState({ loading: true });
    this.getCourses();
  }

  render() {
    return (
      <div className="admin-home pb-5">
        <Container className="content-margin">
          <Row>
            <Col style={{ textAlign: "left" }} sm={12}>
              <Button className="home-btn2">Courses</Button>
            </Col>
          </Row>
          {this.state.courses.length !== 0 && this.state.loading == false ? (
            <Row className="py-5">
              {this.state.courses.map((course) => {
                return (
                  <Col className="card-col" sm={4}>
                    <div className="course-card my-4">
                      <HomeCourses
                        title={course.title}
                        cost={course.cost}
                        installmentCost={course.installments_cost}
                        description={course.description}
                        image={course.image}
                        id={course.id}
                        getCourses={this.getCourses}
                        // studentEnroll={course.student_enroll}
                      />
                      {this.props.user.user_type === "3" ? (
                        <>
                          {/* <div className="homeCoursesBtn mb-5">
                          <Button
                             className="courseBtn1 my-2"
                             id={course.id}
                             onClick={this.addRound}
                           >
                             <i
                               id={course.id}
                               onClick={this.addRound}
                               className="fas fa-plus-circle pr-2"
                             ></i>{" "}
                             Round
                           </Button>
                           <Button
                             className="my-2 courseBtn1 ml-1"
                             id={course.id}
                             onClick={this.viewRounds}
                           >
                             <i
                               id={course.id}
                               onClick={this.viewRounds}
                               className="fas fa-eye pr-2"
                             ></i>
                             Rounds
                           </Button>
                           <Button
                             className="courseBtn1 my-2"
                             id={course.id}
                             onClick={this.addSubject}
                           >
                             <i
                               id={course.id}
                               onClick={this.addSubject}
                               className="fas fa-plus-circle pr-2"
                             ></i>
                             Subjects
                           </Button>
                           <Button
                             className="courseBtn1 my-2 ml-1"
                             id={course.id}
                             onClick={this.viewSubjects}
                           >
                             <i
                               id={course.id}
                               onClick={this.viewSubjects}
                               className="fas fa-eye pr-2"
                             ></i>
                             Subjects
                           </Button>
                         </div> */}
                          <Row>
                            <Col xs="6">
                              <Button
                                className="courseBtn1 my-2"
                                id={course.id}
                                onClick={this.addRound}
                              >
                                <i
                                  id={course.id}
                                  onClick={this.addRound}
                                  className="fas fa-plus-circle pr-2"
                                ></i>{" "}
                                Round
                              </Button>
                            </Col>
                            <Col xs="6" className="">
                              {" "}
                              <Button
                                className="my-2 courseBtn1 ml-1"
                                id={course.id}
                                onClick={this.viewRounds}
                              >
                                <i
                                  id={course.id}
                                  onClick={this.viewRounds}
                                  className="fas fa-eye pr-2"
                                ></i>
                                Rounds
                              </Button>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs="6">
                              <Button
                                className="courseBtn1 my-2"
                                id={course.id}
                                onClick={this.addSubject}
                              >
                                <i
                                  id={course.id}
                                  onClick={this.addSubject}
                                  className="fas fa-plus-circle pr-2"
                                ></i>
                                Subjects
                              </Button>
                            </Col>
                            <Col xs="6" className="">
                              {" "}
                              <Button
                                className="courseBtn1 my-2 ml-1"
                                id={course.id}
                                onClick={this.viewSubjects}
                              >
                                <i
                                  id={course.id}
                                  onClick={this.viewSubjects}
                                  className="fas fa-eye pr-2"
                                ></i>
                                Subjects
                              </Button>
                            </Col>
                          </Row>
                        </>
                      ) : this.props.user.user_type === "1" ? (
                        <div className="ml-5 mb-5">
                          <Button
                            className="ml-2 courseBtn1"
                            id={course.id}
                            onClick={this.viewRounds}
                          >
                            <i
                              id={course.id}
                              onClick={this.viewRounds}
                              className="fas fa-eye pr-2"
                            ></i>
                            Rounds
                          </Button>

                          <Button
                            className="courseBtn1 ml-2"
                            id={course.id}
                            onClick={this.viewSubjects}
                          >
                            <i
                              id={course.id}
                              onClick={this.viewSubjects}
                              className="fas fa-eye pr-2"
                            ></i>
                            Subjects
                          </Button>
                        </div>
                      ) : null}
                    </div>
                  </Col>
                );
              })}
            </Row>
          ) : this.state.loading && this.state.courses.length == 0 ? (
            <Row className="py-5">
              <Spin tip="...loading" />
            </Row>
          ) : (
            <Empty />
          )}

          <Row>
            {this.state.courses.length !== 0 ? (
              <Pagination
                onChange={this.onChange}
                defaultCurrent={this.state.current}
                total={this.state.count}
                current={this.state.current}
              />
            ) : null}
          </Row>
        </Container>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.auth.user
});

export default connect(
  mapStateToProps,
  null
)(ErrorHandler(Courses, axiosApiInstance));
