import React, { Component } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import {
  Container,
  Row,
  Col,
  Button,
  OverlayTrigger,
  Tooltip,
  Modal,
  Form,
  Table,
} from "react-bootstrap";
// import { Link } from "react-router-dom";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { APILINK } from "../../EndPoint";
import { Link } from "react-router-dom";
import {
  Select,
  DatePicker,
  Radio,
  TimePicker,
  Pagination,
  Spin,
  message,
} from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isSubject } from "../../global-state/actions/isSubjectAction";
const axiosApiInstance = axios.create();
class AssignedCourses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      tutors: [],
      data2: [],
      count: 1,
      loading: false,
      policy: "",
      current: 1,
      cost: 0,
      imageName: "",
      showSubjectModel: false,
      showSubjectRoundForm: false,
      showEnroll: false,
      showEdit: false,
      visaPay: true,
      transferPay: false,
      startDate: "",
      endDate: "",
      showDelete: false,
      disabled: false,
      columns: [
        {
          name: "Round Version",
          selector: "version",
          sortable: true,
          right: true,
        },
        {
          name: "Course",
          selector: "course.title",
          sortable: true,
          right: true,
        },
        {
          name: "Start Date",
          selector: "start_date.date",
          sortable: true,
          right: true,
        },
        {
          name: "End Date",
          selector: "end_date.date",
          sortable: true,
          right: true,
        },
        {
          name: "Actions",
          button: true,
          width: "200px",
          cell: (row) => (
            <div className="table-data-feature">
              {this.props.user.user_type === "3" ? (
                <React.Fragment>
                  <OverlayTrigger
                    key={"4"}
                    placement={"top"}
                    overlay={
                      <Tooltip id={`tooltip-${"top"}`}>Delete Round</Tooltip>
                    }
                  >
                    <i
                      id={row.id}
                      onClick={this.showWarning}
                      className="fas fa-trash pr-2"
                    ></i>
                  </OverlayTrigger>
                  <OverlayTrigger
                    key={"4"}
                    placement={"top"}
                    overlay={
                      <Tooltip id={`tooltip-${"top"}`}>Select Subjects</Tooltip>
                    }
                  >
                    <i
                      id={row.id}
                      onClick={this.openSubject}
                      className="fas fa-plus pr-2"
                    ></i>
                  </OverlayTrigger>
                  <OverlayTrigger
                    key={"3"}
                    placement={"top"}
                    overlay={
                      <Tooltip id={`tooltip-${"top"}`}>Edit Round</Tooltip>
                    }
                  >
                    <i
                      onClick={this.showEdit}
                      id={row.id}
                      className="fas fa-edit pr-2"
                    ></i>
                  </OverlayTrigger>
                  <OverlayTrigger
                    key={"2"}
                    placement={"top"}
                    overlay={
                      <Tooltip id={`tooltip-${"top"}`}>
                        View Subject Round
                      </Tooltip>
                    }
                  >
                    <Link to={`/subject-rounds/${row.id}`}>
                      {" "}
                      <i className="fas fa-eye pr-2"></i>
                    </Link>
                  </OverlayTrigger>
                  <OverlayTrigger
                    key={"2"}
                    placement={"top"}
                    overlay={
                      <Tooltip id={`tooltip-${"top"}`}>Create Training</Tooltip>
                    }
                  >
                    <Link to={`/create_training/${row.id}`}>
                      {" "}
                      <i
                        onClick={this.props.isSubject(false)}
                        className="fas fa-plus-circle pr-2"
                      ></i>
                    </Link>
                  </OverlayTrigger>
                  <OverlayTrigger
                    key={"2"}
                    placement={"top"}
                    overlay={
                      <Tooltip id={`tooltip-${"top"}`}>View Trainings</Tooltip>
                    }
                  >
                    <Link to={`/view_training/${row.id}`}>
                      {" "}
                      <i
                        onClick={this.props.isSubject(false)}
                        className="fas fa-eye pr-2"
                      ></i>
                    </Link>
                  </OverlayTrigger>
                  <OverlayTrigger
                    key={"2"}
                    placement={"top"}
                    overlay={
                      <Tooltip id={`tooltip-${"top"}`}>View Forum</Tooltip>
                    }
                  >
                    <Link to={`/view_round_fourm/${row.id}`}>
                      {" "}
                      <i className="fas fa-eye pr-2"></i>
                    </Link>
                  </OverlayTrigger>
                </React.Fragment>
              ) : this.props.user.user_type === "2" ? (
                <Button
                  className="forumBtn1"
                  id={row.id}
                  onClick={this.showEnrollModal}
                >
                  Enroll
                </Button>
              ) : (
                <React.Fragment>
                  <OverlayTrigger
                    key={"2"}
                    placement={"top"}
                    overlay={
                      <Tooltip id={`tooltip-${"top"}`}>
                        View Subject Round
                      </Tooltip>
                    }
                  >
                    <Link to={`/subject-rounds/${row.id}`}>
                      {" "}
                      <i className="fas fa-eye pr-2"></i>
                    </Link>
                  </OverlayTrigger>

                  <OverlayTrigger
                    key={"2"}
                    placement={"top"}
                    overlay={
                      <Tooltip id={`tooltip-${"top"}`}>
                        View Enrolled Students
                      </Tooltip>
                    }
                  >
                    <Link to={`/view_students/${row.id}`}>
                      {" "}
                      <i className="fas fa-eye pr-2"></i>
                    </Link>
                  </OverlayTrigger>
                  <OverlayTrigger
                    key={"2"}
                    placement={"top"}
                    overlay={
                      <Tooltip id={`tooltip-${"top"}`}>View Forum</Tooltip>
                    }
                  >
                    <Link to={`/view_round_fourm/${row.id}`}>
                      {" "}
                      <i className="fas fa-eye pr-2"></i>
                    </Link>
                  </OverlayTrigger>
                </React.Fragment>
              )}
            </div>
          ),
        },
      ],
    };
  }
  visaPay = () => {
    this.setState({ visaPay: true, transferPay: false });
  };

  transferPay = () => {
    this.setState({ visaPay: false, transferPay: true });
  };

  handleImage = (e) => {
    console.log(e.target.files);
    this.setState({
      image: e.target.files[0],
      imageName: e.target.files[0].name,
    });
  };

  selectSubject = (e) => {
    console.log("radio checked", e);

    this.setState({ subjectID: e.target.value });
    if (e.target.checked) {
      this.setState({ showSubjectRoundForm: true });
    }
  };

  showEnrollModal = (e) => {
    this.setState({ roundID: e.target.id, showEnroll: true }, () => {
      console.log(this.state.roundID);
    });
  };
  hideEnrollModal = (e) => {
    this.setState({ showEnroll: false });
  };

  getRounds = async () => {
    this.setState({ loading: true });
    let result;
    if (this.props.user.user_type == "1") {
      result = await axiosApiInstance.get(APILINK + `/create_course_round`);
    } else {
      result = await axiosApiInstance.get(
        APILINK + `/create_course_round?course_id=${this.props.match.params.id}`
      );
    }

    console.log(result);
    this.setState({
      data: result.data.data,
      count: result.data.count,
      loading: false,
    });
  };
  componentDidMount() {
    this.getRounds();
    if (this.props.user.user_type == 2) {
      this.success();
    }
  }
  success = () => {
    message.warning(
      "Please note that you can join course rounds That has been already started",
      10
    );
  };
  openSubject = (e) => {
    console.log(e.target.id);
    this.getSubjects();
    this.setState({ showSubjectModel: true, courseRoundID: e.target.id });
  };
  hideSubject = () => {
    this.setState({ showSubjectModel: false });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  showEdit = (e) => {
    console.log(e.target.id);
    this.setState({ roundID: e.target.id }, () => {
      this.setState({ showEdit: true });
      this.getRoundDetails();
    });
  };
  hideEdit = (e) => {
    this.setState({ showEdit: false });
  };

  handleStartDate = (date, dateString) => {
    // console.log(date, dateString);
    this.setState({ startDate: dateString });
  };
  handleEndDate = (date, dateString) => {
    // console.log(date, dateString);
    this.setState({ endDate: dateString });
  };

  getRoundDetails = async () => {
    let result = await axiosApiInstance.get(
      APILINK + `/course_round_details/${this.state.roundID}`
    );
    console.log(result);
    this.setState({
      version: result.data.version,
      startDate: result.data.start_date.date,
      endDate: result.data.end_date.date,
    });
  };

  editCourseRound = async () => {
    let data = {
      start_date_: this.state.startDate,
      end_date_: this.state.endDate,
      version: this.state.version,
    };
    if (this.state.endDate <= this.state.startDate) {
      toast.error("Please Enter Valid Date!");
    } else {
      let result = await axiosApiInstance.patch(
        APILINK + `/update_course_round/${this.state.roundID}`,
        data
      );
      if (result) {
        console.log(result);
        setTimeout(() => {
          toast.info(`Course Round updated Successfully`);
        }, 500);
        setTimeout(() => {
          this.setState({ showEdit: false });
        }, 3000);
      }
    }
  };

  showWarning = (e) => {
    this.setState({ showDelete: true, roundID: e.target.id });
  };
  close = (e) => {
    this.setState({ showDelete: false });
  };
  deleteRound = async () => {
    let result = await axiosApiInstance.delete(
      APILINK + `/update_course_round/${this.state.roundID}`
    );
    console.log(result);
    if (result.data == "") {
      this.setState({ showDelete: false });
      this.getRounds();
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.showEdit !== this.state.showEdit) {
      this.getRounds();
    }
  }

  enrollRound = async () => {
    this.setState({ disabled: true });
    let data;
    let result;
    let formData = new FormData();
    if (this.state.visaPay) {
      data = {
        // activation_type: this.state.policy,
        // payment_type: this.state.payment,
        // student_: this.props.user.id,
        // course_: this.state.roundID,
        course_round_id: this.state.roundID,
        installment_policy: this.state.policy,
      };
      result = await axiosApiInstance.get(
        APILINK +
          `/pay_course?course_round_id=${this.state.roundID}&installment_policy=${this.state.policy}`
      );
      // let result2 = await axiosApiInstance.get(APILINK + `/pay_response?success=${true}&order=${result.data.data.order_id}`)
      window.location.href = `https://accept.paymobsolutions.com/api/acceptance/iframes/${result.data.data.iframe_id}?payment_token=${result.data.data.token}`;
    } else {
      formData.append("course_round_id", this.state.roundID);
      formData.append("installment_policy", this.state.policy);
      formData.append("cost", this.state.cost);
      formData.append("image", this.state.image);

      result = await axiosApiInstance.post(
        APILINK + `/pay_avec_account`,
        formData
      );
    }

    if (result) {
      setTimeout(() => {
        toast.info(`You have enrolled successfully`);
      }, 500);
      setTimeout(() => {
        this.setState({ showEnroll: false });
        this.props.history.push("/courses");
      }, 3000);
    }
  };

  getSubjects = async () => {
    const result = await axiosApiInstance.get(
      APILINK + `/create_subjects?course_id=${this.props.match.params.id}`
    );
    console.log(result);
    let sorted = result.data.data.sort((a, b) =>
      a.display_order > b.display_order ? 1 : -1
    );
    // console.log(sorted);
    this.setState({ data2: sorted });
  };

  getAllTutors = async () => {
    let result = await axiosApiInstance.get(
      APILINK + "/tutor_signup?user_type=1"
    );
    this.setState({ tutors: result.data.data }, () => {
      console.log(this.state.tutors);
    });
  };
  handleTime = (time, timeString) => {
    this.setState({ startTime: timeString });
  };
  handleSubjectRoundStart = (date, dateString) => {
    // console.log(date, dateString);
    this.setState({ subjRoundstartDate: dateString });
  };
  handleSubjectRoundEnd = (date, dateString) => {
    // console.log(date, dateString);
    this.setState({ subjRoundendDate: dateString });
  };
  handleTutor = (e) => {
    this.setState({ selectedTutor: e, selectedTutorID: e });
  };

  createSubjectRound = async () => {
    if (this.state.subjRoundendDate <= this.state.subjRoundstartDate) {
      setTimeout(() => {
        toast.error(`Please Enter valid Date !`);
      }, 500);
    } else {
      let data = {
        tutor_id: this.state.selectedTutor,
        subject_id: this.state.subjectID,
        course_round_id: this.state.courseRoundID,
        start_date_: this.state.subjRoundstartDate,
        end_date_: this.state.subjRoundendDate,
        start_time: this.state.startTime,
      };
      console.log(data);
      let result = await axiosApiInstance
        .post(APILINK + "/create_subject_round", data)
        .catch((err) => {
          console.log(err.response);
          err.response.data.map((err) => {
            setTimeout(() => {
              toast.error(err);
            }, 500);
          });
        });
      console.log(result);
      if (result) {
        if (result.data) {
          this.setState({
            showSubjectRoundForm: false,
            showSubjectModel: false,
          });
          setTimeout(() => {
            toast.info(`A new subject round created successfully`);
          }, 500);
          setTimeout(() => {
            this.props.history.push(
              `/subject-rounds/${this.state.courseRoundID}`
            );
          }, 3000);
        }
      }
    }
  };

  onChange = (page) => {
    console.log(page);
    this.setState(
      {
        current: page,
      },
      async () => {
        let result;
        if (this.props.user.user_type == "1") {
          result = await axiosApiInstance
            .get(APILINK + `/create_course_round`)
            .then((res) => {
              console.log(res);
              this.setState({ data: res.data.data, count: res.data.count });
            });
        } else {
          result = await axiosApiInstance
            .get(
              APILINK +
                `/create_course_round?course_id=${this.props.match.params.id}`
            )
            .then((res) => {
              console.log(res);
              this.setState({ data: res.data.data, count: res.data.count });
            });
        }
      }
    );
  };

  render() {
    // console.log(this.state.data[0].course)
    return (
      <div>
        {this.state.loading ? (
          <Spin className="spinElement" tip="..loading" />
        ) : (
          <Container className="viewSuper">
            <Row>
              <Col className="p-4 mb-4" style={{ textAlign: "left" }} sm={6}>
                <h2>All Courses</h2>
              </Col>
              {this.props.user.user_type === "3" ? (
                <Col className="p-4 mb-4" style={{ textAlign: "right" }} sm={6}>
                  <Link to={`/add_round/${this.props.match.params.id}`}>
                    {" "}
                    <Button className="forumBtn1">Add New Round</Button>
                  </Link>
                </Col>
              ) : null}
            </Row>
            <Row>
              <Col style={{ textAlign: "center" }} sm={12}>
                <DataTable
                  columns={this.state.columns}
                  data={this.state.data}
                />
              </Col>
            </Row>
            <Row className="p-4">
              {this.state.data.length !== 0 ? (
                <Pagination
                  onChange={this.onChange}
                  defaultCurrent={this.state.current}
                  total={this.state.count}
                  current={this.state.current}
                />
              ) : null}
            </Row>
          </Container>
        )}

        <ToastContainer position="bottom-right" />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  isAuth: state.auth.isAuth,
});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ isSubject }, dispatch);
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ErrorHandler(AssignedCourses, axiosApiInstance));
