import React, { Component } from "react";
import {
  Row,
  Col,
  Container,
  Button,
  Form,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import "../../style-sheets/training.css";
import ErrorHandler from "../../error-handler/ErrorHandler";
import axios from "axios";
import { APILINK } from "../../EndPoint";
import { Empty, Spin, Pagination ,Card} from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const axiosApiInstance = axios.create();
const { Meta } = Card;

class AllFourms extends Component {
  state = {
    loading: false,
    data: [],
    count: 1,
    current: 1,
    showAdd: false,
    forumID: "",
    title: "",
    details: "",
    show: false,
  };
  componentDidMount = async () => {
    this.setState({ loading: true });
    this.getForum();
  };
  getForum = async () => {
    let result;

    result = await axiosApiInstance.get(
      APILINK + `/get_forums?page=${this.state.current}`
    );

    console.log(result);

    this.setState({
      data: result.data.data,
      loading: false,
      count: result.data.count,
    });
  };
  onChange = (page) => {
    console.log(page);
    this.setState(
      {
        current: page,
      },
      async () => {
        let result = await axiosApiInstance
          .get(APILINK + `/get_forums?page=${this.state.current}`)
          .then((res) => {
            console.log(res);
            this.setState({ data: res.data.data, count: res.data.count });
          });
      }
    );
  };
  openAddModal = (e) => {
    this.setState({ showAdd: true, forumID: e.target.id });
  };
  hideAdd = (e) => {
    this.setState({ showAdd: false });
  };
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  addQuestion = async () => {
    let data = {
      forum_id: this.state.forumID,
      title: this.state.title,
      question_details: this.state.details,
    };
    let result = await axiosApiInstance
      .post(APILINK + `/create_forum_question`, data)
      .then((res) => {
        console.log(res);
        setTimeout(() => {
          toast.info(`A new Question added successfully`);
        }, 500);
        this.setState({ showAdd: false, title: "", details: "" });
      });
  };

  showWarning = (e) => {
    this.setState({ show: true, forumID: e.target.id });
  };
  close = (e) => {
    this.setState({ show: false });
  };
  deleteForum = async (e) => {
    let result = await axiosApiInstance.delete(
      APILINK + `/delete_forum/${this.state.forumID}`
    );
    this.setState({ show: false });
    this.getForum();
  };

  render() {
    return (
      <div>
        <Container className="content-margin">
          <Row>
            <Col sm="12">
              <h4 style={{ color: "#124E68" }}>
                <i className="fas fa-chevron-left pr-4"></i>All Forums
              </h4>
            </Col>
          </Row>

          <Row className="my-5">
            {this.state.data.length !== 0 && this.state.loading === false ? (
              this.state.data.map((data) => {
                return (
                  <Col sm="12" md="4">
                     <Card
                    className='my-3'
                      actions={[
                        <>
                        <OverlayTrigger
                        key={"2"}
                        placement={"top"}
                        overlay={
                          <Tooltip id={`tooltip-${"top"}`}>
                            Add Question
                          </Tooltip>
                        }
                      >
                          <i
                            id={data.id}
                            className="fas fa-plus-circle pr-2"
                            onClick={this.openAddModal}
                          ></i>
                          </OverlayTrigger>
                        </>,
                        <OverlayTrigger
                        key={"2"}
                        placement={"top"}
                        overlay={
                          <Tooltip id={`tooltip-${"top"}`}>
                            Add Question
                          </Tooltip>
                        }
                      >
                        <Link to={`/view_fourm_questions/${data.id}`}>
                          <i className="fas fa-eye pr-2 viewForum"></i>
                        </Link>
                        </OverlayTrigger>,
                        <OverlayTrigger
                          key={"2"}
                          placement={"top"}
                          overlay={
                            <Tooltip id={`tooltip-${"top"}`}>
                              Delete Forum
                            </Tooltip>
                          }
                        >
                          <i
                            onClick={this.showWarning}
                            id={data.id}
                            className="fas fa fa-trash pr-2"
                          ></i>
                        </OverlayTrigger>,
                      ]}
                    >
                      <Meta
                        title={data.title}
                        description={data.description}
                      />
                    </Card>
                    {/* <div className="forumCard my-4">
                      <h5>{data.title}</h5>
                      <p>{data.description}</p>{" "}
                      <Button
                        onClick={this.openAddModal}
                        id={data.id}
                        className="forumBtn"
                      >
                        {" "}
                        <i
                          id={data.id}
                          className="fas fa-plus-circle pr-2"
                        ></i>{" "}
                        Questions
                      </Button>{" "}
                      <Button className="forumBtn">
                        {" "}
                        <Link to={`/view_fourm_questions/${data.id}`}>
                          <i className="fas fa-eye pr-2"></i> Questions
                        </Link>
                      </Button>
                      <Button
                        onClick={this.showWarning}
                        id={data.id}
                        className="forumBtn"
                      >
                        <i id={data.id} className="fas fa-trash pr-2" />
                        Forum
                      </Button>
                     
                    </div> */}
                  </Col>
                );
              })
            ) : this.state.loading ? (
              <Spin className="text-center" tip="...loading" />
            ) : (
              <Empty className="text-center" />
            )}
          </Row>
          <Row>
            {this.state.data.length !== 0 ? (
              <Pagination
                onChange={this.onChange}
                defaultCurrent={this.state.current}
                total={this.state.count}
                current={this.state.current}
              />
            ) : null}
          </Row>
        </Container>
        <Modal show={this.state.showAdd} onHide={this.hideAdd}>
          <Container fluid className="py-4">
            <Form id="infoForm">
              <Row>
                <Col className="text-center pt-2" sm={12}>
                  {" "}
                  <h6>Add Question</h6>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col sm={6}>
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      name="title"
                      value={this.state.title}
                      onChange={this.handleChange}
                      type="text"
                      placeholder="Title"
                    />
                  </Form.Group>
                </Col>
                <Col sm={6} className="mt-2">
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label>Details</Form.Label>
                    <Form.Control
                      name="details"
                      value={this.state.details}
                      onChange={this.handleChange}
                      type="text"
                      placeholder="Details"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="">
                <Col sm={12} className="mt-4 text-center">
                  <Button className="m-2 forumBtn1" onClick={this.hideAdd}>
                    Cancel
                  </Button>
                  <Button className="m-2 forumBtn1" onClick={this.addQuestion}>
                    Add Question
                  </Button>{" "}
                </Col>
              </Row>
            </Form>
          </Container>
        </Modal>
        <Modal show={this.state.show} onHide={this.close}>
          <Container className="p-4">
            <Row>
              <Col className="text-center" sm={12}>
                {" "}
                <h6>Are u sure u want to delete this Forum?</h6>
              </Col>
              <Row style={{ margin: "auto" }}>
                <Col md={12}>
                  <Button onClick={this.deleteForum} className="forumBtn1 mx-3">
                    Delete
                  </Button>
                  <Button className="forumBtn1" onClick={this.close}>
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Row>
          </Container>
        </Modal>
        <ToastContainer position="bottom-right" />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isSubject: state.isSubject.isSubject,
});

export default connect(
  mapStateToProps,
  null
)(ErrorHandler(AllFourms, axiosApiInstance));
